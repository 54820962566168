/* ID: #num_do_caso */
/* CSS */

/* #22657 - show tooltip above dialog */
.pb-dialog--product-sale .tippy-box {
    border-radius: 10px;
    padding: 10px;
}
/* #23700 - Ajuste - My Shop Online - Respiro de notificação */
#listproducts .pb-toast--sign{
    right:calc((100dvw - var(--container))/2 - 10px);
}

@media (max-width: 1622px) {
	#listproducts .pb-toast--sign {
		right: 40px;
	}
}
@media (max-width: 769px) {
	#listproducts .pb-toast--sign {
        right: 10px;
		width: calc(100dvw - 20px);
		height: auto;
		padding: 10px;
		max-width: 100%;
		grid-template-rows: auto auto;
	}
}
/* #23370 - Ajuste - Banner responsivo na págiand e Oportunidades */ 
@media (min-width: 931px) {
    #Products_indexnew .pb-headercarousel-v2 {
        height: auto;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container,
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide,
    #Products_indexnew .pb-headercarousel-v2 .pb-carousel {
        height: auto;
        aspect-ratio: 1502 / 582;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide > picture {
        height: auto;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide > picture img {
        width: 100%;
        height: 100%;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .pb-carousel-front .slick-slide.footer {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 0;
    }
}
@media (max-width: 930px) {
    #Products_indexnew .pb-headercarousel-v2 {
        height: auto;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container,
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide,
    #Products_indexnew .pb-headercarousel-v2 .pb-carousel {
        height: auto;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .pb-carousel-back .slick-slide,
    #Products_indexnew .pb-headercarousel-v2 .pb-carousel-back,
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide > picture {
        height: auto;
        aspect-ratio: 1 / 1;
    }
    #Products_indexnew .pb-headercarousel-v2 .carousel-container .slick-slide > picture img {
        width: 100%;
        height: 100%;
    }
}

/* #23997 -  Ajustar - Cta Menu Topo [MOBILE] */
@media (max-width: 769px) {
    #Index_index .pb23-carousel--main .pb23-container .item--content .cta--wrapper .pb23-btn {
        width: 100%;
    }   
}

/* Fix my shop pro modal - sem formatação */
.myshoppro-page .pb23-modal {
    width: fit-content;
     border: 0;
     padding: 0;
     border-radius:  15px;
     background: var(--clr-white);
 }
 
 .myshoppro-page .pb23-modal, .myshoppro-page .pb23-modal *, .myshoppro-page .pb23-modal::backdrop {
     --matte: rgba(0,0,0,0.54);
 }

.myshoppro-page .pb23-modal,
.myshoppro-page .pb23-modal *,
.myshoppro-page .pb23-modal::backdrop {
    --matte: rgba(0, 0, 0, 0.54);
}

.myshoppro-page .pb23-modal>.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 25px;
    border-bottom: 1px solid var(--clr-gray-100)
}

.myshoppro-page .pb23-modal>.header .title {
    font-size: 1.5rem;
    font-weight: 400
}

.myshoppro-page .pb23-modal>.body {
    padding: 25px
}

.myshoppro-page .pb23-modal>.body.center,
.myshoppro-page .pb23-modal>.body.center * {
    text-align: center
}

.myshoppro-page .pb23-modal>.body.center input {
    text-align: left
}

.myshoppro-page .pb23-modal>.body>.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap
}

.myshoppro-page .pb23-modal>.body>.wrapper>.title {
    width: 100%;
    margin: 30px 0 20px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px
}

.myshoppro-page .pb23-modal>.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 25px;
    border-top: 1px solid var(--clr-gray-100)
}

.myshoppro-page .pb23-modal>.footer.end {
    justify-content: flex-end
}
body#Page_dynPageView .pb23-breadcrumbs
{
    margin-bottom: 20px;
}

body#Page_dynPageView .pb23-breadcrumbs .pb23-container
{
    --container-margins: 0px;
}

/* Add logged user submenu to new main-menu */
.pb23-mainmenu li.account {
    position: relative;
}
.pb23-mainmenu li.account .user-menu {
    background: none;
    border: none;
    cursor: pointer;
}
.pb23-mainmenu li.account .avatar img {
    vertical-align: middle;
    max-width: 40px;
    max-height: 40px;
    width: 100%;
}
.pb23-mainmenu li.account .submenu {
    display: none;
    position: absolute;
    top: 3rem;
    right: 0.5rem;
    background: var(--clr-white);
    z-index: 1000;
    width: 250px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: var(--shadow);
}
.pb23-mainmenu li.account .submenu li {
    padding: 1rem 0 10px;
    border-bottom: solid 1px var(--clr-gray-280);
}
.pb23-mainmenu li.account .submenu li:last-child {
    border-bottom: none;
}
.pb23-mainmenu li.account .submenu a {
    text-decoration: none;
    color: inherit;
}
.pb23-mainmenu li.account .submenu a:hover {
    font-weight: 600;
}
/* .pb23-mainmenu li.account:hover .submenu,
.pb23-mainmenu li.account .user-menu:focus + .submenu {
    display: block;
} */
.pb23-mainmenu li.account .submenu .avatarMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.pb23-mainmenu li.account .avatar {
    min-width: 40px;
    min-height: 40px;
    border-radius: 100%;
    background: var(--clr-gray-390);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
    max-width: 40px;
    max-height: 40px;
}
.pb23-mainmenu li.account .submenu .avatarMenu .info {
    margin-left: 1rem;
}

/* Add notification submenu to new main-menu */
.pb23-mainmenu li.notification {
    position: relative;
}
.pb23-mainmenu li.notification .notification-menu {
    background: none;
    border: none;
    cursor: pointer;
}
.pb23-mainmenu li.notification .notification-avatar img {
    vertical-align: middle;
    max-width: 40px;
    max-height: 40px;
    width: 100%;
}
.pb23-mainmenu li.notification .notification-submenu {
    display: none;
    position: absolute;
    top: 3rem;
    right: 1.5rem;
    background: var(--clr-white);
    z-index: 1000;
    width: 250px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: var(--shadow);
}
.pb23-mainmenu li.notification .notification-submenu li {
    padding: 1rem 0 10px;
    border-bottom: solid 1px var(--clr-gray-280);
}
.pb23-mainmenu li.notification .notification-submenu li:last-child {
    border-bottom: none;
}
.pb23-mainmenu li.notification .notification-submenu a {
    text-decoration: none;
    color: inherit;
}
.pb23-mainmenu li.notification .notification-submenu a:hover {
    font-weight: 600;
}
/* .pb23-mainmenu li.notification:hover .notification-submenu,
.pb23-mainmenu li.notification .notification-menu:focus + .notification-submenu {
    display: block;
} */

/* Melhorias Novembro 2023 - MyShopPro */
.myshoppro-page .my-shop--cart-create section.msp-info--client[data-show="false"] {
    display: none;
}

.myshoppro-page .msp-info--ambient ul li .ambient-tag .icon img {
    min-width: 29px;
    min-height: 34px;
}
.myshoppro-page .msp-info--ambient ul li .ambient-tag .icon img {
    min-width: 89px;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container {
    grid-area: breakmargin;
    text-align: left;
    margin: 1rem 0;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container .title {
    display: block;
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 10px;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container label {
    width: auto;
    height: 56px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    border: 2px solid var(--color-lightest);
    border-radius: 10px;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container label > button {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 4px;
    background: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container label > button:first-of-type {
    background: var(--color-light);
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .breakmargin--container label > input {
    width: 72px;
    text-align: center;
    border: none;
}
.pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body {
    grid-template-areas:
        "carousel info info"
        "carousel ambient spacer"
        "carousel qtd resume"
        "carousel breakmargin resume" !important;
}

/* .pb-myshop-product-add-modal .pb-myshop-product-add-modal--insert .modal-body .splide__slide img {
    max-width: 300px;
} */

#Product_downloads section#downloads .tippy-box,
#Product_downloadsSearch section#downloads .tippy-box,
#Product_downloadsCategory section#downloads .tippy-box {
    padding: 10px;
    border-radius: 10px;
    z-index: 10;
}

.pb-myshop-product-add-modal--insert .pos { /* Adjustment on production environment for #27139: https://portobello.kanbanize.com/ctrl_board/69/cards/27139/details/ */
    padding: 10px;
    color: var(--gray);
}

.pb-myshop-product-add-modal--insert .breakmargin--container input,
.pb-myshop-product-add-modal--insert .quantity--container input { /* Adjustment on production environment for #27139: https://portobello.kanbanize.com/ctrl_board/69/cards/27139/details/ */
    text-align: right !important;
}

#Product_view .pb23-mainmenu { /* Fix main menu over add to product modal */
    z-index: 999
}

/* BEGIN Fix #27440: https://portobello.kanbanize.com/ctrl_board/103/cards/27440/ */

#Product_downloadsLaudo .pb23-downloads--list-filter,
#Product_downloadsImages .pb23-downloads--list-filter,
#Product_downloadsLaudo .pb23-downloads--list-filter {
    margin-top: 2rem; 
}

#Product_downloads3d .pb23-downloads--search #downloads3d {
    display: block;
}
/* #Product_downloads3d .pb23-downloads--search .query > form {
    margin: 1rem 0 !important
} */
#Product_downloads3d .pb23-downloads--search .form-block,
#Product_downloadsImages .pb23-downloads--search .form-block,
#Product_downloadsLaudo .pb23-downloads--search .form-block {
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1rem auto;
    align-items: center;
}

#Product_downloads3d .pb23-downloads--search .form-block p,
#Product_downloadsImages .pb23-downloads--search .form-block p,
#Product_downloadsLaudo .pb23-downloads--search .form-block p {
    display: block;
    width: 100%;
}

#Product_downloads3d .pb23-downloads--search .form-block .data,
#Product_downloadsImages .pb23-downloads--search .form-block .data,
#Product_downloadsLaudo .pb23-downloads--search .form-block .data {
    display: block;
    text-align: left;
    max-width: 250px;
    height: 50px;
    margin: 0;
}

#Product_downloads3d .pb23-downloads--search .form-block .data select,
#Product_downloadsImages .pb23-downloads--search .form-block .data select,
#Product_downloadsLaudo .pb23-downloads--search .form-block .data select {
    border: none;
    box-shadow: none;
    margin: -4px 0 0 0;
    height: 30px;
    padding-left: 0;
    width: 100%;
}

#Product_downloads3d .pb23-downloads--search .form-block .data label,
#Product_downloadsImages .pb23-downloads--search .form-block .data label,
#Product_downloadsLaudo .pb23-downloads--search .form-block .data label {
    margin-top: 5px;
    font-size: 0.7rem !important;
    font-weight: 500;
}

#Product_downloads3d .pb23-downloads--search .form-block select,
#Product_downloadsImages .pb23-downloads--search .form-block select,
#Product_downloadsLaudo .pb23-downloads--search .form-block select {
    width: 300px;
}

#Product_downloads3d .pb23-downloads--search .form-block .pb23-btn,
#Product_downloadsImages .pb23-downloads--search .form-block .pb23-btn,
#Product_downloadsLaudo .pb23-downloads--search .form-block .pb23-btn {
    padding: 0 15px;
}

#Product_downloads3d .pb23-downloads--list .file img,
#Product_downloadsImages .pb23-downloads--list .file img,
#Product_downloadsLaudo .pb23-downloads--list .file img {
    max-width: 20px;
    filter: saturate(0);
}

#Product_downloadsLaudo .pb23-downloads--search .page-discontinued .radio-container,
#Product_downloadsImages .pb23-downloads--search .page-images .radio-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 20px;
}
#Product_downloadsLaudo .pb23-downloads--search .page-discontinued label:has(> input[type="checkbox"],> input[type="radio"]):before,
#Product_downloadsImages .pb23-downloads--search .page-images label:has(> input[type="checkbox"],> input[type="radio"]):before {
    background: none;
}

/* END Fix #27440: https://portobello.kanbanize.com/ctrl_board/103/cards/27440/  */



#languages-modal-v3,
#languages-modal-v4,
#languages-modal-v5 {
    --modal--max-width: 614px;
    --button-padding: 40px;
}
#languages-modal-v3.pb23-modal,
#languages-modal-v4.pb23-modal,
#languages-modal-v5.pb23-modal {
    --modal--background: var(--clr-gray-100);
}
#languages-modal-v3 h3,
#languages-modal-v4 h3,
#languages-modal-v5 h3 
{
    text-transform: initial;
    color: var(--clr-gray-700);
    font-weight: 300;
    font-size: 28px;
}
#languages-modal-v3 .body,
#languages-modal-v4 .body,
#languages-modal-v5 .body
{
    /*background-color: var(--clr-white);*/
    padding: 20px;
    border-radius: 10px;
}
#languages-modal-v5 .body
{
    background-color: var(--clr-white);
}
#languages-modal-v3 .footer, 
#languages-modal-v4 .footer,
#languages-modal-v5 .footer
{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#languages-modal-v3 .footer a,
#languages-modal-v4 .footer a,
#languages-modal-v5 .footer a 
{
    color: black;
    text-decoration: underline;
    /*font-weight: bold;*/
    margin-top: 14px;
}
#languages-modal-v3 .footer a.pb23-btn,
#languages-modal-v4 .footer a.pb23-btn,
#languages-modal-v5 .footer a.pb23-btn
{
    text-decoration: none;
    font-weight: initial;
    margin-top: 0px;
}
#languages-modal-v3 .footer a:hover.pb23-btn,
#languages-modal-v4 .footer a:hover.pb23-btn,
#languages-modal-v5 .footer a:hover.pb23-btn
{
    color: white;
}

#languages-modal-v3 .body p,
#languages-modal-v4 .body p,
#languages-modal-v5 .body p 
{
    margin-bottom: 30px;
    font-size: 16px;
}
#languages-modal-v3 .footer,
#languages-modal-v4 .footer,
#languages-modal-v5 .footer
{
    justify-content: center;
    column-gap: 16px;
}
#languages-modal-v3 h3 img,
#languages-modal-v4 h3 img,
#languages-modal-v5 h3 img 
{
    margin-left: 16px;
}
#languages-modal-v3 .pb23-link,
#languages-modal-v4 .pb23-link,
#languages-modal-v5 .pb23-link,
#languages-modal-v3 .pb23-link *,
#languages-modal-v4 .pb23-link *,
#languages-modal-v5 .pb23-link *
{
    --link-color: black;
}
#languages-modal-v3 .pb23-link,
#languages-modal-v4 .pb23-link,
#languages-modal-v5 .pb23-link
{
    font-style: normal !important;
}
.pb-languages-v3 .pb23-modal.channel-select .header
{
    margin-bottom: 30px;
}
@media (max-width: 769px) {
    #languages-modal-v3 .body,
    #languages-modal-v4 .body,
    #languages-modal-v5 .body
    {
        padding: 0px;
    }
    .pb23-mainmenu .pb23-modal.channel-select,
    .pb-languages-v2 .pb23-modal.channel-select,
    .pb-languages-v3 .pb23-modal.channel-selec {
        margin-bottom: 0;
        border-radius: 0;
    }
    .pb23-mainmenu .pb23-modal.channel-select .body ul li,
    .pb-languages-v2 .pb23-modal.channel-select .body ul li {
        flex-direction: column;
        gap: var(--spacer);
    }
    .pb23-mainmenu .pb23-modal.channel-select .body ul li .pb23-btn,
    .pb-languages-v2 .pb23-modal.channel-select .body ul li .pb23-btn {
        width: 100%;
    }
    #languages-modal-v2,
    #languages-modal-v3,
    #languages-modal-v4,
    #languages-modal-v5
    {
        border-radius: 0px;
        bottom: 0px;
        position: fixed;
        top: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    #languages-modal-v3 h3,
    #languages-modal-v4 h3,
    #languages-modal-v5 h3

    {
        margin-bottom: 30px;
    }
    #languages-modal-v3 .footer,
    #languages-modal-v4 .footer,
    #languages-modal-v5 .footer
    {
        flex-direction: column;
        row-gap: 16px;
        align-items: center;
    }
    #languages-modal-v3 .footer a.pb23-btn,
    #languages-modal-v4 .footer a.pb23-btn,
    #languages-modal-v5 .footer a.pb23-btn
    {
        width: 100%;
    }  
}