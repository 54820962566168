/* bootstrap  modal tweaks */
.modal-content
{
    border-radius: 0px;
}

/* Ajuste do Ícone do Lightbox*/
.pswp__button--download {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.pswp__button--download {
  background-position: -176px 0px; 
}
.pswp__button--download:after {
  width: 16px;
  height: 16px;
  display: block;
  content: "";
  background: url("/template/images/icon_download.png") center center no-repeat !important;
  background-size: 16px 16px;
}

.pswp {    
  z-index: 150000000;
}

.pswp__caption__center
{
  text-align: center !important;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {  
  .pswp--svg .pswp__button, 
  .pswp--svg .pswp__button--arrow--left:before, 
  .pswp--svg .pswp__button--arrow--right:before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) 0 0 no-repeat;
    background-size: 264px 88px;
    width: 44px;
    height: 44px;
  }   
  .pswp__button--arrow--left:before {
    left: 6px;
    background-position: -138px -44px !important;
  }
  .pswp__button--arrow--right:before {
    right: 6px;
    background-position: -94px -44px !important;
  }
  .pswp__button--close {
    background-position: 0 -44px !important;
  }
  .pswp__button.pswp__button--share,
  .pswp--svg .pswp__button--arrow--left,
  .pswp--svg .pswp__button--arrow--right
  {
    background-image: none;
  }
}

@media (max-width: 769px) {
  #Product_groupcolor .pb-productgroupnav > div {
   padding-left: 12px;
 }
}
#Product_groupcolor{
  overflow-x: hidden;
}
#Line_view .pageInModal
{
  overflow-x: hidden;
}
/*body#Product_lastrasProducts #filters #Format,
body#Product_lastrasProducts #filters #Format--header
{
  display: none;
}*/
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-48.slick-slide > div > div.button-container > a,
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-53.slick-slide > div > div.button-container > a,
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-52.slick-slide > div > div.button-container > a
{
  background-color: black;
}
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-48.slick-slide > div > div.button-container > a span,
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-53.slick-slide > div > div.button-container > a span,
#maincarousel > div > div > div.pb-carousel.pb-carousel-front.slick-initialized.slick-slider > div > div > div.item.bannerCover-52.slick-slide > div > div.button-container > a span
{
  color: white;
}
/* TV */
body.tv #SalesChannel, 
body.tv #SalesChannel--header
{
  display: none;

}
body.tv .pswp .pswp__button.pswp__button--share
{
  display: none;
}

body#Line_list.portobelloshop .pb-conceptart img
{
  object-position: left center;
}
body#Line_list.multimarcas .pb-conceptart img
{
  object-position: right center;
}

/** Media content / Grid ****/

#embed-content-modal,
#grid-video-modal
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 14300;
    background: white;
    height: 100%;
    overflow: auto;
    padding-bottom: 100px;
}
#grid-video-modal .container-xl,
#grid-video-modal .container-lg
{
    margin-top: 60px;
}
#embed-content-modal .btn-close,
#grid-video-modal .btn-close
{
    z-index: 1431;
}

.content-modal .btn-close
{
    position: absolute;
    right: 60px;
    top: 40px;
    z-index: 1431;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    color: var(--color-dark);
    vertical-align: middle;
    text-transform: uppercase;
}
.content-modal .btn-close .pb-close
{
    margin-left: 10px;
}
.content-modal button.close-modal
{
    margin-top: 30px;
    width: 100%;
    max-width: 302px;
    padding: 8px 20px;
    font-size: 14px;
}
#embed-content-modal .video,
#grid-video-modal #embed
{
    position: relative;
    margin-top: 55px;
    margin-bottom: 30px;
    width: 100%;
    height: 60vh;
}
#grid-video-modal #embed
{
    display: none;
}
#embed-content-modal .video .embed, 
#grid-video-modal #embed .embed
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#embed-content-modal .title,
#grid-video-modal .title
{
    margin: 0 0 1rem;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
}

#grid-video-modal .title-header
{
    text-align: right;
}
#embed-content-modal .content, 
#grid-video-modal .content
{
    margin-top: 50px;
}
#grid-video-modal.final .pb-item--video-revestir2021 span
{
    min-height: 52px;
}
@media (max-width: 440px) {
    #embed-content-modal .video,
    #grid-video-modal .video
    {        
        height: 27vh;
    }
    #embed-content-modal  .btn-close,
    #grid-video-modal .btn-close
    {
        position: absolute;
        top: 20px;
    }
}
a.expandEmbed
{
    text-transform: uppercase;
    font-weight: 500;
    position: absolute;
    right: 15px;
    margin-top: 20px;
    color: var(--color-darkest);
}
#embed-content-modal .video .embed.expanded, 
#grid-video-modal #embed .embed.expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
@media (max-width: 440px) {
    a.expandEmbed
    {
        right: auto;
        left: 15px;
        display: none;
    }
}

body#Index_index.cover .pb-collectioncarousel--multi .carousels .pb-carousel--container .pb-carousel .pb-item--collection .image {
  width: 260px; 
}
body#Index_index.cover.tv .pb-collectioncarousel--multi .info .content
{
  margin: 20px 0 30px;
}
body#Index_index.cover.tv .pb-collectioncarousel--multi .carousels .pb-carousel--container .pb-carousel .pb-item--collection .image
{
  width: 360px; 
}

/* Form +arquitetura */
body#Page_pageDynamic .form--title {
  grid-column: span 2;
  display: block;
  margin: 10px;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
}
body#Page_pageDynamic .form--atendimento {
  grid-column: span 2;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  margin: 20px;
}
body#Page_pageDynamic .form--atendimento .form-check-label {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--color-main);
}
#modal-archtrends .modal-dialog
{
  min-width: 100vw;
  margin: 0px;
}
#modal-archtrends .modal-content
{
  min-height: 100vh;  
}
#modal-archtrends .modal-body
{
  padding: 0px;
}
#modal-archtrends iframe {
  height: 100% !important;
  min-height: calc(100vh - 62px);  
}
@media (max-width: 440px) {
  #modal-archtrends .modal-dialog
  {
    min-width: 100vw;
    min-height: 100vw;
    margin: 0px;
  }
  #modal-video iframe {
    width: 100% !important;
    height: auto !important;
  }

  #modal-archtrends iframe {
    width: 100% !important;
    height: auto !important;
  }
}


.pb-list[data-area="instalacao-e-pos-obra"] h2,
.pb-list[data-area="installation-and-post-work"] h2,
.pb-list[data-area="instalacion-y-post-trabajo"] h2 
{
  text-transform: uppercase;
  font-size: 1.75rem;
  font-weight: 700;
}

body#Product_downloads .pb-card .image img {
   object-position: center center;
}

div.pswp.pswp--supports-fs.pswp--open.pswp--notouch.pswp--css_animation.pswp--svg.pswp--zoom-allowed.pswp--visible.pswp--animated-in > div.pswp__scroll-wrap > div.pswp__ui.pswp__ui--fit > div.pswp__top-bar > button.pswp__button.pswp__button--download
{
  background: none;
}
body#Index_index #collection .pb-custom-container {
  position: relative;
  z-index: 3;
}
body#Index_index #collection .pb-custom-container {
  position: relative;
  z-index: 3;
}

/* Fix: https://portobelloamerica-team.monday.com/boards/1110640814/pulses/1412594994 */
#page-maisarquitetura .content-box {
  margin-top: 40px;
}
#page-maisarquitetura .pb-content--highlight .image {
  display: none;
}
body#Page_pageDynamic[data-pageid="1"] .pb-accordionheadermenu {
  top: calc(var(--mainmenu-height) + 22px) !important;
}
#page-maisarquitetura .pb-content--highlight .content-box:before
{
  display: none;
}

@media (max-width: 992px) {
  body#Page_pageDynamic[data-pageid="1"] .pb-accordionheadermenu {
      top: 0 !important;
      left: 0;
      position: relative;
  }
}

/* Mais Arquitetura Tweaks */
@media (max-width: 992px) {
  #page-maisarquitetura p {
    font-size: 14px;
    font-weight: 300;
  }
  #page-maisarquitetura .accordion form {
    width: 100%;
  }
  #page-maisarquitetura .pb-accordioncontent .accordion .content-template .more > .btn {
    margin: 0 4px;
  }
  #page-maisarquitetura section .card .image img {
    width: 100%;
  }
  #page-maisarquitetura .accordion .content img {
    max-width: 100%;
  }
  #page-maisarquitetura .pb-accordioncontent .accordion .content-template--cols-content-image {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "image" "content";
  }
  #page-maisarquitetura .pb-accordioncontent .accordion .content-template--cols-content-image .content {
    grid-area: content;
  }
  #page-maisarquitetura .pb-accordioncontent .accordion .content-template--cols-content-image .image {
    grid-area: image;
  }
}


@media (min-width: 993px) and (max-width: 1096px) {
  nav.pb-mainmenu > .container-xl {
    padding: 0;    
  }
  nav.pb-mainmenu .navbar-brand {
    margin: 0;
  }
}

body#Specifier_experience .locale {
  display: none !important;
}

.jsEmbed div.embed iframe
{
    display: none;
}

body.hasChannel #SalesChannel--header, 
body.hasChannel #SalesChannel
{
  display: none;
}
body#Product_lastras ul.links 
{
  list-style-type: none;
  padding-left: 0px;
}
body#Product_lastras ul.links li {
  margin-top: 20px;
}
body#Product_lastras ul.links a
{
  font-weight: 500;
}

a label.new, 
a label.lastra {
  cursor: pointer;
}

#poscompra div.content a.pdf
{
    margin-bottom: 20px;
}

.bannerCover-154  a.pb-btn-banner
{
  background-color: rgb(96,96,96) !important;
}
.bannerCover-154  a.pb-btn-banner span
{
  color: white !important;
}
/* form invalid field */
.is-invalid
{
  --color-soft: red !important;
  color: red !important;
}

a.pb-item.pb-item--video-revestir2021.showVideo {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  position: relative;
  padding: 0 0 56%;
  margin-bottom: 10px;
}
span.videotitle {
  margin-bottom: 20px;
  display: block;
}
.pb-item--video-revestir2021 img {
  grid-area: video;
  width: 100%;
  /* width: 100%; */
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}

body#Line_material #filters #ProductClass4--header,
body#Line_material #filters #ProductClass4
{
  display: none;
}
body#Specifier_index .pb-specifier-header-v2 .btn-contact
{
  border-radius: initial;
}


.unified-breadcrumbs {
  padding-bottom: 10px;
}
.unified-breadcrumbs ul {
  display: flex;
  list-style: none;
  margin: 0 0 var(--title-spacer);
  padding: 0;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.unified-breadcrumbs ul li {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--color-light);
  text-decoration: none;
}
.unified-breadcrumbs ul li a {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--color-light);
  text-decoration: none;
}
.unified-breadcrumbs ul li a:hover
{
  text-decoration: underline;
}
.unified-breadcrumbs ul li {
  margin: 0 0 0 10px;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.unified-breadcrumbs ul li:before {
  width: 6px;
  height: 5px;
  content: "";
  display: block;
  background: url("/template/images/icons/icon_chevrons-right.svg") center center no-repeat;
  margin: 0 10px 0 0;
}
.unified-breadcrumbs ul li:first-of-type {
  margin: 0;
}
.unified-breadcrumbs ul li:first-of-type:before {
  display: none;
}
body#Page_dynPageView .pb-footer {
  margin-top: 30px;
}
body.directSales .pb-mainmenu .nav-item[data-id="2"],
body.directSales .pb-mainmenu .nav-item[data-id="2156"],
body.directSales .pb-mainmenu .nav-item[data-id="84"],
body.directSales .pb-footer #sitemap li[data-id="22"],
body.directSales .pb-footer #sitemap li[data-id="66"]
{
  display: none;
}

.pb-item--releases .image > img {
  max-height: 800px;
}

.form-check-label{
  display: inline!important;
}

/* banner black */

div.bannerCover-291 a.pb-btn-banner {
  background-color: #f7ce82 !important;
  border-radius: 15px !important;
  color: black !important;
  max-width: 200px;
  display: inline-block;
  padding: 15px 40px;
  text-decoration: none !important;    
  margin-top: 80px;

}

div.bannerCover-291 a.pb-btn-banner span {
  color: black !important;
  text-transform: uppercase;
  text-decoration: none !important;
}

.pb-headercarousel-v2 div.bannerCover-291.item[data-template="1"] > .pb-container
{
  padding: 90px 90px
}

.pb-headercarousel-v2 div.bannerCover-291.item.slick-active > .pb-container .button-container
{
  text-align: center;
}
.pb-headercarousel .item>.pb-container .subtitle.banner_310>p
{
text-transform: initial;
}

.pb-productheader-v2 .channel .channelOptions .header > div span,
.pb-lineheader-v3 .channel .channelOptions .header > div span
{
  font-size: 16px !important;
}

/* NOVOS FILTROS */
.channelDesktop label {
  color: var(--clr-gray-300);
}
@media (max-width: 992px) {
  .pb-productheader-v2 .channel {
      padding-top: 0px !important;
      margin-bottom: 40px;
  }
  .pb-lineheader-v3 .channel {
      margin-top: 10px !important;
      align-items: flex-start !important;
  }
}
@media (max-width: 769px) {
  .pb-lineheader-v3 .channel .titleChannel,
  .pb-productheader-v2 .channel .titleChannel {
      font-size: 10px;
      text-align: left;
      padding: 10px 20px;
  }
  .pb-lineheader-v3 .channel .titleChannel label {
      font-size: 15px;
  }
  .pb-lineheader-v3 .channel .channelOptions .header {
      margin-bottom: 20px;
  }
  .pb-lineheader-v3 .optionsMobile .btn {
      margin-bottom: 0px;
  }
  .pb-lineheader-v3 .channel .channelOptions {
      padding: 20px;
  }
  .pb-lineheader-v3 .channel .channelOptions .header > div span {
      font-size: 16px;
      color: var(--clr-darkest);
  }
  .pb-lineheader-v3 .channel .channelOptions .optionsMobile > ul li:last-of-type {
      padding-top: 20px;
      margin-bottom: 0;
  }
  .pb-lineheader-v3 .optionsMobile ul li label span {
      font-size: 14px !important; 
  }
  .pb-lineheader-v3 .channel .channelOptions .header > .pb-close span.sr-only {
      display: block !important;
      right: 30px;
      top: 3px;
      letter-spacing: 1px;
      position: absolute;
      color: var(--clr-gray-500);
      font-size: 12px;
  }
}

.pb-lineheader-v3 .channel .channelOptions .header > .pb-close,
.pb-productheader-v2 .channel .channelOptions .header > .pb-close {
    width: auto;
    min-width: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pb-lineheader-v3 .channel .channelOptions .header > .pb-close span.sr-only,
.pb-productheader-v2 .channel .channelOptions .header > .pb-close span.sr-only {
    width: auto;
    height: auto;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    opacity: 1;    
    display: block !important;
    letter-spacing: 1px;
    color: var(--clr-gray-500);
    font-size: 12px;
    z-index: 1;
    visibility: visible;
    margin-right: 30px;
}
.pb-lineheader-v3 .channel .channelOptions .header > .pb-close:after,
.pb-lineheader-v3 .channel .channelOptions .header > .pb-close:before,
.pb-productheader-v2 .channel .channelOptions .header > .pb-close:after,
.pb-productheader-v2 .channel .channelOptions .header > .pb-close:before {
    left: auto;
    right: 0;
}