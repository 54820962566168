#page-images,
#page-images * {
    box-sizing: border-box;
    --content-height: 72px;
    --content-spacer: 45px;
    --page-spacer: 300px;
}
#page-images {
    margin: 0 0 var(--page-spacer);
}
#page-images h2 {
    text-transform: uppercase;
    font-size: 1.55rem;
    font-weight: 500;
    color: var(--color-darkest);
}

#page-images .btn-darkest {
    width: auto;
    min-width: 172px;
    padding: 16px 40px;
    line-height: 1.2em;
    letter-spacing: .4px;
    border-radius: 0px;
    text-transform: uppercase;
}
#page-images p {
    margin: 0 0 2rem;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.2;
}
#page-images .content {
    margin: 0 0 2rem;
}
#page-images form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--container-grid-spacer);
}
#page-images .form-group {
    margin-bottom: 0;
}
#page-images .form--title {
    grid-column: span 2;
    display: block;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
}
#page-images .form--canal,
#page-images .form--linha,
#page-images .form--produto,
#page-images .form--optin {
    grid-column: span 2;
}
#page-images .form--optin .form-check {
    margin: 0 0 15px;
}
#page-images .form--optin .form-check label {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-main);
    margin-top: 2px;
    margin-left: 6px;
}
#page-images .form--optin .form-check label a {
    text-decoration: underline;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-main);
}
#page-images .results
{
    margin-top: 60px;
}

@media(max-width: 992px) {
    #page-images,
    #page-images * {
        --page-spacer: 100px;
    }
    #page-images form {
        grid-template-columns: 1fr;
    }
   
    #page-images p {
        margin: 0 0 20px;
        font-size: 1.125rem;
    }
}
#page-images .box > button {
    display: block;
    border: none;
    background: none;
    margin: 0 0 36px;
}
@media (min-width: 993px) {
    #page-images .box > button:hover {
        cursor: default;
    }
    #page-images .box .collapsing,
    #page-images .box .collapse {
        display: block;
        height: auto !important;
    }
    #page-images .box .card {
        padding: 0;
        border: none;
    }    
    #page-images .line {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
    #page-images .line .divider {
        width: 1px;
        height: 154px;
        background: #707070;
    }
}
@media (max-width: 992px) {
    #page-images .box {
        margin: 0 0 12px;
        position: relative;
        padding: 30px 20px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16); 
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
    }
    #page-images .box > button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }
    #page-images .box > .collapse + .chevron {
        width: 10px;
        height: 10px;
        display: block;
        content: "";
        position: absolute;
        top: 30px;
        right: 24px;
        border-bottom: 2px solid #8390A5;
        border-right: 2px solid #8390A5;
        transform: rotate(45deg);
    }
    #page-images .box > .collapse.show + .chevron {
        top: 34px;
        right: 24px;
        transform: rotate(-135deg);
    }
    #page-images .box > button h2 {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        text-transform: none;
    }
    #page-images .box .card {
        border: none;
        padding: 16px 0 0;
    }
}
#page-images .results ul {
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
}
#page-images .results ul li:nth-of-type(odd) {
    background: #f1f1f1;
}
#page-images .results ul li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 14px 23px;
    font-size: 16px;
    color: #393d48;
    text-decoration: none;
}
.pb-pageheader {
    margin-bottom: 70px;
}
.pb-pageheader .header h1 {
    position: relative;
}
.pb-pageheader .header h1:before {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -36px;
    left: 0;
    background: var(--color-main);
}
@media(max-width:992px) {
    .pb-pageheader {
        margin-bottom: 23px;
    }
    .pb-pageheader .header h1:before {
        bottom: -10px;
    }
    #page-images .content {
        margin: 0;
    }
}