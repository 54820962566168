/* Geral - OK */
* {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
html body {
    font-size: 0.875rem;
    /*font-size: 1rem;*/
    line-height: 1.25rem;
}
body.noScroll {
    overflow: hidden;
}
/*h1 {
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
    text-transform: initial;
    font-size: 38px;
    font-weight: 300;
    color: var(--color-darkest);
}
h2 {
   font-size: 1.875rem;
    font-weight: 300;
    color: var(--color-darkest);
    text-transform: none;
}*/
h2 b,
h2 strong {
    font-weight: 400;
}
h2.strong {
    font-weight: 400;
}
h2.pb-title {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 32px;
    position: relative;
    color: var(--color-darker);
    font-weight: 500;
}
h2.pb-title:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0;
    background: var(--color-main);
}
/*
h3 {
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-darker);
}*/
.title-context {
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-darkest);
    line-height: 1.875rem;
}
a {
    text-decoration: none;
}
:focus {
    outline: none;
}
.help-text {
    font-size: 0.75rem;
    font-style: italic;
    color: var(--gray-light);
}
.container-lg,
.container-xl {
    padding-left: 60px;
    padding-right: 60px;
}
@media (max-width: 1179px) {    
    h1 {
        font-size: 30px;
    }
}
@media (max-width: 992px) {
    .container-lg,
    .container-xl {
        padding-left: 28px;
        padding-right: 28px;
    }
    /*
    h2,
    h3 {
        font-size: 1.125rem;
    }*/
    .title-context {
        font-size: 1.125rem;
    }
    /*p {
        font-size: 0.75rem;
    }*/
}
/* Item genérico */
.pb-item.new .content:after {
    width: auto;
    display: inline-block;
    content: "Lançamento";
    margin-top: 2px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-lightest);
}
html[lang="es-es"] .pb-item.new .content:after {
    content: "Lanzamiento";
}
html[lang="en"] .pb-item.new .content:after {
    content: "New";
}
.pb-item.upgrade .content:after {
    width: auto;
    display: inline-block;
    content: "Upgrade";
    margin-top: 2px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-lightest);
}
html[lang="es-es"] .pb-item.upgrade .content:after {
    content: "Upgrade";
}
html[lang="en"] .pb-item.upgrade .content:after {
    content: "Upgrade";
}
.pb-item.lastra .content:after {
    width: auto;
    display: inline-block;
    content: "Upgrade";
    margin-top: 2px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-white);
    border: 1px solid var(--color-main);
}
html[lang="es-es"] .pb-item.lastra .content:after {
    content: "Slabs";
}
html[lang="en"] .pb-item.lastra .content:after {
    content: "Slabs";
}
.tag-container label,
nav.pb-productgroupnav ul li a label,
.pb-card .content label,
.pb-item .content label {
    width: auto;
    display: inline-block;
    margin-top: 2px;
    margin-right: 2px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-lightest);
}
nav.pb-productgroupnav ul li a label
{
    width: fit-content;
    display: block;
}
.pb-card .content label.upgrade,
.pb-item .content label.upgrade {    
    content: "Upgrade";    
}
.tag-container label.lastra,
nav.pb-productgroupnav ul li a label.lastra,
.pb-card .content label.lastra,
.pb-item .content label.lastra {
    background: var(--color-white);
    border: 1px solid var(--color-main);
    content: "Lastra";
}

/* Item de Categoria */
.pb-item--category,
.pb-item--category a,
.pb-item--category img,
.pb-item--category span {
    width: 100%;
    display: block;
}
.pb-item--category img {
    border-radius: 4px;
    height: 100%;
}
.pb-item--category span {
    width: calc(100% - 4px);
    margin: 12px 0 0 4px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-darkest);
    text-align: center;
}
@media (max-width: 992px) {
    .pb-item--category span {
        font-size: 12px;
    }
}
/* Prêt-à-porter */
.pb-item--pret {
    display: grid;
    grid-template-columns: 33.33% 1fr;
    gap: var(--container-grid-spacer);
    padding: 0px;
    margin: 0px;
    text-decoration: none !important;
}
.pb-item--pret .image {
    display: block;
}
.pb-item--pret .image img {
    width: 100%;
}
.pb-item--pret .content {
    align-self: center;
}
.pb-item--pret .content .producttitle {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-black);
}
.pb-item--pret .content .dimensions {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.827rem;
    font-weight: 400;
    color: var(--color-darker-alt);
}

/* Item de Produto */
.pb-card {
    width: var(--card-size);
    display: block;
    text-decoration: none !important;
}
[class^="pb-card pb-card--downloads-v2"] {
    padding: 12px;
    border: 1px solid var(--color-lightest);
    display: flex;
    flex-direction: column;
    height: 100%;
}
.pb-card .image {
    width: 100%;
    height: 0px;
    padding: 0 0 100%;
    display: block;
    position: relative;
    text-decoration: none;
}
.pb-card--products .image  {
    padding: 0 0 100%;
}
.pb-card--lines .image  {
    padding: 0 0 82.8%;
}
.pb-card--complements .image  {
    padding: 0 0 82.8%;
}
[class^="pb-card pb-card--downloads-v2"] .image {
    padding: 0 0 56.28%;
}
.pb-card .image > div {
    width: auto;
    height: 50px;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    background: var(--color-white);
}
[class^="pb-card pb-card--downloads"] .image > div {
    display: flex;
}
.pb-card .image > div img {
    width: 16px;
    height: 16px;
    margin: 0 16px 0 0;
    position: relative;
}
.pb-card .image > div span {
    font-size: 1rem;
    color: var(--color-dark-alt)
}
.pb-card .image img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: center left;
}
.pb-list[data-area="revestimentos"][data-type="lines"] .pb-card .image img {
    object-fit: cover;
}
.pb-card .content {
    display: block;
    padding: 1rem 0;
}
.pb-card .content,
.pb-card .content * {
    text-decoration: none;
}
[class^="pb-card pb-card--downloads-v2"] .content {
    margin: 0;
    padding: 1rem 0 0;
}
.pb-card .content .title {
    display: block;
    font-size: 1rem;
    color: var(--color-main);
    margin: 0;
    margin-bottom: 8px;
    font-weight: 300;
}
[class^="pb-card pb-card--downloads-v2"] .content .title {
    min-height: 45px;
    margin: 0.75rem 0;
}
.pb-card .content .info {
    display: block;
    font-size: 0.875rem;
    color: var(--color-dark);
}
.pb-card .content .likes {
    display: flex;
    font-size: 0.875rem;
    color: var(--color-dark);
}
.pb-card .content .likes > img {
    width: 12px;
    margin: 0 5px 0 0;
}
.pb-card .content .likes > span {
    margin: 0 2px;
}
.pb-card.new .content:after {
    width: auto;
    display: inline-block;
    content: "Lançamento";
    margin-top: 2px;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 0.5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-lightest);
}
html[lang="es-es"].pb-card.new .content:after {
    content: "Lanzamiento";
}
html[lang="en"].pb-card.new .content:after {
    content: "New";
}
[class^="pb-card pb-card--downloads"] .content .info {
    display: none
}
.pb-card--favorite .content {
    display: grid;
    grid-template-columns: 1fr 42px;
    grid-template-areas: "content favorite";
}
.pb-card--favorite .content a {
    grid-area: content;
}
.pb-favorite {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.pb-favorite:hover {
    cursor: pointer;
}
.pb-favorite input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
}
.pb-favorite input ~ img.favorite-false {
    display: block;
    position: relative;
    z-index: 2;
}
.pb-favorite input ~ img.favorite-true {
    display: none;
}
.pb-favorite input:checked ~ img.favorite-true {
    display: block;
    position: relative;
    z-index: 2;
}
.pb-favorite input:checked ~ img.favorite-false {
    display: none;
}
[class^="pb-card pb-card--downloads-v2"] .complement {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
[class^="pb-card pb-card--downloads-v2"] .complement ul {
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
}
[class^="pb-card pb-card--downloads-v2"] .complement ul li {
    margin: 0 5px 5px 0;
    padding: 6px 10px;
    font-size: 0.5625rem;
    text-transform: uppercase;
    color: var(--color-black);
    background: var(--color-lightest);
    border-radius: 3px;
    line-height: 1.2em;
}
[class^="pb-card pb-card--downloads-v2"] .complement .actions {
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 10px;
}
[class^="pb-card pb-card--downloads-v2"] .complement .actions .btn-more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-main);
}
[class^="pb-card pb-card--downloads-v2"] .complement .actions .btn-more.download img {
    margin: 0 16px 0 0;
}
[class^="pb-card pb-card--downloads-v2"] .complement .actions .btn-more.list span {
    display: none;;
}
.btn.btn-more-new {
    width: 100%;
    height: 46px;
    max-width: 460px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 2px solid var(--color-darkest);
    border-radius: 10px;
}
@media (max-width: 992px) {  
    .pb-card .content .title {
        font-size: 0.875rem;
    }
    .pb-card .content .info {
        font-size: 0.75rem;
    }
    .pb-carousel .pb-card {
        width: var(--card-size);
    }
    .pb-carousel .pb-card .content .title {
        font-size: 1.125rem;
    }
    .pb-carousel .pb-card .content .info {
        font-size: 1rem;
    }
}
/* Itemd e Produto, variação Officina */
.pb-card.pb-card--officina .content .info {
    display: block;
    margin-top: 10px;
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--color-light);
}
.pb-card.pb-card--officina .content .format {
    display: block;
    margin: 20px 0 6px;
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--color-dark-alt);
}
/* Item de Produto BlackDays 2021 */
.pb-card.pb-card--blackdays2021 {

    position: relative;
    border: 1px solid #E0E0E0;
}
.pb-card.pb-card--blackdays2021 a {
    text-decoration: none;
}
.pb-card.pb-card--blackdays2021 .tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2px 7px 7px;
    position: absolute;
    top: 8px;
    left: 7px;
    border-radius: 3px;
    background: var(--color-darkest);
}
.pb-card.pb-card--blackdays2021 .tag span {
    display: block;
    font-size: 0.71rem;
    color: #f5ce81;
}
.pb-card.pb-card--blackdays2021 .tag span:nth-of-type(2) {
    font-size: 1.25rem;
    font-weight: 700;
}
.pb-card.pb-card--blackdays2021 .title {
    min-height: 52px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px 13px;
}
.pb-card.pb-card--blackdays2021 .title .tag-entrega span {
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    top: -10px;
    position: absolute;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    color: #000;  
    background: #F5CE81;
  }
.pb-card.pb-card--blackdays2021 .title,
.pb-card.pb-card--blackdays2021 .title * {
    font-size: 1.143rem;
    font-weight: 400;
    color: var(--color-darker);
}
.pb-card.pb-card--blackdays2021 dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "size-title surface-title" "size-data surface-data" "code-title border-title" "code-data border-data";
    margin: 0;
}
.pb-card.pb-card--blackdays2021 dl dt,
.pb-card.pb-card--blackdays2021 dl dd {
    border: none !important;
}
.pb-card.pb-card--blackdays2021 dl dt a {
    display: block;
    margin: 0;
    padding: 0 0 0 13px;
    font-size: 0.571rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: #AFAFAF;
}
.pb-card.pb-card--blackdays2021 dl dt:nth-of-type(2n) a {
    padding: 0;
}
.pb-card.pb-card--blackdays2021 dl dd a {
    display: block;
    margin: 0;
    padding: 0 0 14px 13px;
    font-size: 0.93rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color-dark-alt);
}
.pb-card.pb-card--blackdays2021 dl dd:nth-of-type(2n) a {
    padding: 0 0 14px;
}
.pb-card.pb-card--blackdays2021 dl dt.size {
    grid-area: size-title;
}
.pb-card.pb-card--blackdays2021 dl dd.size {
    grid-area: size-data;
}
.pb-card.pb-card--blackdays2021 dl dt.surface {
    grid-area: surface-title;
}
.pb-card.pb-card--blackdays2021 dl dd.surface {
    grid-area: surface-data;
}
.pb-card.pb-card--blackdays2021 dl dt.code {
    grid-area: code-title;
}
.pb-card.pb-card--blackdays2021 dl dd.code {
    grid-area: code-data;
}
.pb-card.pb-card--blackdays2021 dl dt.border {
    grid-area: border-title;
}
.pb-card.pb-card--blackdays2021 dl dd.border {
    grid-area: border-data;
}

/* Item de Produto Desconto */
.pb-card.pb-card--discount {

    position: relative;
    border: 1px solid #E0E0E0;
}
.pb-card.pb-card--discount a {
    text-decoration: none;
}
.pb-card.pb-card--discount .tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2px 7px 7px;
    position: absolute;
    top: 8px;
    left: 7px;
    border-radius: 3px;
    background: var(--color-darkest);
}
.pb-card.pb-card--discount .tag span {
    display: block;
    font-size: 0.71rem;
    color: #f5ce81;
}
.pb-card.pb-card--discount .tag span:nth-of-type(2) {
    font-size: 1.25rem;
    font-weight: 700;
}
.pb-card.pb-card--discount .title {
    min-height: 52px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px 13px;
}
.pb-card.pb-card--discount .title .tag-entrega span {
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    top: -10px;
    position: absolute;
    z-index: 10;
    font-size: 10px;
    font-weight: 500;
    color: #000;  
    background: #F5CE81;
  }
.pb-card.pb-card--discount .title,
.pb-card.pb-card--discount .title * {
    font-size: 1.143rem;
    font-weight: 400;
    color: var(--color-darker);
}
.pb-card.pb-card--discount dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "size-title surface-title" "size-data surface-data" "code-title border-title" "code-data border-data";
    margin: 0;
}
.pb-card.pb-card--discount dl dt,
.pb-card.pb-card--discount dl dd {
    border: none !important;
}
.pb-card.pb-card--discount dl dt a {
    display: block;
    margin: 0;
    padding: 0 0 0 13px;
    font-size: 0.571rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    color: #AFAFAF;
}
.pb-card.pb-card--discount dl dt:nth-of-type(2n) a {
    padding: 0;
}
.pb-card.pb-card--discount dl dd a {
    display: block;
    margin: 0;
    padding: 0 0 14px 13px;
    font-size: 0.93rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--color-dark-alt);
}
.pb-card.pb-card--discount dl dd:nth-of-type(2n) a {
    padding: 0 0 14px;
}
.pb-card.pb-card--discount dl dt.size {
    grid-area: size-title;
}
.pb-card.pb-card--discount dl dd.size {
    grid-area: size-data;
}
.pb-card.pb-card--discount dl dt.surface {
    grid-area: surface-title;
}
.pb-card.pb-card--discount dl dd.surface {
    grid-area: surface-data;
}
.pb-card.pb-card--discount dl dt.code {
    grid-area: code-title;
}
.pb-card.pb-card--discount dl dd.code {
    grid-area: code-data;
}
.pb-card.pb-card--discount dl dt.border {
    grid-area: border-title;
}
.pb-card.pb-card--discount dl dd.border {
    grid-area: border-data;
}
/* Item de Galeria */
.pb-item--gallery .image {
    height: auto;
    padding: 0;
}
.pb-item--gallery .image img {
    position: relative;
    border-radius: 4px;
}
.pb-item--gallery .content .title {
    color: var(--color-dark);
    font-size: 0.75rem;
}
.pb-item--gallery .content .info {
    color: var(--color-dark);
    font-size: 0.625rem;
}
.pb-item--gallery.avatar
{
    height: auto !important;
    min-height: 260px;
}
.pb-item--gallery.avatar .content {
    display: grid;
    grid-template-columns: 38px auto;
    grid-template-areas: "avatar title" "avatar subtitle";
    column-gap: 15px;
}
.pb-item--gallery.avatar .content .avatar {
    grid-area: avatar;
}
.pb-item--gallery.avatar .content .avatar img {

    border-radius: 50%;
}
.pb-item--gallery.avatar .content .title {
    grid-area: title;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}
.pb-item--gallery.avatar .content .info {
    grid-area: subtitle;
}
/* Officina */
.pb-item--product .producttitle {
    display: block;
    margin: 8px 0 2px;
    font-size: 1rem;
    font-weight: 700;
}
.pb-item--product .finishing {
    display: block;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 300;
    text-transform: capitalize;
}
/* Item de Categoria de Complemento */
.pb-card--extras .image {
    width: 100%;
    height: 0;
    display: block;
    position: relative;
    padding-bottom: 100%;
    border: 1px solid var(--color-light);
    border-radius: 4px;
}
.pb-card--extras .image img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    object-fit: scale-down;
    object-position: center center;
}
.pb-card--extras .content {
    margin: 0;
    padding: 0;
}
.pb-card--extras .content .title {
    margin: 12px 0 0;
    font-size: 0.75rem;
    color: var(--color-main);
    font-weight: 700;
    text-align: center;
}

/* Tag */
.pb-tag {
    height: 28px;
    padding: 0 24px;
    display: inline-block;
    border: 1px solid var(--color-lightest);
    border-radius: 14px;
    font-size: .75rem;
    color: var(--color-darkest) !important;
    font-weight: 500;
    line-height: 28px;
    vertical-align: middle;
    text-decoration: none !important;
    white-space: nowrap;
    background: var(--color-white);
}
.pb-tag.selected {
    background: var(--color-lightest);
}

/* Card de Loja em Resultado de Busca */
.pb-store {
    max-width: 380px;
    display: inline-block;
    border-radius: 4px;
    background: var(--color-white);
}
.pb-store > a.header {
    display: grid;
    grid-template-columns: 32px 1fr;
    column-gap: 8px;
    min-height: 38px;
    margin: 0px !important;
    padding: 20px;
    border-bottom: 1px solid var(--color-lightest);
    text-decoration: none;;
}
.pb-store > a.header .name {
    justify-self: start;
    align-self: center;
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-black);
}
.pb-store > dl {
    padding: 20px;
    display: grid;
    grid-template-columns: 32px 1fr;
    column-gap: 12px;
    row-gap: 20px;
}
.pb-store > dl dt {
    justify-self: end;
    align-self: start;
}
.pb-store > dl dt span {
    display: none;
}
.pb-store > dl dd,
.pb-store > dl dd * {
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-dark);
    text-decoration: none;
}

/* Formulários */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.875rem;
    color: var(--color-darker);
    text-transform: none;
}
::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.875rem;
    color: var(--color-darker);
    text-transform: none;
}
:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.875rem;
    color: var(--color-darker);
    text-transform: none;
}
:-moz-placeholder { /* Firefox 18- */
    font-size: 0.875rem;
    color: var(--color-darker);
    text-transform: none;
}
.form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid var(--color-dark);
}
.form-control:disabled, .form-control[readonly] {
    background: none !important;
    opacity: 0.35;
}
.form-control:disabled:hover, .form-control[readonly]:hover {
    cursor: not-allowed;
}
input[type=checkbox] {
    width: 16px;
    height: 16px;
/*    margin-top: 0.2rem; */
    margin-top: 0px;
}
input[type=radio] {
    width: 16px;
    height: 16px;
    /*margin-top: 0.2rem;*/
    margin-top: 0;
}
.form-group > label,
.form-group > label {
    margin: 0;
    padding: 0 0.75rem;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
}
.form-check-group {
    padding: 0.375rem 0.75rem;
}
.form-check-group > .form-check-inline {
    margin-right: 40px;
}
/* Botões */
.btn {
    font-size: 0.875rem;
    line-height: 1.75rem;
}
.btn-secondary {
    font-weight: 900;
    color: var(--color-main);
    background-color: var(--color-lightest);
    border: transparent;
}
.btn-darkest {
    font-weight: 400;
    color: var(--color-white);
    background-color: var(--color-darkest);
    border: 1px solid var(--color-main);    
}
.btn-black {
    font-weight: 400;
    color: var(--color-white);
    background-color: var(--color-darkest);
    border: 1px solid var(--color-main);    
    padding: 15px 30px 13px;
    text-transform: uppercase;
    letter-spacing: 3px;
    border-radius: 0;
}
.btn-black:hover {
    color: var(--color-lighter);
}
.btn-darkest:hover {
    color: var(--color-white);
}
.btn-light {
    font-weight: 900;
    color: var(--color-main);
    background-color: transparent;
    border: 1px solid var(--color-main);
}
.btn-light:hover {
    border: 1px solid var(--color-main);
}
.btn-lightest {
    max-width: 128px;
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--color-dark-alt);
    background-color: var(--color-lightest);
    border: transparent;
}
.btn-lightest.btn-icon {
    padding: 0.375rem 0.75rem 0.375rem calc(0.375rem + 15px);
}
.btn-lightest.btn-icon img[src*="chevron"] {
    top: 12px;
    left: 14px;
}
.btn-lightest.btn-icon img[src*="favorite"] {
    width: 13.6px;
    height: 12px;
    top: 10px;
    left: 11px;
}
.btn-icon {
    position: relative;
    padding: 0.375rem calc(0.75rem + 40px);
}
.btn-icon img {
    position: absolute;
    top: calc((100% - 20px)/2);
    left: 20px;
}
.btn-icon-secondary {
    padding: 6px 20px 6px 41px;
    color: var(--color-main);
    background-color: var(--color-lightest);
    border: transparent;
}
.btn-icon-secondary img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc((100% - 16px)/2);
    left: 20px;
}
.pb-close {
    width: 20px;
    height: 20px;
    /*display: block;*/
    display: inline;
    position: relative;
    margin: 0 0 0 16px;
    text-transform: uppercase;    
}
.pb-close:before {
    width: 28px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    bottom: 9px;
    left: -3px;
    transform: rotate(45deg);
    background: var(--color-dark);
}
.pb-close:after {
    width: 28px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    bottom: 9px;
    left: -3px;
    transform: rotate(-45deg);
    background: var(--color-dark);
}
.btn-contact {
    min-width: 145px;
    height: 45px;
    padding: 0 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 22.5px;
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--color-white);
    text-transform: uppercase;
    background: var(--color-main);
}
.btn-contact.btn-chat:hover span,
.btn-contact.btn-whatsapp:hover span {
    color: var(--color-white);

}
.btn-contact > img {
    margin: 0 8px 0 0 ;
}
.btn-contact.btn-chat {
    background: var(--color-main);
}
.btn-contact.btn-whatsapp {
    background: var(--color-green);
}
.btn-more {
    width: 80%;
    max-width: 246px;
    height: 40px;
    font-size: 0.875rem;
    color: var(--color-main);
    font-weight: 700;
    line-height: 2.25;
    border: 1px solid  var(--color-lightest);
}
.btn-submit {
    width: 100%;
    height: 40px;
    font-size: 0.875rem;
    color: var(--color-main);
    font-weight: 700;;
    border: 1px solid  var(--color-main);
    background: var(--color-lightest);
}
.btn-cancel {
    width: 100%;
    height: 40px;
    font-size: 0.875rem;
    color: var(--color-main);
    font-weight: 700;;
    border: 1px solid  var(--color-main);
    background: var(--color-white);
}

.btn-white {
    height: 40px;
    font-size: 0.875rem;
    color: var(--color-main);
    font-weight: 500;
    border: 1px solid  var(--color-main);
    text-transform: uppercase;
    background: var(--color-white);
    border-radius: 0px;
    letter-spacing: 3px;
    padding-left: 20px;
    padding-right: 20px;
}
.link-bold {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--color-light);
    border-bottom: 2px solid var(--color-lightest);
}
.link-small {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--color-light);
}
.btn-sharp {
    padding: 11px 40px;
    border: 1px solid var(--color-black);
    border-radius: 0px;
    font-size: 0.75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.btn-light-v2 {
    padding: 9px 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    border-radius: 0px;
    color: var(--color-black);
    border: 2px solid var(--color-black);
}
/* Navegador */
.pb-nav {
    font-size: 0.875rem;
    color: var(--color-light);
} 
.pb-nav a {
    position: relative;
    color: var(--color-light);
}
.pb-nav a.active {
    color: var(--color-main);
    font-weight: 900;
}
.pb-nav a.active:after {
    width: calc(100% - 2rem);
    height: 2px;
    display: block;
    content: "";
    position: absolute;
    bottom: 0.25rem;
    left: 1rem;
    background: var(--color-lightest);
}

/* Tableas de especificações */

.specs {
    width: 100%;
    border-collapse: collapse;
} 
.specs thead {
    display: none;
} 
.specs tr {
    height: 1.25rem;
}
.specs tr:nth-of-type(odd) {
    background: var(--color-lightest);
}
.specs td {
    width: 50%;
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--color-darkest);
    line-height: 1.2em;
    vertical-align: center;
} 
.specs th:nth-of-type(1) {
    width: calc(50% - 8px);
    padding: 0 0 0 8px;
} 
.specs th {
    width: 50%;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--color-darkest);
    line-height: 1.2em;
    vertical-align: center;
} 

@media (max-width:992px) {
    .specs {
        width: 100%;
    }
    .specs td {
        padding: 0 8px 0 0;
        text-align: right;
    }
}
/* Animação de Carregamento (Loading) */
.pb-loading {
    display: inline-block;
    position: relative;
    width: var(--animation-size);
    height: var(--animation-size);
}
.pb-loading div {
    position: absolute;
    top: calc((var(--animation-size) - var(--animation-item-size))/2);
    width: var(--animation-item-size);
    height: var(--animation-item-size);
    border-radius: 50%;
    background: var(--animation-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.pb-loading div:nth-child(1) {
    left: 8px;
    animation: pb-loading1 var(--animation-speed) infinite;
}
.pb-loading div:nth-child(2) {
    left: 8px;
    animation: pb-loading2 var(--animation-speed) infinite;
}
.pb-loading div:nth-child(3) {
    left: 32px;
    animation: pb-loading2 var(--animation-speed) infinite;
}
.pb-loading div:nth-child(4) {
    left: 56px;
    animation: pb-loading3 var(--animation-speed) infinite;
}
@keyframes pb-loading1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pb-loading3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes pb-loading2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
/* Pins de Canais de Venda */
.pin {
    display: inline-block;
    position: relative;
}
/*
.pin:after {
    width: 100%;
    height: 100%;
    display: flex;
    content: "";
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 0 0.4rem;
    color: var(--color-white);
    font-weight: 400;
    font-size: 0.625rem;
    z-index: 2;
}
*/
/* Caixa de Descontinuados */
.pb-legacy-box {
    width: 100%;
    /*max-width: 300px;*/
    padding: 26px 25px 30px;
    background: var(--color-lightest);
    color: var(--color-darkest);
    font-size: 0.85rem;
    text-align: center;
}
.pb-legacy-box p {
    margin: 16px 0 0;
    text-align: center;
    line-height: 1.5rem;
}
/* Page Header Padrão */
.pb-pageheader-v2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 0px;
}
.pb-pageheader-v2 .subtitle {
    display: block;
    margin-bottom: 20px;
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--color-darkest);
}
.pb-pageheader-v2 .title {
    margin: 0;
    padding-bottom: 20px;
    font-size: 1.875rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
.pb-pageheader-v2 .slogan {
    display: block;
    margin: -4px 0 0;
    padding-bottom: 20px;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-darkest);
}
.pb-pageheader-v2:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -4px;
    left: 0px;
    background: var(--color-main);
}
/* Botão Novo */
.btn-darkest-v2 {
    padding: 12px 42px;
    line-height: 1.2em;
    letter-spacing: 2px;
    border-radius: 0px;
    text-transform: uppercase;
    color: var(--color-white);
    background: var(--color-darkest);
    text-decoration: none !important;
}
.btn-darkest-v2:hover {
    color: var(--color-white);
}
.btn-lightest-v2 {
    padding: 12px 42px;
    line-height: 1.2em;
    letter-spacing: 2px;
    border-radius: 0px;
    text-transform: uppercase;
    color: var(--color-main);
    background: var(--color-lightest);
}
.btn-lightest-v2:hover {
    color: var(--color-main);
}
div.field-error
{
    color: red;
    padding: 5px;
}
div.field-error::before
{
    content: "*";
    color: red;
}

/* Favorites */
.pb-item,
.pb-item--container {
    position: relative;
}
.favorite-cta {
    width: 38px;
    height: 38px;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #f1f1f1;
    z-index: 5;
}
body.hasWishlist .favorite-cta {
    display: flex;
}
.favorite-cta.disabled {
	opacity: 0.65;
	color: #777;
    pointer-events:none;
}
.favorite-cta.disabled:hover {
    pointer-events:none;
}

.favorite-cta > .icon {
    display: block;
}
.favorite-cta > .icon-hover {
    display: none;
}
.favorite-cta[favorited] .icon,
.favorite-cta:hover > .icon {
    display: none;
}
.favorite-cta[favorited] .icon-hover,
.favorite-cta:hover > .icon-hover {
    display: block;
}

.favorite-page-cta {
    display: none;
    justify-content: flex-start;
    align-items: center;
}
body.hasWishlist .favorite-page-cta {
    display: flex;
}
.favorite-page-cta > .icon {
    margin: 0 8px 0 0;
}
.favorite-page-cta > .icon .icon {
    display: block;
}
.favorite-page-cta > .icon .icon-hover {
    display: none;
}
.favorite-page-cta[favorited] > .icon .icon,
.favorite-page-cta:hover > .icon .icon {
    display: none;
}
.favorite-page-cta[favorited] > .icon .icon-hover,
.favorite-page-cta:hover > .icon .icon-hover {
    display: block;
}

.newlink {
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--color-light) !important;
    border-bottom: 2px solid var(--color-lightest);
    text-decoration: none !important;
}
.mainlink {
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-light) !important;
    text-decoration: underline var(--color-lightest) 2px;
    text-align: center;
}
/* Novo Padrão de Modal */
.pb-modal-v2 .modal-content .modal-header {
    align-items: center;
    padding: 37px 53px 0;
	border: none !important;
}
.pb-modal-v2 .modal-content .modal-header .modal-title {
    font-size: 24px;
    font-weight: 300;
    color: var(--color-darker-alt);
}
.pb-modal-v2 .modal-content .modal-header > button {
    position: relative;
    margin: 0px;
    padding: 0 30px 0 0;
    opacity: 1;
}
.pb-modal-v2 .modal-content .modal-header > button > span {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--color-dark);
    vertical-align: middle;
}
.pb-modal-v2 .modal-content .modal-header > button:after {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 10px;
    background: var(--color-dark);
    transform: rotate(45deg);
  }
.pb-modal-v2 .modal-content .modal-header > button:before {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 10px;
    background: var(--color-dark);
    transform: rotate(-45deg);
  }
.pb-modal-v2 .modal-content .modal-body {
    padding: 30px 53px;
}
.pb-modal-v2 .modal-content .modal-footer {
    border: none !important;
    justify-content: center;
    padding: 0px 53px 37px;
}
.pb-modal-v2 .modal-content .modal-footer > .btn {
    width: 100%;
    max-width: 302px;
    padding: 8px 20px;
    font-size: 14px;
}
@media (max-width: 992px) {
    .pb-modal-v2.show {
        height: calc(100% - var(--mainmenu-height));
        padding-right: 0px !important;
        top: var(--mainmenu-height);
    }
    .pb-modal-v2.show .modal-dialog {
        width: 100%;
        max-width: 100%;
        margin: 0;
        height: auto;
        min-height: 100%;
    }
    .pb-modal-v2 .modal-content {
        min-height: calc(100vh - var(--mainmenu-height));
        border: none;
    }
    .pb-modal-v2 .modal-content .modal-header {
        padding: 18px 22px 0;
    }
    .pb-modal-v2 .modal-content .modal-body {
        padding: 30px 22px;
    }
    .pb-modal-v2 .modal-content .modal-footer {
        padding: 0px 22px 18px;
    }
}


.splide__pagination {
    margin-top: 30px;
    gap: 14px;
}
.splide__pagination li button {
    width: 15px;
    height: 15px;
    border: none;
    background: var(--color-lightest);
}
.splide__pagination li button.is-active {
    background: #b1b5ba;
}
.pb-btn-darkest {
    width: auto;
    display: inline-block;
    padding: 15px 40px;
    line-height: 1.2em;
    letter-spacing: .4px;
    border-radius: 0;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-white);
    background-color: var(--color-darkest);
    border: 1px solid var(--color-darkest);
}
.pb-btn-darkest:hover {
    color: var(--color-lighter);
}
.pb-cta-collapsible {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 0;
    border: 0;
    font-size: 14px;
    font-weight: 400;
    background: none;
}
.pb-cta-collapsible:after {
    width: 7px;
    height: 7px;
    display: block;
    content: "";
    margin: -4px 0 0;
    border: 2px solid #8390a5;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
}
.collapse.show + .pb-cta-collapsible:after {
    margin: 4px 0 0;
    transform: rotate(-135deg);
}
.form--type label{
    display: block;
}

/* Banners Portobello Mais Arquitetura */
.banner-maisarq2023,
.banner-maisarq2023 .banner-desktop,
.banner-maisarq2023 .banner-mobile {
    width: 100%;
    display: block;
}
.banner-maisarq2023 .banner-mobile {
    display: none;
    padding: 0 20px;
}
@media (max-width: 992px) {
    .banner-maisarq2023 .banner-desktop {
        display: none;
    }
    .banner-maisarq2023 .banner-mobile {
        display: block;
    }    
}
dialog{
    background: white;
    z-index: 99;
}

/* Fix Botões */
.btn-close {
    position: relative;
}
.btn-close .pb-close {
    position: absolute;
    right: -20px;
    top: -3px;
}
button.icon-only > svg + span {
    display: none;
}

/* Fix para remover padding-right e alinha modal ao centro quand aberta */
#modal-video{
    padding-right: 0px;
}