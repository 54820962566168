:root {
  --color-darkest: #222;
  --color-darker: #414141;
  --color-darker-alt: #403C55;
  --color-dark: #707070;
  --color-dark-alt: #707480;
  --color-light: #828FA5;
  --color-lighter: #a2a2a2;
  --color-lighter-alt: #e1e5ed;
  --color-lightest: #E6EAF2;
  --color-lightest-alt: #efefef;
  --color-white: #fff;
  --color-white-alt: #F8F9FB;
  --color-black: #1D2029;
  --color-main: #092650;
  --color-green:#009649;
  --color-unltd: #89B32F;
  --color-sales-1: #3495C1; /* Construtoras */
  --color-sales-2: #E71170; /* Multimarcas */
  --color-sales-4: #003166; /* Portobello Shop */
  --color-sales-5: #000; /* Export */
  --color-sales-10: #003166; /* Portobello America */
  --color-sust: #687B4F;
  --color-sust-alt: #AAB59C;
  --color-seo: #63666A;
  --max-container: 1620px;
  --max-row: 1532px;
  --container-grid-cols: 12;
  --container-grid-spacer: 30px;

  --mainmenu-height: 96px;

  --z-menu: 5000;
  --z-modal: 10000;
  --z-lightbox: 12000;

  --card-size: 100%;

  --animation-size: 80px;
  --animation-item-size: 19px;
  --animation-speed: 0.6s;
  --animation-color: #828FA5;

  --shadow-default: -0px 0px 15px 0px rgba(0,0,0,0.2); 
}
@media (max-width: 1720px) {
  :root {
    --max-row: calc(100vw - 120px);
  }
}
@media (max-width: 992px) {
  :root {
    --mainmenu-height: 63px;
    --card-size: 232px;
  }
}