.pb23-banner {
    width: 100%;
    height: var(--banner-height);
    aspect-ratio: var(--banner-aspect);
    position: relative;
    isolation: isolate;
    overflow: hidden;
    border-radius: var(--box-radius);
    box-shadow: var(--banner-shadow);
}
.pb23-banner .content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: var(--banner-align--y,flex-end);;
    align-items: var(--banner-align--x,flex-start);
    padding: var(--banner-padding--y) var(--banner-padding--x);
    text-decoration: none;
}
.pb23-banner.center-bottom .content {
    justify-content: flex-end;
    align-items: center;
}
.pb23-banner.left-bottom .content {
    justify-content: flex-end;
    align-items: flex-start;
}
.pb23-banner.left-bottom .content .subtitle,
.pb23-banner.left-bottom .content .title {
    width: 100%;
    max-width: 50%;
    text-align: left;
}
.pb23-banner.cta-collapse .content .cta-wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0fr;
    overflow: hidden;
    padding: 0;
    transition: grid-template-rows 0.3s ease-out, padding 0.3s ease-out;
}
.pb23-banner.cta-collapse .content .cta-wrapper > b {
    overflow: hidden;
    display: block;
}
.pb23-banner.cta-collapse:hover .content .cta-wrapper {
    padding: var(--banner-cta--spacer) 0 0;
    grid-template-rows: 1fr;
}
.pb23-banner.hover-fx:not(.hover-dark-fx):hover .image:before {
    animation: bannerHoverFx;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}
.pb23-banner.hover-dark-fx:hover .image:before {
    animation: bannerHoverDarkFx;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}
.pb23-banner.center-center .content {
    justify-content: center;
    align-items: center;
}
.pb23-banner .content .subtitle {
    order: 1;
    margin: 0 0 var(--banner-subtitle--spacer);
    font-size: var(--banner-subtitle--font);
    font-weight: 500;
    line-height: var(--banner-subtitle--line,1.2);
    color: var(--clr-white);
    text-transform: var(--banner-subtitle--transform,uppercase);
    letter-spacing: var(--banner-subtitle--spacing,0.5px);
    text-align: left;
}
.pb23-banner .content .title {
    order: 2;
    margin: 0 0;
    font-size: var(--banner-title--font);
    font-weight: 300;
    line-height: var(--banner-title--line,1.2);
    color: var(--clr-white);
    text-align: left;
}
.pb23-banner .content .logo {
    order: 2;
    max-height: 60%;
    display: block;
}
.pb23-banner .content .logo > img {
    max-width: 100%;
    max-height: 100%;
    display: none;
    object-fit: center;
    object-fit: contain;
}
.pb23-banner .content .description {
    order: 3;
    margin: var(--banner-description--spacer) 0 0;
    font-size: var(--banner-description);
    font-weight: 400;
    line-height: 1.2857;
    color: var(--clr-white);
    text-align: left;
}
.pb23-banner .content .logo > img.default {
    display: block;
}
.pb23-banner .content .cta-wrapper {
    order: 4;
    margin: 0;
    padding: var(--banner-cta--spacer) 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--banner-cta--gap);
}
.pb23-banner .content i.pb23-link {
    font-size: var(--banner-cta--font,0.875rem);
    font-style: normal;
    color: var(--clr-white);
}
.pb23-banner .content i.pb23-link,
.pb23-banner .content i.pb23-link:after {
    opacity: 0;
}
.pb23-banner:hover .content i.pb23-link,
.pb23-banner:hover .content i.pb23-link:after {
    opacity: 1;
}
/*.pb23-banner .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}*/
.pb23-banner .image:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--banner-fx);
}
.pb23-banner .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.pb23-banner .image > img {
    width: 100%;
    height: 100%;
    display: none;
    position: relative;
    z-index: 1;
    object-fit: cover;
    object-position: center;
}
.pb23-banner .image > img.default {
    display: block;
}
.pb23-banner .video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
}
.pb23-banner .video:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--banner-fx);
}
.pb23-banner .video > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.pb23-banner .video > iframe.desktop {
    display: block;
}
.pb23-banner .video > iframe.mobile {
    display: none;
}
.pb23-banner a.pb23-link,
.pb23-banner a.pb23-link:hover {
    text-decoration: none;
}
.pb23-banner .linkarea {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}
.pb23-banner .pb23-link span {
    font-weight: 700;
}
@media (max-width: 1180px) {
    .pb23-banner .content .logo:has(> img.tablet) > img.default {
        display: none;
    }
    .pb23-banner .content .logo:has(> img.tablet) > img.tablet {
        display: block;
    }
    .pb23-banner .image:has(> img.tablet) > img.default {
        display: none;
    }
    .pb23-banner .image:has(> img.tablet) > img.tablet {
        display: block;
    }
    .pb23-banner.left-bottom .content .subtitle,
    .pb23-banner.left-bottom .content .title {
        max-width: 75%;
    }  
    .pb23-banner.cta-collapse .content .cta-wrapper {
        padding: var(--banner-cta--spacer) 0 0;
        grid-template-rows: 1fr;
    }
    .pb23-banner .content i.pb23-link, .pb23-banner .content i.pb23-link:after {
        opacity: 1;
    }
}
@media (max-width: 769px) {
    .pb23-banner .content .logo:has(> img.mobile) > img.default,
    .pb23-banner .content .logo:has(> img.mobile) > img.tablet {
        display: none;
    }
    .pb23-banner .content .logo:has(> img.mobile) > img.mobile {
        display: block;
    }
    .pb23-banner .image:has(> img.mobile) > img.default,
    .pb23-banner .image:has(> img.mobile) > img.tablet {
        display: none;
    }
    .pb23-banner .image:has(> img.mobile) > img.mobile {
        display: block;
    }
    .pb23-banner .content .cta-wrapper {
        width: 100%;
        flex-direction: column;
    }
    .pb23-banner .content .cta-wrapper .pb23-btn {
        width: 100%;
        order: 1;
    }
    .pb23-banner .content .cta-wrapper .pb23-link {
        order: 2;
        align-self: center;
    }
    .pb23-banner.left-bottom .content .subtitle,
    .pb23-banner.left-bottom .content .title {
        max-width: 100%;
    }
    .pb23-banner .video > iframe.desktop {
        display: none;
    }
    .pb23-banner .video > iframe.mobile {
        display: block;
    }
}

