#page-sustainability,
#page-sustainability * {
    box-sizing: border-box;
    --header-spacer: 36px;
}
#page-sustainability .btn-more {
    line-height: 2.3;
}
#page-sustainability header,
#page-sustainability header h1,
#page-sustainability header h1 img {
    width: 100%;
}
#page-sustainability aside {
    margin-top: var(--header-spacer);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#page-sustainability aside > header {
    display: block;
}
#page-sustainability aside > header .subtitle {
    display: block;
    margin: 0 0 10px;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.25rem;
}
#page-sustainability aside > header .title {
    display: block;
    position: relative;
    padding: 20px 0 36px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#page-sustainability aside > header .title:after {
    display: block;
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: var(--color-main);
}
#page-sustainability aside > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}
#page-sustainability aside > ul .btn {
    white-space: nowrap;
    max-width: none;
}
#page-sustainability aside > ul .btn-clear {
    margin: 0 10px;
}
#page-sustainability aside > ul .btn-clear {
    color: var(--color-light);
}
#page-sustainability aside > ul .btn-more {
    width: 180px;
    line-height: 2.3;
}
@media (max-width: 992px) {
    #page-sustainability aside,
    #page-sustainability aside > ul {
        display: block;
    }
    #page-sustainability aside > ul {
        margin: 20px 0 0;
        display: none;
    }
    #page-sustainability aside > ul .btn-clear {
        margin: 10px 0;
        padding: 0;
    }
}
#page-sustainability #conceito {
    margin-top: 64px;
}
#page-sustainability #conceito p {
    margin-bottom: 70px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
}
#page-sustainability #conceito p.centered {
    text-align: center;
}
#page-sustainability #conceito .video-container {
    width: 100%;
    height: 0;
    margin-bottom: 60px;
    padding: 0 0 56.65%;
    position: relative;
}
#page-sustainability #conceito .video-container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
#page-sustainability #conceito .link {
    margin-bottom: 60px;
}
#page-sustainability #conceito .link img {
    display: block;
    margin: 0 auto 36px;
}
#page-sustainability #conceito .link .btn-more {
    max-width: 120px;
    display: block;
    margin: 0 auto 36px;
    border: 1px solid var(--color-main);
}
#page-sustainability #conceito .steps--page {
    margin-bottom: 40px;
}
#page-sustainability #conceito .steps--page p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: left;
}
#page-sustainability #caminho {
    background: var(--color-sust);
    position: relative;
    overflow: hidden;
}
@media (min-width: 993px){
    #page-sustainability #caminho,
    #page-sustainability #caminho .content {
        height: 640px;
    }
    #page-sustainability #caminho .content h2 {
        max-width: 50%;
    }
    #page-sustainability #caminho .image {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
    }
}
@media (max-width: 992px){
    #page-sustainability #conceito .steps--page,
    #page-sustainability #conceito .steps--page h2,
    #page-sustainability #conceito .steps--page p {
        text-align: center;
    }
    #page-sustainability #conceito .steps--page img {
        margin: 0px;
    }
    #page-sustainability #conceito .steps--page > a{
        margin-bottom: 40px;
    }
}
@media (max-width: 992px){
    #page-sustainability #conceito .steps--page,
    #page-sustainability #conceito .steps--page h2,
    #page-sustainability #conceito .steps--page p {
        text-align: center;
    }
    #page-sustainability #conceito .steps--page img {
        margin: 0px;
    }
    #page-sustainability #conceito .steps--page > a{
        margin-bottom: 40px;
    }
}
#page-sustainability #caminho .image img {
    width: calc(100% + 32px);
    height: 100%;
    margin: 0 -16px;
    object-fit: cover;
    object-position: center left;
}
#page-sustainability #caminho .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
#page-sustainability #caminho .content h2 {
    margin: 0 0 2rem;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--white);
    text-transform: uppercase;
}
#page-sustainability #caminho .content p {
    margin: 0 0 2rem;
    color: var(--white);
    font-weight: 300;
}
#page-sustainability #numeros {
    margin-top : 120px;
    margin-bottom: 60px;
}
#page-sustainability #numeros h2 {
    margin: 0 0 70px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-sust);
    text-transform: uppercase;
}
#page-sustainability #numeros h2 span {
    display: block;
    font-size: 3.5rem;
}
#page-sustainability #numeros .numbers {
    min-height: 140px;
    margin-bottom: 80px;
    padding-left: 39px;
    position: relative;
}
#page-sustainability #numeros .numbers:before {
    width: 5px;
    height: 104px;
    content: "";
    display: block;
    position: absolute;
    left: 15px;
    top: 24px;
    background: var(--color-sust-alt);
}
#page-sustainability #numeros .numbers span {
    line-height: 30px;
    font-weight: 300;
}
#page-sustainability #numeros .numbers span:nth-of-type(1),
#page-sustainability #numeros .numbers:nth-of-type(4) span:nth-of-type(1)
{
    display: block;
}
#page-sustainability #numeros .numbers span b {
    font-weight: 700;
}
#page-sustainability #numeros .numbers:nth-of-type(1) span:nth-of-type(1) {
    display: inline;
}
@media (max-width: 992px) {    
    #page-sustainability #numeros h2 span { 
        font-size: 1.875rem; 
    }
    #page-sustainability #numeros .numbers {
        margin-bottom: 20px;
    }
}
#page-sustainability #cuidado,
#page-sustainability #alternativa {
    margin-bottom: 100px;
}
#page-sustainability #cuidado {
    margin-top: 100px;
}
#page-sustainability #cuidado h2,
#page-sustainability #alternativa h2 {
    margin: 0 0 38px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-sust);
    text-transform: uppercase;
    
}
#page-sustainability #cuidado p,
#page-sustainability #alternativa p {
    font-weight: 300;
    font-size: 0.875rem;
}
#page-sustainability #alternativa p {
    margin-bottom: 60px;
}
@media (max-width: 992px) {    
    #page-sustainability #alternativa img {
        width: 100%;
        margin: 0 0 40px;
    }
}
#page-sustainability #elementos .grid-elements {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 75px;
    row-gap: 30px;
    margin-bottom: 100px;
}
#page-sustainability #elementos .pb-card--element {
    width: 100%;
    max-width: 302px;
    aspect-ratio:  302 / 510;
    padding: 28px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 4px;
    overflow: hidden;
}
#page-sustainability #elementos .pb-card--element p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    margin: 10px 0 58px;    
    color: var(--white);
}
#page-sustainability #elementos .pb-card--element.jazidas {
    background: url("/data/sustainability/jazidas_bg.jpg") top center no-repeat;
    background-size: cover;
}
#page-sustainability #elementos .pb-card--element.agua {
    background: url("/data/sustainability/agua_bg.jpg") top center no-repeat;
    background-size: cover;
}
#page-sustainability #elementos .pb-card--element.emissoes {
    background: url("/data/sustainability/emissoes_bg.jpg") top center no-repeat;
    background-size: cover;
}
#page-sustainability #elementos .pb-card--element.energia {
    background: url("/data/sustainability/energia_bg.jpg") top center no-repeat;
    background-size: cover;
}
#page-sustainability #elementos .pb-card--element.residuos {
    background: url("/data/sustainability/residuo_bg.jpg") top center no-repeat;
    background-size: cover;
}
#page-sustainability #elementos .pb-card--element img {
    max-height: 112px;
    margin: 0 0 15px;
}
#page-sustainability #elementos .pb-card--element .title {
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--white);
    text-transform: uppercase;
}
#page-sustainability #elementos .pb-card--element .btn {
    width: 100%;
    max-width: 100%;
    color: var(--color-white);
}
@media (max-width: 1350px) {
    #page-sustainability #elementos .pb-card--element {
        height: 580px;
    }    
}
@media (max-width: 1209px) {
    #page-sustainability #elementos .pb-card--element {
        height: 640px;
        grid-template-rows: 128px 56px auto 80px;
    }    
    #page-sustainability #elementos .pb-card--element .btn {
        height: 72px;
    }
}
@media (max-width: 1148px) {
    #page-sustainability #elementos .pb-card--element {
        height: 700px;
        grid-template-rows: 128px 56px auto 80px;
    }    
}
@media (max-width: 992px) {
    #page-sustainability #elementos .pb-card--element {
        height: 510px;
        margin-bottom: 35px;
        grid-template-rows: 128px 28px auto 40px;
    }    
    #page-sustainability #elementos .pb-card--element .btn {
        height: 40px;
    }
}
#page-sustainability #nossoproduto,
#page-sustainability #nossagente,
#page-sustainability #nossonegocio {
    padding-top: 60px;
}
#page-sustainability #nossoproduto .image,
#page-sustainability #nossoproduto .image img {
    width: 100%;
}
#page-sustainability #nossoproduto .content img,
#page-sustainability #nossagente .content img,
#page-sustainability #nossonegocio .content img {
    width: 125px;
    height: 132px;
    object-fit: contain;
    object-position: top center;
    margin: 40px 0 30px;
}
#page-sustainability #nossonegocio .content p,
#page-sustainability #nossagente .content p,
#page-sustainability #nossoproduto .content p {
    margin-bottom: 30px;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.875rem;
}
@media (max-width: 992px) {
    #page-sustainability #nossoproduto,
    #page-sustainability #nossagente,
    #page-sustainability #nossonegocio {
	    padding-top: 100px;
    }  
    #page-sustainability #nossoproduto .content img,
    #page-sustainability #nossagente .content img,
    #page-sustainability #nossonegocio .content img {
        display: block;
        margin: 0 auto 40px;
        text-align: center;
    }
    #page-sustainability #nossoproduto .content p,
    #page-sustainability #nossagente .content p,
    #page-sustainability #nossonegocio .content p {
        text-align: center;
    }  
    #page-sustainability #nossoproduto .content .btn,
    #page-sustainability #nossagente .content .btn,
    #page-sustainability #nossonegocio .content .btn {
        width: 100%;
        max-width: 100%;
    }  
}
#page-sustainability #contato {
    padding-top: 114px;
}
#page-sustainability #contato h2 {
    margin: 0 0 28px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-sust);
    text-transform: uppercase;
}
#page-sustainability #contato p {
    margin-bottom: 54px;
}
#page-sustainability #contato .btn {
    margin-top: 30px;
    border: none;
}
@media (max-width: 992px) {
    #page-sustainability #contato {
        margin-top: 64px;
        padding-top: 0px;
    }
}
#page-sustainability #downloads {
    min-height: 680px;
    margin-top: 200px;
    background: url("/data/sustainability/relatorios_bg.png") center center no-repeat;
    background-size: cover;
}
#page-sustainability #downloads h2 {
    padding-top: 100px;
    margin-bottom: 15px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--white);
    text-transform: uppercase;
}
#page-sustainability #downloads p {
    margin: 0 0 90px;
    font-size: 1;
    font-weight: 300;
    color: var(--white);
}
#page-sustainability #downloads .accordion,
#page-sustainability #downloads .accordion .card,
#page-sustainability #downloads .accordion .card * {
    background: none;
    border-radius: 0px;
    border: none;
}
#page-sustainability #downloads .accordion {
    border-top: 1px solid var(--white);
    margin-bottom: 60px;
}
#page-sustainability #downloads .accordion .card {
    border-bottom: 1px solid var(--white);
}
#page-sustainability #downloads .accordion .card-header {
    padding: 0px;
}
#page-sustainability #downloads .accordion .card-header h3 button {
    width: 100%;
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    font-size: 1rem;
    font-weight: 700;
    color: var(--white);
}
#page-sustainability #downloads .accordion .card-header h3 button:after {
    width: 10px;
    height: 10px;
    display: block;
    content: "";
    position: relative;
    right: 12px;
    border-right: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    transform: rotate(-45deg);
}
#page-sustainability #downloads .accordion .card .card-body {
    padding: 0px;
}
#page-sustainability #downloads .accordion .card .card-body .title {
    display: block;
    margin: 0 0 20px;
    font-size: 1.125rem;
    font-weight: 300;
    color: var(--white);
}
#page-sustainability #downloads .accordion .card .card-body ul {
    padding: 0px;
    margin-bottom: 30px;
    list-style: none;
}
#page-sustainability #downloads .accordion .card .card-body ul li {
    margin: 0 0 8px;
    padding: 0px;
}
#page-sustainability #downloads .accordion .card .card-body ul li a {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
    text-decoration: none;
}
#page-sustainability #downloads .accordion .card .card-body ul li a span {
    font-size: 1rem;
    font-weight: 300;
    color: var(--white);
}
/* Páginas Sustentabilidade */
.pages-sustainability--about,
.pages-sustainability--about * {
    --close-y: 48px;
    --close-x: 42px;
}
.pages-sustainability--about header .image-header {
    width: 100%;
    height: 350px;
    margin: 0 0 93px;
    position: relative;
}
.pages-sustainability--about header .image-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: relative;
}
.pages-sustainability--about .back-shortcut {
    padding: 8px 0 18px;
}
.pages-sustainability--about header .btn-close {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--color-black);
    text-transform: uppercase;
    letter-spacing: 2px;
}
.pages-sustainability--about header .btn-close .pb-close {
    margin: 0;
}
.pages-sustainability--about header .icon {
    max-width: 192px;
    max-height: 192px;
    width: 100%;
    height: auto;
    display: block;
    margin: auto;
    text-align: center;    
    object-fit: contain;
    object-position: top center;
}
.pages-sustainability--about header h1 {
    margin: 0 0 20px;
    font-size: 3.75rem;
    font-weight: 900;
}
.pages-sustainability--about header h1 + span {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
}
.pages-sustainability--about header p {
    margin-top: 18px;
}
.pages-sustainability--about header .paragraph {
    margin: 50px 0;
}
#page-sustainability--people.pages-sustainability--about header p,
#page-sustainability--business.pages-sustainability--about header p,
#page-sustainability--product.pages-sustainability--about header p {
    font-size: 1rem;
    font-weight: 700;
}
#page-sustainability--people.pages-sustainability--about header ul,
#page-sustainability--business.pages-sustainability--about header ul,
#page-sustainability--product.pages-sustainability--about header ul {
    list-style: none;
    margin: 0 0 0 16px;
    padding: 0;
}
#page-sustainability--people.pages-sustainability--about header ul li:before,
#page-sustainability--business.pages-sustainability--about header ul li:before,
#page-sustainability--product.pages-sustainability--about header ul li:before {
    content: "\2022";
    color: var(--color-sust);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
#page-sustainability--people.pages-sustainability--about header ul li,
#page-sustainability--business.pages-sustainability--about header ul li,
#page-sustainability--product.pages-sustainability--about header ul li {
    margin: 0 0 10px;
    font-weight: 300;
}
#page-sustainability--people.pages-sustainability--about header .selo img,
#page-sustainability--business.pages-sustainability--about header .selo img,
#page-sustainability--product.pages-sustainability--about header .selo img {
    width: 100%;
    display: block;
    margin-bottom: 34px;
}
.pages-sustainability--about section h2 {
    margin: 0 0 38px;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-sust);
    text-transform: uppercase;
}
.pages-sustainability--about section h2 + p {
    margin: 0 0 44px;
    font-weight: 300;
}
.pages-sustainability--about section p {
    font-weight: 300;
}
.pages-sustainability--about section .subtitle {
    display: block;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
    margin: -26px 0 16px;
}
.pages-sustainability--about .icon-prev {
    display: block;
    margin-bottom: 20px;
}
.pages-sustainability--about .graph-title {
    display: block;
    margin: 0 0 20px;
    font-size: 1rem;
    font-weight: 700;
}
.pages-sustainability--about .graph {
    width: 100%;
}
.pages-sustainability--about .graph-image {
    position: relative;
}
.pages-sustainability--about .graph-info {
    position: relative;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 1.2rem;
}
@media (min-width: 993px) {
    .pages-sustainability--about .graph-info {
        width: 238px;
        padding: 0 0 0 10px;
        position: absolute;
        border-left: 1px solid #000;
        display: inline-block;
        left: 314px;
        bottom: -16px;
    }
    .pages-sustainability--about .graph-info:before {
        width: 50px;
        height: 0px;
        border-top: 1px solid #000;
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: -50px;
    }
}
@media (min-width: 993px) and (max-width: 1150px) {
    .pages-sustainability--about .graph-info {
        width: 180px;
        bottom: -24px;
    }
}
@media (min-width: 993px) and (max-width: 1035px) {
    .pages-sustainability--about .graph-info {
        width: 140px;
        bottom: -50px;
    }
}
.pages-sustainability--about .grid-items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;
    margin: 0;
    padding: 0;
    list-style: none;
}
.pages-sustainability--about .grid-items li span {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
}
.pages-sustainability--about .grid-items li p {
    margin: 0;
    font-weight: 300;
}
.pages-sustainability--about .grid-items.withbars li {
    max-width: 257px;
    min-height: 68px;
    padding-left: 28px;
    position: relative;
}
.pages-sustainability--about .grid-items.withbars li:before {
    width: 5px;
    height: calc(100% - 16px);
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    top: 16px;
    background: var(--color-sust-alt);
}
.pages-sustainability--about .grid-items.withbars li span {
    line-height: 30px;
    text-transform: none;
    font-weight: 300;
}
.pages-sustainability--about .grid-items.withbars li span:nth-of-type(1) {
    display: block;
    font-weight: 700;
}
@media (max-width: 992px) {
    .pages-sustainability--about,
    .pages-sustainability--about * {
        --close-y: 20px;
        --close-x: 20px;
    }
    .pages-sustainability--about header .image-header {
        height: auto;
        margin: 0 0 60px;
    }
    .pages-sustainability--about header .image-header img {
        height: auto;
    }
    .pages-sustainability--about header h1 {
        margin: 80px 0 28px;
        font-size: 1.875rem;
        text-align: center;
    }
    .pages-sustainability--about header h1 + span {
        font-size: 1.125rem;
        text-align: center;
    }
    .pages-sustainability--about #info {
        margin-top: 76px;
    }
    .pages-sustainability--about #objetivos {
        margin-top: 36px;
    }
    .pages-sustainability--about section h2 + p {
        margin: 0 0 20px;
    }
}

/* Nosso Produto */
.pages-sustainability--about #jornada {
    margin-top: 86px;
}
.pages-sustainability--about #jornada img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
}
.pages-sustainability--about #sustentavel {
    margin-top: 170px;
}
.pages-sustainability--about #sustentavel .grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 86px;
}
.pages-sustainability--about #sustentavel .grid-container .item {
    display: grid;
    grid-template-columns: 78px 1fr;
    gap: 30px;
}
.pages-sustainability--about #sustentavel .grid-container .item .title {
    display: block;
    margin: 0 0 16px;
    font-weight: 700;
    text-transform: uppercase;
}
.pages-sustainability--about #sustentavel .grid-container .item p {
    margin: 0;
    font-weight: 300;
    line-height: 1.5;
}
@media (max-width: 992px) {
    .pages-sustainability--about #sustentavel {
        margin-top: 60px;
    }
    .pages-sustainability--about #sustentavel .grid-container {
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .pages-sustainability--about section .subtitle {
        font-size: 1rem;
    }
    .pages-sustainability--about .grid-items {
        grid-template-columns: 1fr;
    }
}

/* Nosso Negócio */
.pages-sustainability--about #governanca {
    margin-top: 120px;
}
.pages-sustainability--about #governanca img {
    width: 100%;
}
.pages-sustainability--about #etica {
    margin-top: 100px;
}
.pages-sustainability--about #etica img {
    width: 100%;
}
.pages-sustainability--about #valor-investimento {
    margin-top: 135px;
}
.pages-sustainability--about #satisfacao {
    margin-top: 160px;
}
.pages-sustainability--about #satisfacao p {
    font-weight: 300;
    margin: 0 0 1.5rem;
}
.pages-sustainability--about #satisfacao p:nth-of-type(3) {
    margin: 40px 0;
    font-weight: 700;
    color: var(--color-sust);
}
.pages-sustainability--about #satisfacao ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.pages-sustainability--about #satisfacao ul li:before {
    width: 12px;
    height: 12px;
    content: "";
    display: inline-block;
    position: absolute;
    top: -4px;
    left: 0px;
    background: url("/data/sustainability/icon-aspas.svg") center center no-repeat;
}
.pages-sustainability--about #satisfacao ul li {
    position: relative;
    margin: 0;
    padding: 0 0 0 20px;
    color: var(--color-sust);
    margin-bottom: 1.5rem;
}
.pages-sustainability--about #investidores .box-investidores {
    margin-top: 60px;
    padding: 96px 20px 200px;
    background: url("/data/sustainability/relacao_bg.png") center center no-repeat;
    background-size: cover;
}
.pages-sustainability--about #investidores img {
    margin: 0 auto 24px;
}
.pages-sustainability--about #investidores h2 {
    width: 100%;
    max-width: 40%;
    margin: 0 auto 25px;
    color: var(--white);
    text-align: center;
}
.pages-sustainability--about #investidores p {
    width: 100%;
    max-width: 40%;
    margin: 0 auto 48px;
    font-size: 1.125rem;
    color: var(--white);
    line-height: 2rem;
    text-align: center;
}
.pages-sustainability--about #investidores .btn {
    width: 100%;
    max-width: 408px;
    width: auto;
    display: block;
    margin: 0 auto;
    color: var(--white);
    white-space: nowrap;
}
.pages-sustainability--about .accordion {
    border-radius: 0;
    background: none;
    border: none;
    border-top: 1px solid var(--color-lightest);
}
.pages-sustainability--about .accordion .card {
    border-radius: 0;
    background: none;
    border: none;
    border-bottom: 1px solid var(--color-lightest);
}
.pages-sustainability--about .accordion .card-header {
    background: none;
    border: none;
    padding: 0;
}
.pages-sustainability--about .accordion .card-header h3 {
    background: none;
    margin: 0;
    padding: 0;
}
.pages-sustainability--about .accordion .card-header h3 button {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-main);
    text-transform: uppercase;
    text-decoration: none;
}
.pages-sustainability--about .accordion .card-header h3 button:after {
    width: 10px;
    height: 10px;
    display: block;
    content: "";
    position: relative;
    right: 12px;
    border-right: 2px solid var(--color-light);
    border-bottom: 2px solid var(--color-light);
    transform: rotate(-45deg);
}
.pages-sustainability--about .accordion .card-body {
    padding: 20px 0 60px;
    font-weight: 300;
    line-height: 1.2;
}
.pages-sustainability--about .stats {
    margin-top: 86px;
}
.pages-sustainability--about .stats img {
    max-width: 100%;;
}
.pages-sustainability--about .stats .title {
    display: block;
    font-size: 0.8rem;
    font-weight: 300;
    color: var(--color-sust);
}
.pages-sustainability--about .stats .info {
    display: block;
    position: relative;
    margin: 0;
    padding: 0 0 0 20px;
}
.pages-sustainability--about .stats .info:before {
    width: 11px;
    height: 11px;
    display: block;
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    background: var(--color-light);
}
.pages-sustainability--about .stats .info > span {
    display: block;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1;
}
.pages-sustainability--about .stats .info > span:nth-of-type(1) {
    font-size: 2rem;
    font-weight: 400;
}
.pages-sustainability--about .content-element {
    margin-top: 72px;
}
.pages-sustainability--about .content-element p {
    font-weight: 300;
}
@media (max-width: 992px) {
    .pages-sustainability--about #valor-investimento {
        margin-top: 90px;
    }
    .pages-sustainability--about #valor-investimento .col-12:nth-of-type(2) {
        margin-top: 100px;
    }
    .pages-sustainability--about #satisfacao {
        margin-top: 120px;
    }
    .pages-sustainability--about #investidores {
        margin-top: 78px;
    }
    .pages-sustainability--about .stats {
        margin-top: 80px;
    }
    .pages-sustainability--about .stats .grid-items {
        grid-template-columns: repeat(2,1fr);
    }
    .pages-sustainability--about .stats .col-12 {
        margin-bottom: 70px;
    }
    .pages-sustainability--about .content-element {
        margin-top: 0px;
    }
    .pages-sustainability--about p {
        font-size: 0.875rem;
    }
}
.pages-sustainability--about .more-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 70px;
    gap: 34px;
}
.pages-sustainability--about .more-container .more-text {
    font-size: 0.875rem;
    font-weight: 700;
}
.pages-sustainability--about .more-container .title {
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
}
.pages-sustainability--about .more-container .btn-more {
    border: 1px solid var(--color-main);
}
/* Nossa Gente */
.pages-sustainability--about #people {
    margin-top: 80px;
}
.pages-sustainability--about #people img {
    width: 100%;
}
.pages-sustainability--about #pluralidade {
    margin-top: 80px;
}
.pages-sustainability--about #pluralidade .content {
    margin-bottom: 70px;
}
.pages-sustainability--about #pluralidade .grid-workers {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 90px;
}
.pages-sustainability--about #pluralidade .grid-workers > div {
    display: grid;
    grid-template-columns: 58px 1fr;
    grid-template-areas: "title title" "icon stats";
    column-gap: 16px;
    row-gap: 32px;
}
.pages-sustainability--about #pluralidade .grid-workers > div .graph-title {
    grid-area: title;
    margin: 0px;
}
.pages-sustainability--about #pluralidade .grid-workers > div img {
    grid-area: icon;
    width: 58px;
    height: 58px;
    object-position: top center;
    object-fit: contain;
}
.pages-sustainability--about #pluralidade .grid-workers > div span:nth-of-type(2) {
    grid-area: stats;
    align-self: end;
    font-size: 2.75rem;
    color: var(--color-sust);
    font-weight: 600;
    line-height: 1;
}
.pages-sustainability--about #pluralidade .grid-workers > div span:nth-of-type(2) small {
    font-size: 1.5rem;
    font-weight: 600;
}
.pages-sustainability--about #pluralidade .gender {
    margin-top: 78px;
}
.pages-sustainability--about #pluralidade .grid-graphs {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 100px;
}
.pages-sustainability--about #covid {
    margin-top: 110px;
}
.pages-sustainability--about #covid .grid-items {
    display: grid;
    grid-template-columns: repeat(3,1fr);   
}
.pages-sustainability--about #cuidado {
    margin-top: 70px;
}
.pages-sustainability--about #programas {
    margin-top: 180px;
}
.pages-sustainability--about #programas .icon-prev {
    margin: 0 auto 40px;
    display: block;
    text-align: center;
}
.pages-sustainability--about #programas .subtitle {
    margin: 0 auto 1.5rem;
    text-align: center;
}
.pages-sustainability--about #programas .programas-image {
    width: 100%;
    margin: 30px auto 94px;
}
.pages-sustainability--about #meritocracia {
    margin-top: 160px;
    overflow: hidden;
}
.pages-sustainability--about #meritocracia .subtitle {
    margin: 0px auto 2rem;
}
.pages-sustainability--about #meritocracia .content {
    margin-bottom: 60px;
}
.pages-sustainability--about #meritocracia .cols {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 30px;
}
.pages-sustainability--about #meritocracia .cols span {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
}
.pages-sustainability--about #meritocracia .cols ul {
    list-style: none;
    margin: 16px 0 0 16px;
    padding: 0;
}
.pages-sustainability--about #meritocracia .cols ul li {
    margin: 0 0 10px;
    font-weight: 300;
    position: relative;
}
.pages-sustainability--about #meritocracia .cols ul li:before {
    content: "\2022";
    color: var(--color-sust);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.pages-sustainability--about #meritocracia .cols + p {
    margin-top: 50px;
}
.pages-sustainability--about #meritocracia .graph {
    margin-top: 100px;
    margin-bottom: 60px;
}
.pages-sustainability--about #meritocracia .tools {
    margin-top: 60px;
    margin-bottom: 60px;
}
#page-sustainability .steps--page img
{
    margin-bottom: 60px;
    width: 100%;
    height: auto;
    max-width: 100%;
}
#page-sustainability .steps--page h2
{
    font-size: 24px;
    font-weight:400;
    margin-bottom: 20px;
}
@media (max-width: 992px) {    
    .pages-sustainability--about #people {
        margin-top: 40px;
    }
    .pages-sustainability--about #pluralidade .grid-workers {
        margin-top: 56px;
        display: block;
    }
    .pages-sustainability--about #pluralidade .grid-workers > div {
        margin-bottom: 30px;
    }
    .pages-sustainability--about #pluralidade .gender {
        margin-top: 20px;
    }
    .pages-sustainability--about #pluralidade .grid-graphs {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 30px;
    }
    .pages-sustainability--about #pluralidade .grid-graphs img {
        width: 100%;
    }
    .pages-sustainability--about #covid .grid-items {
        display: grid;
        grid-template-columns: 100%;   
    }
    .pages-sustainability--about #meritocracia .cols {
        grid-template-columns: 1fr;
    }
    .pages-sustainability--about #meritocracia .graph {
        margin-top: 40px;
        margin-bottom: 40px;;
    }
    .pages-sustainability--about #meritocracia .content
    {
        margin-bottom: 30px;
    }
    .pages-sustainability--about header .btn-close
    {
        position: absolute;
    }
    .pages-sustainability--about #programas .programas-image
    {
        width: 100%;
    }    
}

/* Fogo */
.pages-sustainability--about .graph-fire {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "titlePb titlePo" "gasPb gasPo" "elePb elePo";
    column-gap: 30px;
    row-gap: 12px;
}
.pages-sustainability--about .graph-fire .title.portobello {
    grid-area: titlePb;
}
.pages-sustainability--about .graph-fire .title.pointer {
    grid-area: titlePo;
}
.pages-sustainability--about .graph-fire .gas.portobello {
    grid-area: gasPb;
}
.pages-sustainability--about .graph-fire .gas.pointer {
    grid-area: gasPo;
}
.pages-sustainability--about .graph-fire .eletrica.portobello {
    grid-area: elePb;
}
.pages-sustainability--about .graph-fire .eletrica.pointer {
    grid-area: elePo;
}
.pages-sustainability--about .graph-fire .gas:before {
    background: var(--color-sust);
}
.pages-sustainability--about .graph-fire .eletrica:before {
    background: var(--color-main);
}

/* Fogo */
.pages-sustainability--about .graph-fire {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "titlePb titlePo" "gasPb gasPo" "elePb elePo";
    column-gap: 30px;
    row-gap: 12px;
}
.pages-sustainability--about .graph-fire .title.portobello {
    grid-area: titlePb;
}
.pages-sustainability--about .graph-fire .title.pointer {
    grid-area: titlePo;
}
.pages-sustainability--about .graph-fire .gas.portobello {
    grid-area: gasPb;
}
.pages-sustainability--about .graph-fire .gas.pointer {
    grid-area: gasPo;
}
.pages-sustainability--about .graph-fire .eletrica.portobello {
    grid-area: elePb;
}
.pages-sustainability--about .graph-fire .eletrica.pointer {
    grid-area: elePo;
}
.pages-sustainability--about .graph-fire .gas:before {
    background: var(--color-sust);
}
.pages-sustainability--about .graph-fire .eletrica:before {
    background: var(--color-main);
}

/* Fogo */
.pages-sustainability--about .graph-residuo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.pages-sustainability--about .graph-residuo .title {
    width: 100%;
    margin: 0 0 1rem;
}
.pages-sustainability--about .graph-residuo .interno:before {
    background: var(--color-sust);
}
.pages-sustainability--about .graph-residuo .aterro:before {
    background: var(--color-main);
}

@media (max-width: 992px) {
    #page-sustainability #contato h2,
    #page-sustainability #downloads h2,
    #page-sustainability #cuidado h2, 
    #page-sustainability #alternativa h2,
    #page-sustainability #numeros h2,
    #page-sustainability #caminho .content h2 {
      font-size: 1.125rem;
    }
    #page-sustainability #caminho .content h2 {
      margin-top: 50px;
    }
    body#Index_sustainability #findstore
    {
        display: none;
    }
    .pages-sustainability--about #investidores .btn-more{
        height: auto;
    }
}
body#Index_sustainability .pswp__bg,
body#Index_sustainabilityproduct .pswp__bg,
body#Index_sustainabilitypeople .pswp__bg,
body#Index_sustainabilityfire .pswp__bg,
body#Index_sustainabilitywater .pswp__bg,
body#Index_sustainabilityzero .pswp__bg,
body#Index_sustainabilityearth .pswp__bg,
body#Index_sustainabilitybusiness .pswp__bg,
#page-sustainability .pswp__bg
{
    background-color: white;
}
body#Index_sustainability .pswp__img--placeholder--blank,
body#Index_sustainabilityproduct .pswp__img--placeholder--blank,
body#Index_sustainabilitypeople .pswp__img--placeholder--blank,
body#Index_sustainabilityfire .pswp__img--placeholder--blank,
body#Index_sustainabilitywater .pswp__img--placeholder--blank,
body#Index_sustainabilityzero .pswp__img--placeholder--blank,
body#Index_sustainabilityearth .pswp__img--placeholder--blank,
body#Index_sustainabilitybusiness .pswp__img--placeholder--blank,
#page-sustainability .pswp__img--placeholder--blank
{
    background-color: white;
}

@media (max-width:992px) {
    #conceito .steps--page:nth-of-type(2) {
      margin-bottom: 60px;
    }
}

#page-sustainability--discard .centered {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 40px;
    margin-bottom: 40px;
}
#page-sustainability--discard header h1 {
    margin: 0 0 66px;
}
#page-sustainability--discard header h1 ~ span {
    margin: 0 0 45px;
}
#page-sustainability--discard .link img {
    display: block;
    margin: 0 auto 36px;
}
#page-sustainability--discard .link .btn-more {
    max-width: 120px;
    display: block;
    margin: 0 auto 36px;
    border: 1px solid var(--color-main);
}
#page-sustainability--discard .list-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    gap: 40px;
    margin-bottom: 80px;
    
}
#page-sustainability--discard .list-actions li img {
        width: 160px;
        height: auto;
}
#page-sustainability--discard #products .content .highlight{
        display: block;
        padding-top: 10px;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.2;
}
#page-sustainability--discard #descarte h2 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.2;
        color: var(--color-black);
        text-transform: none;
        margin-top: 20px;
}
#page-sustainability--discard #descarte h2 + p {
    margin-bottom: 2rem;
}
#page-sustainability--discard #descarte .table-container {
        display: block;
        margin: 42px 0 68px;
}
#page-sustainability--discard #descarte .table-container table {
    width: 100%;
    border: 1px solid #666;
}
#page-sustainability--discard #descarte .table-container table th,
#page-sustainability--discard #descarte .table-container table td {
        border: 1px solid #666;
        padding: 6px;
}
#page-sustainability--discard #descarte .table-container table td.centered {
    text-align: center;
}
#page-sustainability--discard #descarte .table-container table th {
    background: #ccc;
    color: #707070;
    font-weight: 500;
}
#page-sustainability--discard #descarte .table-container .footnote {
    display: block;
    color: #666;
    padding: 10px 0;
}
#page-sustainability--discard #moreinfo .grid-items li {
        width: 50%;
        max-width: 50%;
        min-height: 68px;
        padding-left: 28px;
        position: relative;
}
@media (max-width: 600px) {
    #page-sustainability--discard #moreinfo .grid-items {
        display: block;
    }
    #page-sustainability--discard #moreinfo .grid-items li {
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px;
    }
}