#page-porcelanateria,
#page-porcelanateria * {
    box-sizing: border-box;
    --titleblock-width: 332px;
    --titleblock-height: 360px;
    
}
#page-porcelanateria
{
    margin-bottom: 60px;
}
#page-porcelanateria h2 {
    display: block;
    margin: 0 0 48px;
    font-size: 1.72rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
    color: var(--color-darker);
}
#page-porcelanateria h2.centered {
    text-align: center;
}
#page-porcelanateria h3 {
    margin: 50px 0 10px;
}
/* Cabeçalho */
#page-porcelanateria header {
    width: 100%;
    height: calc(100vh - var(--mainmenu-height));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    background: url("https://imagens.portobello.com.br/unsafe/0x0/https://www.portobello.com.br/data/lp-2021-porcelanateria/porcelanateria_banner.jpg") center center no-repeat;
    background-size: cover;
}
#page-porcelanateria header > div {
    width: var(--titleblock-width);
    height: var(--titleblock-height);
    padding: 0 64px 56px;
    background: var(--color-white);
}
#page-porcelanateria header > div h1 {
    display: block;
    margin: -42px 0 52px;
    /*opacity: 0;*/
    opacity: 1;
}
#page-porcelanateria header > div p {
    display: block;
    margin: 0;
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.71rem;
}
#page-porcelanateria header a {
    width: 86px;
    display: inline-block;
    position: absolute;
    left: calc(50% - 43px);
    bottom: 66px;
    text-align: center;
}
#page-porcelanateria header a span.label {
    display: block;
    font-size: 1rem;
    text-align: center;
    font-weight: 300;
    color: var(--color-white);
    text-transform: uppercase;
}
#page-porcelanateria header a img {
    display: block;
    margin: 0 auto -40px;
    text-align: center;
}
#page-porcelanateria header a span.block {
    width: 65px;
    height: 42px;
    margin: 20px auto 0;
    display: block;
    background: var(--color-white);
}
@media (max-width: 992px) {
    #page-porcelanateria header {
        height: auto;
        display: block;
        padding: 40px 28px 0;
        background: none;
    }
    #page-porcelanateria header > div {
        width: 100%;
        height: auto;
        padding: 0;
        background: var(--color-white);
    }
    #page-porcelanateria header > div h1 {
        margin: 0px 0 30px;
        opacity: 1;
    }
    #page-porcelanateria header > div .subtitle {
        opacity: 1;
    }
    #page-porcelanateria header a {
        display: none;
    }
}
/* Menu Fixo */
#page-porcelanateria .navaux {
    /* This is needed as fix for minification when using division inside calc  */
    --division-value: calc(var(--titleblock-height) / 2); 
    display: block;
    position: fixed;
    top: calc(
            ((100vh - var(--mainmenu-height)) / 2) - var(--division-value)
        );
    left: 70px;
    /*z-index: 1000;*/
    z-index: 0;
}
#page-porcelanateria .navaux .pretitle {
    margin: 0 0 36px;
}
#page-porcelanateria .navaux .pretitle span {
    display: block;
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--color-darker-alt);
}
#page-porcelanateria .navaux .link {
    margin: 36px 0 0;
    display: block;
}
#page-porcelanateria--products .btn-light,
#page-porcelanateria .navaux .link .btn-light {
    padding: 0.375rem 2rem;
    border: none;
    background: var(--color-lightest);
    text-transform: uppercase;
}
#page-porcelanateria--products .btn-light
{
    padding: 0.375rem 7rem;
}
#page-porcelanateria .navaux.scrolled .pretitle {
    opacity: 1;
}
#page-porcelanateria .navaux.scrolled ul {
    display: grid;
}
@media (max-width: 992px) {
    #page-porcelanateria .navaux {
        display: none;
    }
}

/* Conceito */
#page-porcelanateria--concept {
    padding-top: 96px;
}
#page-porcelanateria--concept .subtitle {
    display: block;
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
}
#page-porcelanateria--concept .subtitle-202106 {
    display: block;
    margin: 0 0 48px;
    font-size: 1.72rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
}
#page-porcelanateria--concept .image-full {
    max-width: 100%;
}
#page-porcelanateria--concept .slogan {
    margin: 0 0 66px;
    font-size: 1.75rem;
}
#page-porcelanateria--concept .content p {
    margin: 0 0 1.125rem;
    font-size: 1.125rem;
    font-weight: 300;
    color: var(--color-darker-alt);
    line-height: 1.5em;
}
#page-porcelanateria--concept .content b {
    font-weight: 700;
}

/* Escala */
#page-porcelanateria--scale {
    padding-top: 148px;
}
#page-porcelanateria--scale h2 {
    display: block;
    margin: 0 0 30px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
}
#page-porcelanateria--scale .content {
    max-width: 525px;
    margin: 0 0 134px;
}
#page-porcelanateria--scale .content p {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    color: var(--color-darker-alt);
    line-height: 1.5em;
}
#page-porcelanateria--scale .content b {
    font-weight: 700;
}
#page-porcelanateria--scale .scale-container {
    width: 100%;
    margin-right: -60px;
    padding-bottom: 50px;
    position: relative;
    overflow-x: auto;
}
#page-porcelanateria--scale .scale > img {
  opacity: 0.8;
}
#page-porcelanateria--scale .scale {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    bottom: 0px;
    left: -50px;
}
#page-porcelanateria--scale .scale span {
    display: block;
    max-width: 160px;
    margin: 0 20px 60px 0; 
    font-size: 1.25rem;
    text-align: right;

}
#page-porcelanateria--scale .scale-holder {
    position: relative;
}
#page-porcelanateria--scale .scale-container .scale-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
}
#page-porcelanateria--scale .scale-container .scale-wrapper .scale-item span {
    display: block;
    font-size: 0.875rem;
    margin: 0 0 24px;
}
#page-porcelanateria--scale .scale-container .scale-wrapper .scale-item img {
    display: block;
}
#page-porcelanateria--scale .more {
    text-align: right;
}
#page-porcelanateria--scale .more img {
    margin: 0 20px 0 0;
}
#page-porcelanateria--scale .more  span {
    font-size: 1rem;
    color: var(--color-light);
    text-decoration: none;
    text-transform: uppercase;
}
#page-porcelanateria--scale .specs .header {
    margin: 70px 0 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: "image content";
    column-gap: 20px;
}
#page-porcelanateria--scale .specs .header .specs-title {
    grid-area: content;
    align-self: center;
}
#page-porcelanateria--scale .specs .header .content {
    grid-area: content;
    align-self: start;
}
#page-porcelanateria--scale .specs .specs-title {
    font-size: 1.71rem;
    text-transform: uppercase;
    font-weight: 300;
}
#page-porcelanateria--scale .specs .specs-subtitle {
    displaY: block;
    margin: 48px 0 20px;
    font-size: 1.43rem;
    text-transform: uppercase;
    font-weight: 300;
}
#page-porcelanateria--scale .specs p {
    font-size: 1rem;
    line-height: 1.71rem;
    font-weight: 300;
}
#page-porcelanateria--scale .specs .content .specs-title {
    display: block;
    margin: 0 0 20px;
}
#page-porcelanateria--scale .specs.espessura .content .specs-title {
    margin: 16px 0 20px;
}
@media (min-width:1600px) {
    #page-porcelanateria--scale {
        /*background: url("/data/lastras/lastras-bg-01.png") calc(100vw - 258px - 76px - (100vw - var(--max-container))/2) 180px no-repeat;*/
    }
}
@media (max-width:992px) {
    #page-porcelanateria--scale {
        background: none;
    }
    #page-porcelanateria--scale .scale-container {
        width: calc(100% + 28px);
        margin-right: -28px;
    }
    #page-porcelanateria--scale .content {
        max-width: 525px;
        margin: 0 0 28px;
    }
    #page-porcelanateria--scale .scale {
        bottom: 20px;
        right: -20px;
        left: auto;
    }
    #page-porcelanateria--scale .more {
        display: none;
    }
}
/* VIDEO */
#page-porcelanateria--video {
    padding-top: 200px;
}
#page-porcelanateria--video .video {
    width: 100%;
    margin: 20px 0 0;
    height: 0;
    padding: 0 0 56.25%;
    position: relative;
}
#page-porcelanateria--video .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* AMBIENT TAGS */
#page-porcelanateria--tags {
    margin: 200px 0 0;
}
#page-porcelanateria--tags h2 {
    margin: 0 0 60px;
}
/* DESIGN */
#page-porcelanateria--design {
    margin: 200px 0;
}
#page-porcelanateria--design .pieces {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "title1 title2" "image1 image2";
    column-gap: 20px;
    row-gap: 10px;;
}
#page-porcelanateria--design .pieces img {
    width: 100%;
}
#page-porcelanateria--design .pieces span {
    font-size: 0.857rem;
    font-weight: 300;
}
#page-porcelanateria--design .pieces img:nth-of-type(1) {
    grid-area: image1;
}
#page-porcelanateria--design .pieces img:nth-of-type(2) {
    grid-area: image2;
}
#page-porcelanateria--design .pieces span:nth-of-type(1) {
    grid-area: title1;
}
#page-porcelanateria--design .pieces span:nth-of-type(2) {
    grid-area: title2;
}
#page-porcelanateria--design .content {
    margin: 0 0 40px;
}
/* LINHAS */
#page-porcelanateria--lines {
    padding: 60px 0 200px;
}
.pb-list--collections .list #veios.line .image img {
    object-fit: contain;
    object-position: top center;
}
@media (max-width: 440px)
{
    #page-porcelanateria--design {
        margin: 60px 0;
    }
    #page-porcelanateria--products .btn-light {
        padding: 0.375rem 2rem;
    }
}
