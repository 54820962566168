
label {
    display: inline-grid;
    grid-template-columns: 100%;
    grid-template-areas: "field";
    position: relative;
    isolation: isolate;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) {
    min-height: var(--input-height);
}
label:has(input[disabled]):before,
label:has(input[disabled]) input,
label:has(input[disabled]) span:not([class]):before {
    opacity: 0.5 !important;
}
label:has(input[disabled]):hover {
    cursor: not-allowed !important;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) > span:not([class]) {
    grid-area: field;
    position: absolute;
    top: 50%;
    left: var(--input-padding--left,0);
    z-index: 1;
    transform: translateY(-50%);
    font-size: var(--input-font--size);
    color: var(--input-font--color);
    font-weight: var(--input-font--weight);
    white-space: nowrap;
    transition: all 0.3s ease-out;
}
label:has(input:placeholder-shown) > span:not([class]) {
    opacity: 0;
}
label:has(input:not([type="checkbox"]):not([type="radio"]):not(:placeholder-shown)) {
    --input-border--color: var(--clr-gray-700);
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input {
    grid-area: field;
    min-height: var(--input-height);
    align-self: center;
    align-items: center;
    min-height: var(--input-height);
    padding: calc(var(--input-padding--top)/2) var(--input-padding--right,0) calc(var(--input-padding--top)/2) var(--input-padding--left,0);
    position: relative;
    z-index: 2;
    font-size: var(--input-font--size);
    font-weight: var(--input-font--weight);
    color: var(--input-font--color);
    background: none;
    border: none;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])):before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    grid-column: 1 / -1;
    grid-row: 1 / 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--input-background);
    border: 1px solid var(--input-border--color);
    border-radius: var(--input-radius);
    transition: background 0.3s ease-out, border 0.3s ease-out;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])):has(input:not(:placeholder-shown)) > span:not([class]) {
    font-size: var(--input-font--size-focus);
    font-weight: var(--input-font--weight-focus);
    color: var(--input-font--color-focus,--input-font--color);
    top: var(--input-label--top);
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input:not(:placeholder-shown) {
    padding: var(--input-padding--top) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
/* Prefixos e Sufixos (conteúdo Pre e Pos) */
label:has(> .pre) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "pre field";
}
label:has(> .pos) {
    grid-template-columns: 1fr auto;
    grid-template-areas: "field pos";
}
label:has(> .pre, > .pos) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "pre field pos";
}
label:has(> .pre,[data-hint]) {
    grid-template-areas: "pre field" "hint hint";
}
label:has(> .pos,[data-hint]) {
    grid-template-areas: "field pos" "hint hint";
}
label:has(> .pre, > .pos,[data-hint]) {
    grid-template-areas: "pre field pos" "hint hint hint";
}
label:has(> .pre,[data-error]) {
    grid-template-areas: "pre field" "error error";
}
label:has(> .pos,[data-error]) {
    grid-template-areas: "field pos" "error error";
}
label:has(> .pre, > .pos,[data-error]) {
    grid-template-areas: "pre field pos" "error error error";
}
label:has(> input:not([type="radio"]):not([type="checkbox"])):has(> .pre:not(.icon)) > input {
    padding: var(--input-padding--top,0) var(--input-padding--right,0) 0 calc(var(--input-padding--left,0)/8);
}
label:has(> input:not([type="radio"]):not([type="checkbox"])):has(> .pos:not(.icon)) > input {
    padding: var(--input-padding--top,0) calc(var(--input-padding--right,0)/8) 0 var(--input-padding--left,0);
    text-align: right;
}
label > .pre {
    grid-area: pre;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: var(--input-height);
    align-self: center;
    align-items: center;
    text-align: right;
    font-size: var(--input-prepos--size);
    font-weight: var(--input-prepos--weight);
    color: var(--input-prepos--color);
    padding: var(--input-padding--top,0) 0 0 var(--input-padding--left,0);
}
label > .pos {
    grid-area: pos;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: var(--input-height);
    align-self: center;
    align-items: center;
    text-align: left;
    font-size: var(--input-prepos--size);
    font-weight: var(--input-prepos--weight);
    color: var(--input-prepos--color);
    padding: var(--input-padding--top,0) var(--input-padding--right,0) 0 0;
}
label > .pre.icon,
label > .pos.icon {
    padding-top: 0;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])):has(> .pre:not(.icon)) > span:not([class]),
label:has(> input:not([type="radio"]):not([type="checkbox"])):has(> .pos:not(.icon)) > span:not([class]) {
    grid-area: not-set;
    font-size: var(--input-font--size-focus);
    font-weight: var(--input-font--weight-focus);
    color: var(--input-font--color-focus);
    top: var(--input-label--top);
    left: var(--input-padding--left,0);
    opacity: 1;
}
label:has(input[type="radio"]:checked),
label:has(input[type="radio"]:checked) *,
label:has(input[type="checkbox"]:checked),
label:has(input[type="checkbox"]:checked) * {
    --mark-font--weight: 700;
    --input-mark--border-color: var(--clr-link-400);
}
/* Dicas (conteúdo em data-hint) */
label[data-hint] {
    grid-template-areas: "field" "hint";
}
label[data-hint]:after {
    grid-area: hint;
    display: block;
    content: "*" attr(data-hint);
    font-size: var(--hint-font--size);
    color: var(--hint-font--color);
    font-weight: var(--hint-font--weight);
    font-style: var(--hint-font--style);
    padding: 0 0 0 calc(var(--input-padding--left,0)/4);
}
/* Erro em feedback (conteúdo em data-error) */
label[data-error] {
    grid-template-areas: "field" "error";
}
label[data-error]:after {
    grid-area: error;
    display: block;
    content: "*" attr(data-error);
    font-size: var(--error-font--size);
    color: var(--error-font--color);
    font-weight: var(--error-font--weight);
    font-style: var(--error-font--style);
    padding: calc(var(--input-padding--left,0)/4) 0 0 calc(var(--input-padding--left,0)/4);
}
label:has(.is-invalid),
label:has(.field-error) {
    --input-border--color: var(--clr-error-400);
}
/* Input de arquivo e de image (file e image)*/
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="file"],
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="image"] {
    min-width: var(--input-width--min);
    padding: calc(var(--input-padding--top) * 1.2) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
/* Input de cor (color) */
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="color"] {
    width: 100%;
    min-width: var(--input-width--min);
    height: calc(100% - (var(--input-padding--top) * 1.2));
    padding: calc(var(--input-padding--top) * 1.2) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
/* Input de Intervalo (range) */
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="range"] {
    -webkit-appearance :none;
    -moz-appearance :none;
    appearance :none;
    background: none;
    cursor: pointer;
    padding: calc(var(--input-padding--top) * 1.2) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="range"]::-webkit-slider-thumb {
    height: var(--input-range--thumb-size);
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background: var(--input-range--thumb-color);
    transform: translateY(calc(var(--input-range--track-size)/2 - var(--input-range--thumb-size)/2));
    appearance: none;
}
label:has(> input:not([type="radio"]):not([type="checkbox"])) > input[type="range"]::-webkit-slider-runnable-track {
    height: var(--input-range--track-size);
    background: var(--input-range--track-color);
    border-radius: calc(var(--input-range--track-size)/2);
}
/* Input Radio e Checkbox */
label > input[type="checkbox"],
label > input[type="radio"] {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: none;
    width: 1rem;
    height: 1rem;
}
label:has(> input[type="checkbox"],> input[type="radio"]) {
    grid-template-columns: var(--input-mark) 1fr;
    grid-template-areas: "mark field";
    column-gap: var(--input-mark--spacer);
}
label[data-hint]:has(> input[type="checkbox"],> input[type="radio"]) {
    grid-template-areas: "mark field" "mark hint";
}
label[data-error]:has(> input[type="checkbox"],> input[type="radio"]) {
    grid-template-areas: "mark field" "mark error";
}
label:has(> input[type="checkbox"],> input[type="radio"]):has(> .content) {
    grid-template-areas: "mark field" "mark content";
}
label[data-hint]:has(> input[type="checkbox"],> input[type="radio"]):has(> .content) {
    grid-template-areas: "mark field" "mark content" "mark hint";
}
label[data-error]:has(> input[type="checkbox"],> input[type="radio"]):has(> .content) {
    grid-template-areas: "mark field" "mark content" "mark error";
}
label:has(> input[type="checkbox"],> input[type="radio"]):hover {
    cursor: pointer;
}
label:has(> input[type="checkbox"],> input[type="radio"]) > span:not([class]) {
    grid-area: field;
    position: relative;
    top: 0 !important;
    font-size: var(--mark-font--size) !important;
    color: var(--mark-font--color) !important;
    font-weight: var(--mark-font--weight) !important;
    font-style: var(--mark-font--style) !important;
}
label:has(> input[type="checkbox"],> input[type="radio"]):before {
    grid-area: mark;
    align-self: start;
    width: var(--input-mark);
    height: var(--input-mark);
    content: "";
    display: block;
    position: relative;
    background: var(--input-mark--background);
    border-radius: var(--input-mark--radius);
    border: var(--input-mark--border-size) solid var(--input-mark--border-color);
}
label:has(> input[type="checkbox"],> input[type="radio"]) span:not([class]):before {
    align-self: start;
    width: calc(var(--input-mark) - ((var(--input-mark--border-size) + var(--input-mark--border-spacer)) * 2));
    aspect-ratio: 1;
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--input-mark--border-size) + var(--input-mark--border-spacer));
    left: calc(0px - var(--input-mark--spacer) - (var(--input-mark) - (var(--input-mark--border-size) + var(--input-mark--border-spacer))));
    background: var(--input-mark--check);
    border-radius: var(--input-mark--radius);
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
label:has(> input[type="radio"]):before,
label:has(> input[type="radio"]) span:not([class]):before  {
    border-radius: 50%;
}
label:has(> input[type="checkbox"],> input[type="radio"]) .content {
    grid-area: content;
    font-size: var(--content-font--size);
    color: var(--content-font--color);
    font-weight: var(--content-font--weight);
    font-style: var(--content-font--style);
}
label:has(> input[type="checkbox"],> input[type="radio"]):has(> input:checked) span:not([class]):before  {
    opacity: 1;
    transform: scale(1);
}
label:has(> input[type="checkbox"],> input[type="radio"]):has(> input.checked) span:not([class]):before  {
    opacity: 1;
    transform: scale(1);
}
/* Área de texto */
label:has(> textarea) > span:not([class]) {
    grid-area: field;
    position: absolute;
    top: var(--input-padding--top);
    left: var(--input-padding--left,0);
    z-index: 1;
    font-size: var(--input-font--size);
    color: var(--input-font--color);
    font-weight: var(--input-font--weight);
    white-space: nowrap;
}
label:has(textarea:placeholder-shown) > span:not([class]) {
    opacity: 0;
}
label > textarea {
    grid-area: field;
    min-height: var(--input-height);
    align-self: center;
    align-items: center;
    min-height: var(--input-height);
    margin: var(--input-padding--top) var(--input-padding--right,0) var(--input-padding--top) var(--input-padding--left,0);
    position: relative;
    z-index: 2;
    font-size: var(--input-font--size);
    font-weight: var(--input-font--weight);
    color: var(--input-font--color);
    resize: both;
    background: none;
    border: none;
}
label:has(> textarea):before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    grid-column: 1 / -1;
    grid-row: 1 / 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--input-background);
    border: 1px solid var(--input-border--color);
    border-radius: var(--input-radius);
}
label:has(textarea:not(:placeholder-shown)) > span:not([class]) {
    font-size: var(--input-font--size-focus);
    font-weight: var(--input-font--weight-focus);
    color: var(--input-font--color-focus,--input-font--color);
    top: var(--input-label--top);
}
label > textarea:not(:placeholder-shown) {
    margin: calc(var(--input-padding--top) * 2) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
/* Seletor (select) */
label:has(> select) {
    display: inline-grid;
    grid-template: "field";
}
label:has(> select) > span:not([class]) {
    grid-area: not-set;
    position: absolute;
    font-size: var(--input-font--size-focus);
    font-weight: var(--input-font--weight-focus);
    color: var(--input-font--color-focus);
    top: var(--input-label--top);
    transform: translateY(-50%);
    left: var(--input-padding--left,0);
    opacity: 1;
    transition: all 0.3s ease-out;
}
label:has(> select):before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    grid-column: 1 / -1;
    grid-row: 1 / 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url("/template/images/icons/icon_chevron--select-v23.svg") calc(100% - 1rem) calc(50%) no-repeat, var(--input-background);
    border: 1px solid var(--input-border--color);
    border-radius: var(--input-radius);
}
label > select {
    grid-area: field;
    min-height: var(--input-height);
    align-self: center;
    align-items: center;
    min-height: var(--input-height);
    padding: var(--input-padding--top) calc(var(--input-padding--right, 0) + 1.25rem) 0 var(--input-padding--left, 0);
    position: relative;
    z-index: 2;
    font-size: var(--input-font--size);
    font-weight: var(--input-font--weight);
    color: var(--input-font--color);
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    transition: all 0.3s ease-out;
}
label:has(option[value=""]:checked) > select {
    --input-padding--left: 20px;
    margin: 0 0 0 calc(0 - var(--input-padding--left));
    padding: calc(var(--input-padding--top) / 2) var(--input-padding--right, 0) calc(var(--input-padding--top) / 2) var(--input-padding--left, 0);
}
label:has(option[value=""]:checked) > span:not([class]) {
    grid-area: field;
    position: absolute;
    top: 50%;
    left: var(--input-padding--left, 0);
    z-index: 1;
    transform: translateY(-50%);
    font-size: var(--input-font--size);
    color: var(--input-font--color);
    font-weight: var(--input-font--weight);
    white-space: nowrap;
    opacity: 0;
}
label .pb23-btn.input-disabled {
    display: none;
}
label:has(select[disabled]) .pb23-btn.input-disabled {
    grid-area:field;
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: flex-start;
    top: 0;
    left: 0;
    padding: calc(var(--input-padding--top) / 2) var(--input-padding--right, 0) calc(var(--input-padding--top) / 2) var(--input-padding--left, 0);
    z-index: 5;
}
label:has(.pb23-btn.input-disabled) select[disabled] {
    padding-left: calc(20px + var(--input-padding--left,0));
}
/* Exibir Placeholder */
label.show-placeholder:has(> input:not([type="radio"]):not([type="checkbox"])) > span:not([class]) {
    grid-area: not-set;
    position: absolute;
    font-size: var(--input-font--size-focus);
    font-weight: var(--input-font--weight-focus);
    color: var(--input-font--color-focus);
    top: var(--input-label--top);
    transform: translateY(-50%);
    left: var(--input-padding--left,0);
    opacity: 1;
}
label.show-placeholder:has(> input:not([type="radio"]):not([type="checkbox"])) > input {
    padding: var(--input-padding--top) var(--input-padding--right,0) 0 var(--input-padding--left,0);
}
/* Fake Input Checkbox  / Radio */
.pb23-input--fake.radio,
.pb23-input--fake.radio * {
    --input-mark--radius: 50%;
}
.pb23-input--fake {
    min-height: auto;
    display: inline-grid;
    grid-template-columns: var(--input-mark) 1fr;
    grid-template-areas: "mark field";
    column-gap: var(--input-mark--spacer);
    position: relative;
    isolation: isolate;
    padding: 0;
    border: none;
    background: none;
    text-align: left;
}
.pb23-input--fake:before {
    grid-area: mark;
    align-self: start;
    width: var(--input-mark);
    height: var(--input-mark);
    content: "";
    display: block;
    position: relative;
    background: var(--input-mark--background);
    border-radius: var(--input-mark--radius);
    border: var(--input-mark--border-size) solid var(--input-mark--border-color);
}
.pb23-input--fake > span:not([class]) {
    grid-area: field;
    position: relative;
    top: 0 !important;
    font-size: var(--mark-font--size) !important;
    color: var(--mark-font--color) !important;
    font-weight: var(--mark-font--weight) !important;
    font-style: var(--mark-font--style) !important;
}
.pb23-input--fake span:not([class]):before {
    align-self: start;
    width: calc(var(--input-mark) - ((var(--input-mark--border-size) + var(--input-mark--border-spacer))* 2));
    aspect-ratio: 1;
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--input-mark--border-size) + var(--input-mark--border-spacer));
    left: calc(0px - var(--input-mark--spacer) - (var(--input-mark) - (var(--input-mark--border-size) + var(--input-mark--border-spacer))));
    background: var(--input-mark--check);
    border-radius: var(--input-mark--radius);
    transform: scale(0);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.pb23-input--fake.checked span:not([class]):before {
    opacity: 1;
    transform: scale(1);
}