.pb23-modal {
    box-sizing: border-box;
    width: var(--modal--width);
    max-width: var(--modal--max-width);
    border: none;
    padding: var(--modal--padding);
    border-radius: var(--modal--radius);
    background: var(--modal--background,#fff) !important;
}
.pb23-modal::backdrop {
    background-color: var(--matte);
}
@media (max-width: 769px) {
    .pb23-modal {
        max-width: 100dvh;
    }
}