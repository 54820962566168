.pb23-container {
    width: min(100%,var(--container));
    padding: 0 var(--container-margins,20px);
    margin-inline: auto;
}
.pb23-container.full-spaced {
    --container: 100%;
    margin-left: var(--container-margins,20px);
    margin-right: var(--container-margins,20px);
}
@media (max-width: 769px) {
    body, html{
        overflow-x: hidden;
    }
}