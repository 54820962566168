#page-international,
#page-international * {
    box-sizing: border-box;
}
#page-international .pb-content--concept p {
    width: 100%;
    max-width: 100%;
    font-size: 1.72rem;
    font-weight: 300;
    line-height: 1.2;
    margin: 0 0 2.5rem;
    text-align: center;
}
#page-international .pb-content--concept p.small {
    font-size: 1rem;
    line-height: 1.5;
    color: var(--color-dark-alt);
}
#page-international .pb-content--concept p.small b {
    font-weight: 700;
}
#page-international .pb-content--concept img {
    width: 100%;
}