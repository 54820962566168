/* CARD DE PROJETO ARCHTRENDS */
.pb23-card--archtrends-project {
    width: var(--card--size);
    aspect-ratio: 310 / 390;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 84% 1fr;
    grid-template-areas: "image" "content";
    border-radius: var(--box-radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    background: var(--clr-white);
}
.pb23-card--archtrends-project .image {
    grid-area: image;
    width: 100%;
    height: 100%;
    position: relative;
    isolation: isolate;
}
.pb23-card--archtrends-project .image > a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}
.pb23-card--archtrends-project .image > a:before {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--card--fx);
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.pb23-card--archtrends-project .image > a > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}
.pb23-card--archtrends-project .image .title {
    width: calc(100% - 30px);
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: var(--clr-white);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    z-index: 5;
    bottom: 18px;
    left: 15px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.pb23-card--archtrends-project .image .shortcut {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(34,34,34,0.5);
    position: absolute;
    z-index: 5;
    top: 18px;
    right: 15px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.pb23-card--archtrends-project:hover .image:before,
.pb23-card--archtrends-project:hover .image .title,
.pb23-card--archtrends-project:hover .image .shortcut {
    opacity: 1;
}
.pb23-card--archtrends-project .content {
    grid-area: content;
    width: 100%;
    height: 100%;
}
.pb23-card--archtrends-project .content a {
    display: grid;
    padding: 13px 13px 15px;
    grid-template-columns: 35px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "avatar name" "avatar locale";
    column-gap: 5px;
    text-decoration: none;
}
.pb23-card--archtrends-project .content .avatar {
    grid-area: avatar;
    width: 35px;
    height: 35px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
.pb23-card--archtrends-project .content .avatar img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}
.pb23-card--archtrends-project .content .name {
    grid-area: name;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pb23-card--archtrends-project .content .locale {
    grid-area: locale;
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--clr-gray-290);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 1180px) {
    .pb23-card--archtrends-project .image:before,
    .pb23-card--archtrends-project .image .title,
    .pb23-card--archtrends-project .image .shortcut {
        opacity: 1 !important;
    }
}
/* CARD DE POST ARCHTRENDS */
.pb23-card--archtrends-post {
    width: var(--card--size);
    aspect-ratio: 310 / 390;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50.5% 1fr;
    grid-template-areas: "image" "content";
    border-radius: var(--box-radius);
    overflow: hidden;
    box-shadow: var(--shadow);
    background: var(--clr-white);
}
.pb23-card--archtrends-post .image {
    grid-area: image;
    width: 100%;
    height: 100%;
    position: relative;
    isolation: isolate;
}
.pb23-card--archtrends-post .image > a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}
.pb23-card--archtrends-post .image > a > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}
.pb23-card--archtrends-post .image .shortcut {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(34,34,34,0.5);
    position: absolute;
    z-index: 5;
    top: 24px;
    right: 24px;
    opacity: 0;
    transition: opacity 0.3s ease-out;
}
.pb23-card--archtrends-post:hover .image .shortcut {
    opacity: 1;
}
.pb23-card--archtrends-post .content {
    grid-area: content;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 44px;
    grid-template-areas: "article" "author";
    padding: 30px 20px 15px;
}
.pb23-card--archtrends-post .content .article {
    grid-area: article;
    display: block;
    text-decoration: none;
}
.pb23-card--archtrends-post .content .article .title {
    display: -webkit-box;
    font-size: 1.3125rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
    margin: 0 0 16px;
}
.pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .article .title {
    -webkit-line-clamp: 4;
}
.pb23-card--archtrends-post .content .article .post {
    display: none;
    color: var(--clr-gray-700);
}
.pb23-card--archtrends-post .content .author {
    border-top: 1px solid var(--clr-gray-250);
    display: grid;
    padding: 16px 0px 0px;
    grid-template-columns: 32px 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "avatar name" "avatar locale";
    column-gap: 16px;
    text-decoration: none;
}
.pb23-card--archtrends-post .content .avatar {
    grid-area: avatar;
    width: 32px;
    height: 32px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}
.pb23-card--archtrends-post .content .avatar img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}
.pb23-card--archtrends-post .content .name {
    grid-area: name;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pb23-card--archtrends-post .content .occupation {
    grid-area: locale;
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--clr-gray-700);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (min-width: 1180px) {   
    .pb23-card--archtrends-post {
        aspect-ratio: 261 / 329;
    } 
    .pb23-card--archtrends-post .image .shortcut {
        top: 15px;
        right: 15px;
        opacity: 1 !important;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post  {
        grid-template-columns: 56% 1fr;
        grid-template-rows: auto;
        grid-template-areas: "image content";
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .image {
        aspect-ratio: 367 / 318;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content {
        padding: 15px 17px;
        grid-template-rows: 1fr calc(15px + 48px);
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .article .title {
        -webkit-line-clamp: 3;
        font-size: 1.325rem;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .article .post {
        max-height: 116px;
        margin: 0 0 16px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .author {
        height: calc(48px + 15px);
        grid-template-columns: 48px 1fr;
        column-gap: 18px;
        padding: 10px 0 0;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .avatar {
        width: 48px;
        height: 48px;
        align-self: center;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .name {
        align-self: end;
    }
    .pb23-tabs--carousel-archtrends .pb23-carousel--archtrends-posts li:first-of-type > .pb23-card--archtrends-post .content .occupation {
        align-self: end;
    }
}