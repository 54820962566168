#page-terms,
#page-terms * {
    --terms-section--spacer: 180px;
    box-sizing: border-box;
}
/* Cabeçalho e Menu */
#page-terms .pb-tplheadermenu {
    max-width: 25vw;
}
#page-terms .pb-tplheadermenu .divider {
    display: none;
}
#page-terms .pb-tplheadermenu aside ul li {
    margin: 0 0 12px;
}
#page-terms .pb-tplheadermenu aside ul li a {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    color: var(--color-main);
}
/* Conteúdo */
#page-terms .pb-tplcontent {
    margin-top: var(--mainmenu-height);
}
#page-terms .pb-tplcontent > section {
    display: block;
    margin: 0 0 var(--terms-section--spacer);

}
#page-terms .pb-tplcontent > section h3 {
    position: relative;
    margin: 0 0 24px;
}
#page-terms .pb-tplcontent > section h3 span {
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-darker-alt);
}
#page-terms .pb-tplcontent > section h3 div {
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    left: -92px;
    border-radius: 50%;
    background: var(--color-lightest);
}
#page-terms .pb-tplcontent > section p,
#page-terms .pb-tplcontent > section li {
    font-size: 0.875rem;
    color: var(--color-dark);
}
#page-terms .pb-tplcontent > section a {
    color: var(--color-main);
    text-decoration: underline;
}
#page-terms .pb-tplcontent > section ul {
    padding: 0 0 0 1rem;
}
#page-terms .pb-tplcontent > section ol {
    padding: 0 0 0 1rem;
}
#page-terms .pb-tplcontent > section ol li {
    margin: 0 0 1rem;
}
/* Tabelas */
#page-terms .pb-tplcontent > section .terms-table {
    display: block;
    margin: 30px 0;
}
#page-terms .pb-tplcontent > section .terms-table div,
#page-terms .pb-tplcontent > section .terms-table dl {
    display: grid;
    grid-template-columns: 30% 1fr;
    margin: 0;
}
#page-terms .pb-tplcontent > section .terms-table dl:nth-of-type(odd) {
    background: var(--color-lightest);
}
#page-terms .pb-tplcontent > section .terms-table div span {
    display: block;
    padding: 14px;
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-dark);
}
#page-terms .pb-tplcontent > section .terms-table dl dt {
    display: none;
    font-size: 0.75rem;
    color: var(--color-dark);
    font-weight: 700;
    padding: 14px 14px 14px 0;
}
#page-terms .pb-tplcontent > section .terms-table dl dd {
    padding: 14px;
    font-size: 0.875rem;
    color: var(--color-dark);
    margin: 0;
}

/* Specific */
#page-terms .pb-tplcontent > section#termos ul {
    list-style: none;
    padding: 0;
}
#page-terms .pb-tplcontent > section#termos ul li {
    margin: 0 0 1rem;
}
@media (max-width: 992px) {
    #page-terms,
    #page-terms * {
        --terms-section--spacer: 96px;
    }
    #page-terms .pb-tplheadermenu {
        max-width: 100%;
    }
    #page-terms .pb-tplcontent > section h3 div {
        margin: 0px;
    }
    #page-terms .pb-tplcontent > section .terms-table div {
        display: none;
    }
    #page-terms .pb-tplcontent > section .terms-table dl dt:nth-of-type(1),
    #page-terms .pb-tplcontent > section .terms-table dl dd:nth-of-type(1) {
        border-bottom: 1px solid var(--color-lightest);
    }
    #page-terms .pb-tplcontent > section .terms-table dl:nth-of-type(odd) dt:nth-of-type(1),
    #page-terms .pb-tplcontent > section .terms-table dl:nth-of-type(odd) dd:nth-of-type(1) {
        border-bottom: 1px solid var(--color-white);
    }
    #page-terms .pb-tplcontent > section .terms-table dl {
        padding: 0 14px;
        margin: 0 0 8px;
        grid-template-columns: auto 1fr;
        border: 1px solid var(--color-lightest);
    }
    #page-terms .pb-tplcontent > section .terms-table dl dt {
        display: block;
    }
    #page-terms .pb-tplcontent > section .terms-table dl dd {
        padding: 14px 0 14px 14px;
    }

}