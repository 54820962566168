#page-experience,
#page-experience * {
    box-sizing: border-box;
}
#page-experience #tour {
    margin: 1.75rem 0 160px;
}
#page-experience--part2 #kids {
    margin: 30px 0 175px;
}
#page-experience--part2 #services {
    margin: 0 auto 207px;
}
#page-experience--part2 #archtrends {
    margin: 0 0 200px;
}