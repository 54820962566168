#page-about,
#page-about * {    
    box-sizing: border-box;
}
#page-about .pb-content--intro {
    margin: 0 0 78px;
}
#page-about .pb-content--concept-list {
    margin: 0 0 78px;
}
#page-about .pb-content--about-resources {
    margin: 0 0 100px;
}
#page-about .pb-content--about-stats {
    margin: 0 0 175px;
}
#page-about #timeline {
    margin: 0 0 216px;
}
@media (max-width: 992px) {    
    #page-about .pb-content--intro {
        margin: 0 0 107px;
    }
    #page-about .pb-content--concept-list {
        margin: 0 0 60px;
    }
    #page-about .pb-content--about-resources {
        margin: 0 0 62px;
    }
    #page-about .pb-content--about-stats {
        margin: 0 0 40px;
    }
    #page-about #timeline {
        margin: 0 0 46px;
    }
}
