.pb23-section--header, .pb23-section--header * {
}
.pb23-header--content h1 {
    font-size: 2.25rem !important;
}
.pb23-header--line-aside h1 {
    font-size: 2.25rem !important;
}
.pb23-concept--line .content,
.pb23-concept--line .content * {
    font-size: 1rem !important;
}