#page-officina,
#page-officina * {
    box-sizing: border-box;
}
#page-officina .pb-content--officina-concept {
    margin-bottom: 160px;
    margin-top: 30px;
}
@media (max-width:992px) {    
    #page-officina .pb-content--officina-concept {
        margin-bottom: 116px;
    }
}
#page-officina .pb-content--officina-pretaporter {
    margin-bottom: 210px;
}
@media (max-width:992px) {    
    #page-officina .pb-content--officina-pretaporter {
        margin-bottom: 170px;
    }
}