.cc-window,
.cc-window * {
  box-sizing: border-box;
}
.cc-grower .cc-window,
.cc-window {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100vh;
  max-height: 100vh !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: column !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  padding-top: 48px !important;
  z-index: 100000000;
  background: rgba(0,0,0,0.8) !important;
}
.cc-grower .cc-window .cc-message-container,
.cc-window .cc-message-container {
  width: 80%;
  max-width: 460px !important;
  flex-grow: 0 !important;
  margin: 0 0 -1px;
  padding: 50px 70px 30px;
  background: #fff;
}
.cc-grower .cc-window .cc-message-container:before,
.cc-window .cc-message-container:before {
  width: 100%;
  height: 30px;
  margin: 0 0 30px;
  display: block;
  content: "";
  background: url("https://www.portobello.com.br/template/images/logo.svg") 0 0 no-repeat;
}
.cc-grower .cc-window .cc-message-container a,
.cc-window .cc-message-container a {
  color: #212529;
  opacity: 1;
}
.cc-grower .cc-window .cc-message-container > span,
.cc-window .cc-message-container > span {
  margin: 0;
  font-size: 14px;
}
.cc-grower .cc-window .cc-compliance,
.cc-window .cc-compliance {
  width: 80%;
  max-width: 460px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 42px;
  gap: 10px;
  padding: 0px 30px 50px;
  background: #fff;
  flex-grow: 0 !important;
}
.cc-grower .cc-window .cc-compliance > a,
.cc-window .cc-compliance > a {
  width: 100%;
  max-width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 0px;
  border: 1px solid rgb(206,206,206) !important;
  background: none !important;
  font-size: 14px;
  font-weight: 700;
  color: #08234e !important;
  text-decoration: none !important; 
  opacity: 1 !important;
}
.cc-grower .cc-window .cc-compliance > a span, 
.cc-window .cc-compliance > a span {
  font-weight: 700;
  color: #08234e !important;
}
.cc-grower .cc-window .cc-compliance > a:hover,
.cc-window .cc-compliance > a:hover {
  text-decoration: none !important; 
}
.cc-grower .cc-window.cc-invisible, .cc-window.cc-invisible {
  display: none !important;
  font-weight: 300;
}
/* Janela de preferências */
.cmp-body {
  z-index: 100000001 !important;
}
@media (max-width: 992px) {
  .cc-grower .cc-window .cc-message-container,
  .cc-window .cc-message-container {
    width: 90%;
    max-width: 312px !important;
    padding: 30px 30px 30px;
  }
  .cc-grower .cc-window .cc-compliance,
  .cc-window .cc-compliance {
    width: 90%;
    max-width: 312px !important;
    flex: 0 !important;
  }
}

/* ALTERAÇÃO EM 2022-05-23*/
.cc-window.cc-banner {
  width: 360px !important;
  height: auto !important;
  padding: 0 !important;
  background: none !important;
  top: auto !important;
  left: 20px !important;
  right: auto !important;
  bottom: 0px !important;
}
.cc-grower .cc-window .cc-message-container, .cc-window .cc-message-container {
  width: 360px;
  padding: 25px;
  top: 0 !important;
  background: #f5f6f7;
}
.cc-grower .cc-window .cc-compliance, .cc-window .cc-compliance {
  width: 360px;
  padding: 0 25px 25px;
  background: #f5f6f7;
}
.cc-grower .cc-window .cc-message-container:before, .cc-window .cc-message-container:before {
  display: none;
}
.cc-grower .cc-window .cc-compliance > a.cmp-pref-link, .cc-window .cc-compliance > a.cmp-pref-link {
  border: none !important;
}
.cc-grower .cc-window .cc-compliance > a.cmp-pref-link > span, .cc-window .cc-compliance > a.cmp-pref-link > span {
    font-weight: 300 !important;
}
@media (max-width: 769px) and (min-width: 601px) {
  .cc-color-override--222512206.cc-window {
      height: 100vh;
      padding-top: 48px !important;
  }
}
@media (max-width: 600px) {
  .cc-window.cc-banner {
      width: 100vw !important;
      max-width: 100vw !important;
      left: 0px !important;
  }
  .cc-grower .cc-window .cc-message-container, .cc-window .cc-message-container,
  .cc-grower .cc-window .cc-compliance, .cc-window .cc-compliance {
      width: 100vw !important;
      max-width: 100vw !important;
  }
}

@media (max-width: 769px) {
  .cc-grower .cc-window, .cc-window {
      height: auto;
      padding: 0 !important;
      border-radius: 0;
      top: auto !important;
      bottom: 0px;
  }
}