.pb-tracking-link {
  font-size: 1rem;
  font-weight: 700;
  color: var(--color-light);
  border-bottom: 1px;
  text-decoration: underline 2px var(--color-lightest);
}
.hasHelpLink {
  position: relative;
}
.hasHelpLink .pb-help--link {
  position: absolute;
  right: 0px;
  top: 25px;
  z-index: 5;
}
.hasHelpLink {
  position: relative;
}
.hasHelpLink .pb-help--link {
  position: absolute;
  right: 0px;
  top: 24.5px;
  z-index: 5;
}
.hasHelpLink .form-control.is-invalid ~ .pb-help--link, .was-validated .hasHelpLink .form-control:invalid ~ .pb-help--link {
  right: 28px;
}
#pb-tracking--access {
    margin-top: 200px;
    margin-bottom: 300px;
}
#pb-tracking--access .image {
    width: 100%;
    max-width: 680px;
}
#pb-tracking--access .image img {
    width: 100%;
}
#pb-tracking--access header {
  padding-bottom: 52px;
}
#pb-tracking--access header:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: relative;
    background: var(--color-main);
}
#pb-tracking--access header h1 {
  margin: 0 0 36px;  
}
#pb-tracking--access p {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 300;
  margin-bottom: 46px;
}
#pb-tracking--access .form-submit {
  padding-top: 50px;
}
#pb-tracking--access .form-submit > button {
  max-width: 300px;
  border: 0;
}
#pb-tracking--access .form-submit > .pb-tracking-link {
  margin-left: 30px;
}
#pb-tracking--access .info {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 300;
  color: var(--color-darkest);
  line-height: 1.375;
}
#pb-tracking--access .info a {
  color: inherit;
  text-decoration: underline;
}
#pb-tracking--access div.error
{
  padding: 10px;
  width: 100%;
  background-color: red;
  color: white;
  text-align:center;
  margin-bottom: 20px;
}
@media(max-width: 992px) {
    #pb-tracking--access {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    #pb-tracking--access header {
      padding-top: 30px;
      padding-bottom: 25px;
    }
    #pb-tracking--access header h1 {
      margin: 0 0 24px;  
    }
    #pb-tracking--access .form-submit > button {
      max-width: 100%;
    }
    
}

/* DETALHES DO PEDIDO */
#pb-tracking--order header {
    padding-top: 80px;
    margin-bottom: 40px;
}
#pb-tracking--access .info {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 300;
  color: var(--color-darkest);
  line-height: 1.375;
}
@media(max-width: 440px) {
  #pb-tracking--order header {
    padding-top: 20px;
  }
  #pb-tracking--access .form-submit {
    text-align: center;
  }
  #pb-tracking--access .form-submit > .pb-tracking-link {
    margin: auto;
    padding: 20px;
    display: block;
  }
}
#pb-tracking--order .resumo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
#pb-tracking--order .resumo ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}
#pb-tracking--order .resumo ul li {
  margin: 0 80px 0 0;
}
#pb-tracking--order .resumo ul li img {
  width: 52px;
  height: 52px;
  display: inline-block;
  object-fit: contain;
  object-position: center;
  margin: 0 0 8px;
}
#pb-tracking--order .resumo ul li span,
#pb-tracking--order .resumo ul li div,
#pb-tracking--order .resumo ul li a,
#pb-tracking--order .resumo ul li a span {
  position: relative;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  color: var(--color-darker-alt);
  text-decoration: none;
}
#pb-tracking--order .resumo ul li span,
#pb-tracking--order .resumo ul li a {
  display: block;
}
#pb-tracking--order .resumo ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
#pb-tracking--order .resumo ul li a:after {
  width: 7px;
  height: 7px;
  display: block;
  content: "";
  border-right: 2px solid var(--color-light);
  border-bottom: 2px solid var(--color-light);
  position: relative;
  top: 0px;
  right: 0px;
  margin-left: 10px;
  transform: rotate(45deg);
}
#pb-tracking--order .resumo ul li span.title {
  font-weight: 700;
  font-size: 1rem;
}
#pb-tracking--order .resumo .options {
  align-self: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
#pb-tracking--order .resumo .options .btn {
  width: 300px;
  border: 0;
  padding-top: 8px;
}
#pb-tracking--order .resumo.subheader ul li.tracking-pedido,
#pb-tracking--order .resumo.mainorder ul li.tracking-nfe,
#pb-tracking--order .resumo.subheader .options {
  display: none;
}
#pb-tracking--order .options--mobile-only {
  display: none;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
  gap: 8px;
}
#pb-tracking--order .options--mobile-only .btn {
  width: 100%;
  border: 0;
  padding-top: 8px;
}
@media (max-width: 992px) { 
    #pb-tracking--order header {
      padding-top: 30px;
    }
    #pb-tracking--order .resumo ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }
    #pb-tracking--order .resumo ul li {
        margin: 0;
    }
    #pb-tracking--order .resumo ul li:last-of-type {
        grid-column: span 2;
    }
    #pb-tracking--order .resumo > .btn {
        display: none;
    }
    #pb-tracking--order .options--mobile-only {
      display: flex;
    }
}
#pb-tracking--order .timeline {
    margin-top: 60px;
    margin-bottom: 60px;
}
#pb-tracking--order .timeline ul {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 0px;
    margin: 0;
    padding: 0;
    list-style: none;
}
#pb-tracking--order .timeline ul li {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
}
#pb-tracking--order .timeline ul li > .bullet {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  background: var(--color-main);
  position: absolute;
  top: 36px;
  z-index: 3;
  left: 0px;
}
#pb-tracking--order .timeline ul li:before {
    width: 50%;
    height: 3px;
    display: block;
    content: "";
    background: #f1f1f1;
    position: absolute;
    left: 0px;
    top: 45px;
    z-index: 1;
}
#pb-tracking--order .timeline ul li.active:before {
    height: 5px;
    background: var(--color-main);
    top: 44px;
}
#pb-tracking--order .timeline ul li:nth-of-type(1):before {
    height: 5px;
    background: var(--color-main);
    top: 44px;
    left: 10px;
}
#pb-tracking--order .timeline ul li:after {
    width: 50%;
    height: 3px;
    display: block;
    content: "";
    background: #f1f1f1;
    position: absolute;
    right: 0px;
    top: 45px;
    z-index: 1;
}
#pb-tracking--order .timeline ul li.done:after {
    height: 5px;
    background: var(--color-main);
    top: 44px;
}
#pb-tracking--order .timeline ul li:last-of-type:after {
    display: none;
}
#pb-tracking--order .timeline ul li .image {
    width: 93px;
    height: 93px;
    position: relative;
    z-index: 2;
}
#pb-tracking--order .timeline ul li .image img.icon {
    filter: grayscale(100%);
}
#pb-tracking--order .timeline ul li.active .image img.icon {
    filter: none;
}
#pb-tracking--order .timeline ul li .image img.checked {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
}
#pb-tracking--order .timeline ul li.active .image img.checked {
    display: block;
}
#pb-tracking--order .timeline ul li:nth-of-type(1) .image img.icon {
    filter: none;
}
#pb-tracking--order .timeline ul li:nth-of-type(1) .image img.checked {
    display: block;
}
#pb-tracking--order .timeline ul li .title {
    display: block;
    margin: 6px 0;
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-main);
}
#pb-tracking--order .timeline ul li .info {
    width: 80%;
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
#pb-tracking--order .timeline ul li .info.not {
    display: flex;
}
#pb-tracking--order .timeline ul li.active .info.not {
    display: none;
}
#pb-tracking--order .timeline ul li:nth-of-type(1) .info {
    display: flex;
}
#pb-tracking--order .timeline ul li.active .info.ok {
    display: flex;
}
#pb-tracking--order .timeline ul li .info span {
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.4;
}
#pb-tracking--order .timeline ul li .info span + span {
    margin: 0 0 0 4px;
}
#pb-tracking--order .timeline ul li .info span.data {
      font-weight: 700;
      color: var(--color-main);
}
#pb-tracking--order .timeline ul li .info a.pb-tracking-link {
      display: block;
      margin: 4px 0 0;
}
@media (max-width: 992px) {
    #pb-tracking--order .timeline ul {
        grid-template-columns: 100%;
        gap: 30px;
    }
    #pb-tracking--order .timeline ul li .bullet,
    #pb-tracking--order .timeline ul li:before,
    #pb-tracking--order .timeline ul li:after {
        display: none !important;
    }

}
#pb-tracking--order .details {
    margin-bottom: 100px;
}
#pb-tracking--order .card .details {
    margin-bottom: 0px;
}
#pb-tracking--order .details .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 40px;
}
#pb-tracking--order .details .header h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#pb-tracking--order .details .header span {
    margin: 0 0 0 8px;
    font-size: 1.75rem;
    font-weight: 300;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#pb-tracking--order .details .table table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  #pb-tracking--order .details .table table th {
    padding: 12px 18px;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-darkest);
  }
  #pb-tracking--order .details .table table td {
    background: var(--color-lightest);
    padding: 12px 18px;
    border: none;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-dark-alt);
  }
  #pb-tracking--order .details .table table tr:nth-of-type(even) td {
    background: var(--color-white);
  }
  #pb-tracking--order .details .table table td > * {
    color: var(--color-dark-alt);
  }
  #pb-tracking--order .details .table table tr td .title {
    display: none;
  }
  #pb-tracking--order .details .table table tr td .value {
    font-size: 1rem;
    font-weight: 300;
  }
  #pb-tracking--order .details .table table tr td:nth-of-type(1) {
    width: 68px;
    font-weight: 700;
    text-align: center;
  }
  #pb-tracking--order .details .table table tr td:nth-of-type(2) {
    width: 150px;
    font-weight: 700;
    text-align: left;
  }
  #pb-tracking--order .details .table table tr td:nth-of-type(2) .value {
    font-weight: 700;
  }
  #pb-tracking--order .details .table table tr td .unit {
    font-weight: 300;
    margin: 0 0 0 4px;
  }
  #pb-tracking--order .orders--list {
    margin-top: 40px;
  }
/*  #pb-tracking--order .orders--list.first {
    margin-top: 60px;
  }
*/  
  #pb-tracking--order .orders--list .orders--item {
    margin: 0 0 30px;
  }
  #pb-tracking--order .orders--list .orders--item > a {
    width: 100%;
    display: block;
    color: var(--color-darkest);
    position: relative;
    padding: 20px 60px 20px 40px;
    font-weight: 300;
    background: #f1f1f1;
    text-decoration: none;
  }
  #pb-tracking--order .orders--list .orders--item > a b {
    font-weight: 700;
  }
  #pb-tracking--order .orders--list .orders--item > a b:after {
    display: inline;
    content: "-";
    margin: 0 4px;
  }
  #pb-tracking--order .orders--list .orders--item > a:after {
    width: 8px;
    height: 8px;
    display: block;
    content: "";
    border-bottom: 2px solid #8390A5;
    border-right: 2px solid #8390A5;
    position: absolute;
    top: calc(50% - 6px);
    right: 40px;
    transform: rotate(45deg);
  }
  #pb-tracking--order .orders--list .orders--item > a[aria-expanded="true"]:after {
    transform:rotate(-135deg);
    top: calc(50% - 3px);
  }
  #pb-tracking--order .orders--list .orders--item .card.card-body {
    padding: 30px 0px 16px;
    border: none;
  }

  @media (max-width: 992px) {
    #pb-tracking--order .details .header {
        margin: 0 0 15px;
    }
    #pb-tracking--order .details .table table {
      border-spacing: 0;
    }
    #pb-tracking--order .details .table table thead {
      display: none;
    }
    #pb-tracking--order .details .table table tr {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: "number number" "product quantity";
      margin-bottom: 15px;
    }
    #pb-tracking--order .details .table table tr td .title {
      display: block;
      font-weight: 400;
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(1) {
      grid-area: number;
      width: 100%;
      display: block;
      padding: 18px 0 0 14px;
      font-size: 25px;
      font-weight: 700;
      text-align: left;
      color: var(--color-light);
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(2) {
      grid-area: quantity;
      width: 100%;
      display: block;
      padding: 10px 10px 0 0;
      font-weight: 700;
      text-align: left;
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(2) .value {
      font-size: 1.115rem;
      font-weight: 700;
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(2) .unit {
      font-size: 1.115rem;
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(3) {
      grid-area: product;
      width: 100%;
      display: block;
      padding: 10px 10px 14px 14px;
      font-weight: 700;
      text-align: left;
    }
    #pb-tracking--order .details .table table tr td:nth-of-type(3) .value {
      display: block;
      margin-top: 2px;
      font-size: 1.115rem;
      font-weight: 300;
      color: var(--color-darker-alt);
    }
    #pb-tracking--order .orders--list .orders--item > a {
      padding: 15px 30px 15px 15px;
    }
    #pb-tracking--order .orders--list .orders--item > a:after {
      right: 15px;
    }
    #pb-tracking--order .orders--list .orders--item > a > * {
      display: block;
    } 
    #pb-tracking--order .orders--list .orders--item > a b:after {
      display: none;
    }
    #pb-tracking--order .resumo.mainorder ul li img {
      display: none !important;
    }
    #pb-tracking--order .resumo.mainorder .options {
      display: none;
    }
    #pb-tracking--order .resumo.mainorder .tracking-pedido .data {
      display: block;
      font-size: 1rem;
      margin: 10px 0 0;
    }
    #pb-tracking--order .orders--list {
      margin-top: 30px;
    }
  }

  /* MODAL LIST */
  #modal-tracking-places .modal-dialog .modal-body {
    padding: 36px 52px 28px;
  }
  #modal-tracking-places .modal-dialog .modal--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  #modal-tracking-places .modal-dialog .modal--header .title {
    max-width: 360px;
    margin: 0 0 4px;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }
  #modal-tracking-places .modal-dialog .modal--header a.close {
    padding-right: 30px;
    position: relative;
  }
  #modal-tracking-places .modal-dialog .modal--header a.close:after {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 12px;
    background: var(--color-dark);
    transform: rotate(45deg);
  }
  #modal-tracking-places .modal-dialog .modal--header a.close:before {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 12px;
    background: var(--color-dark);
    transform: rotate(-45deg);
  }
  #modal-tracking-places .modal-dialog .modal--header a.close > span {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  #modal-tracking-places .modal-dialog p {
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
  #modal-tracking-places .modal-dialog .modal-table--container table {
    width: 100%;
  }
  #modal-tracking-places .modal-dialog .modal-table--container table tr {
    background: var(--color-white);
  }
  #modal-tracking-places .modal-dialog .modal-table--container table tr:nth-of-type(odd) {
    background: var(--color-lightest);
  }
  #modal-tracking-places .modal-dialog .modal-table--container table tr td {
    width: 50%;
    padding: 14px 25px;
    font-weight: 700;
    color: var(--color-dark-alt);
  }
  #modal-tracking-places .modal-dialog .modal-table--container table tr td:nth-of-type(2) {
    font-weight: 300;
  }
  #modal-tracking-places .modal-dialog .modal-table--container .modal-table--header table tr {
    background: var(--color-white);
  }
  #modal-tracking-places .modal-dialog .modal-table--container .modal-table--header table tr td {
    color: var(--color-darkest);
    font-weight: 700;
  }
  #modal-tracking-places .modal-dialog .modal-table--container .modal-table--body {
    max-height: 370px;
    overflow: auto;
  }
  #modal-tracking-places .modal-dialog .modal--addons {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #modal-tracking-places .modal-dialog .modal--addons .btn.close {  
      width: 100%;
      max-width: 302px;
      height: 40px;
      font-size: 0.875rem;
      color: var(--color-main) !important;
      font-weight: 700;
      border: none;
      background: var(--color-lightest);
      opacity: 1;
  }
  @media(max-width: 992px) {
    #modal-tracking-places {
      width: 100%;
      height: calc(100% - var(--mainmenu-height) + 1px);
      top: calc(var(--mainmenu-height) - 1px);
    }
    #modal-tracking-places + .modal-backdrop {
      display: none;
    }
    #modal-tracking-places .modal-dialog {
      width: 100vw;
      max-width: 100vw;
      height: auto;
      max-height: auto;
      margin: 0px;
    }
    #modal-tracking-places .modal-dialog .modal-content {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
    #modal-tracking-places .modal-dialog .modal-table--container .modal-table--body {
      max-height: none;
    }
  }
  /* MODAL ORDER DETAILS*/
  .modal-tracking-order .modal-dialog .modal-body {
    padding: 36px 52px 28px;
  }
  .modal-tracking-order .modal-dialog .modal--header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .modal-tracking-order .modal-dialog .modal--header .title {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
    line-height: 1.2;
  }
  .modal-tracking-order .modal-dialog .modal--header a.close {
    padding-right: 30px;
    position: relative;
  }
  .modal-tracking-order .modal-dialog .modal--header a.close:after {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 12px;
    background: var(--color-dark);
    transform: rotate(45deg);
  }
  .modal-tracking-order .modal-dialog .modal--header a.close:before {
    width: 24px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    right: 0px;
    top: 12px;
    background: var(--color-dark);
    transform: rotate(-45deg);
  }
  .modal-tracking-order .modal-dialog .modal--header a.close > span {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  .modal-tracking-order .modal-dialog p {
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
  .modal-tracking-order .modal-dialog .modal-table--container table {
    width: 100%;
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr {
    background: var(--color-white);
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr:nth-of-type(odd) {
    background: var(--color-lightest);
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr td {
    width: 20%;
    padding: 14px 15px;
    font-weight: 700;
    color: var(--color-dark-alt);
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr td > img {
    margin-right: 8px;
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr td:nth-of-type(3) {
    width: 60%;
    font-weight: 300;
  }
  .modal-tracking-order .modal-dialog .modal-table--container .modal-table--header table tr {
    background: var(--color-white);
  }
  .modal-tracking-order .modal-dialog .modal-table--container .modal-table--header table tr td {
    color: var(--color-darkest);
    font-weight: 700;
  }
  .modal-tracking-order .modal-dialog .modal-table--container .modal-table--body {
    max-height: 370px;
    overflow: auto;
  }
  .modal-tracking-order .modal-dialog .modal--addons {
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-tracking-order .modal-dialog .modal--addons .btn.close {  
      width: 100%;
      max-width: 302px;
      height: 40px;
      font-size: 0.875rem;
      color: var(--color-main) !important;
      font-weight: 700;
      border: none;
      background: var(--color-lightest);
      opacity: 1;
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr.done {
    background: #54AE72;
  }
  .modal-tracking-order .modal-dialog .modal-table--container table tr.done td {
    font-weight: 700;
    color: var(--color-white);
  }
  @media(max-width: 992px) {
    .modal-tracking-order {
      width: 100%;
      height: calc(100% - var(--mainmenu-height) + 1px);
      top: calc(var(--mainmenu-height) - 1px);
    }
    .modal-tracking-order .modal-dialog .modal-body {
      padding: 20px;
    }
    .modal-tracking-order + .modal-backdrop {
      display: none;
    }
    .modal-tracking-order .modal-dialog {
      width: 100vw;
      max-width: 100vw;
      height: auto;
      max-height: auto;
      min-height: 100%;
      margin: 0px;
    }
    .modal-tracking-order .modal-dialog .modal-content {
      width: 100%;
      max-width: 100%;
      align-self: stretch
    }
    .modal-tracking-order .modal-dialog .modal-table--container .modal-table--body {
      max-height: none;
    }
    .modal-tracking-order .modal--header {
      gap: 20px;
    }
    .modal-tracking-order .modal-dialog .modal-table--header {
      display: none;
    }
    .modal-tracking-order .modal-dialog .modal-table--container {
      margin-top: 18px;
    }
    .modal-tracking-order .modal-dialog .modal-table--container table tr {
      display: flex;
      flex-wrap: wrap;
    }
    .modal-tracking-order .modal-dialog .modal-table--container table tr td:nth-of-type(1) {
      width: auto;
      padding: 10px 5px 0 20px;
      font-weight: 400;
    }
    .modal-tracking-order .modal-dialog .modal-table--container table tr td:nth-of-type(2) {
      width: auto;
      padding: 10px 20px 0 5px;
    }
    .modal-tracking-order .modal-dialog .modal-table--container table tr td:nth-of-type(3) {
      width: 100%;
      padding: 5px 20px 10px 20px;
    }
    #pb-tracking--order ~ .modal-backdrop {
      display: none;
    }
    #pb-tracking--order .timeline ul li .info a {
      display: block;
      width: 100%;
      text-align: center;
      content: "";
    }
    .modal-tracking-order .modal-dialog .modal--addons .btn.close {
      max-width: 100%;
    }
}
