#pb-franchise {

}
#pb-franchise h2 {
    margin: 0 0 24px;
    font-size: 1.875rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#pb-franchise header + .content p {
    margin: 0 0 34px;
    font-size: 1.71rem;
    line-height: 1.2;
}
#pb-franchise header {
  margin-top: 180px;
}
@media (max-width: 992px) {
    #pb-franchise header {
        margin-top: 40px;
    }
    #pb-franchise p {
        font-size: 1rem;
    }
}
#pb-franchise .mainimage img {
    width: 100%;
}
#pb-franchise .mainimage {
    margin-top: 75px;
}
#pb-franchise div.gallery-images
{
    display: none;
}

@media (max-width: 992px) {
    #pb-franchise .mainimage {
        margin: 0 -28px 25px;
    }
}
#pb-franchise #rede {
    margin-top: 76px;
}
#pb-franchise #rede h2 {
    margin-bottom: 32px;
}
#pb-franchise #rede li {
    margin: 0 0 6px;
}
#pb-franchise #rede a {
    color: #212529;  
}
#pb-franchise #modelos {
    margin-top: 76px;
}
#pb-franchise #modelos .table-responsive {
    display: grid;
    grid-template-columns: repeat(7,1fr);
    gap: 16px;
}
#pb-franchise #modelos .table-responsive > dl > dt {
    display: none;
}
#pb-franchise #modelos .table-responsive > dl:nth-of-type(1) > dt {
    display: flex;
    font-size: 0.75rem;
    justify-content: flex-start;
    text-align: left;
}
#pb-franchise #modelos .table-responsive dt,
#pb-franchise #modelos .table-responsive dd {
    height: 62px;
    display: flex;
    padding: 0 26px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #ebebeb;
    margin: 0;
}
#pb-franchise #modelos .table-responsive dt:nth-of-type(odd),
#pb-franchise #modelos .table-responsive dd:nth-of-type(odd){
    background: #eff2f6;
    
}
#pb-franchise #modelos .table-responsive dd:nth-of-type(1){
    background: #fff;
    border: 2px solid #ebebeb;
    font-size: 20px;
}
#pb-franchise #modelos .table-responsive dt:nth-of-type(1) {
    background: #fff;
    font-size: 1rem !important;
    font-weight: 300;
    text-transform: uppercase;
}
#pb-franchise #modelos .gallery-container {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 12px;
    margin: 33px 0 0;
}
#pb-franchise #modelos .gallery-container .item {
    width: 100%;
    height: 0;
    padding: 0 0 125%;
    display: block;
    position: relative;
}
#pb-franchise #modelos .gallery-container .item > img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}
#pb-franchise #modelos .gallery-container .item > .magnify {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    object-fit: contain;
    object-position: center;
    z-index: 2;
}
#pb-franchise #modelos .gallery-container .item > .title {
    width: 100%;
    padding: 6px 12px;
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 12px;
    color: var(--color-white);
    z-index: 2;
    background: var(--color-darkest);
}
@media (max-width: 992px) {
    #pb-franchise #modelos .table-container {
        padding: 0 28px;
      }
      #pb-franchise #modelos .table-responsive {
        display: block;
      }
      #pb-franchise #modelos .table-responsive dl:nth-of-type(1) {
        display: none;
      }
      #pb-franchise #modelos .table-responsive dl {
          margin: 0;
          display: grid;
          grid-template-columns: repeat(2,1fr);
          row-gap: 10px;
          padding: 20px 18px 25px;
          background: #ebebeb;
      }
      #pb-franchise #modelos .table-responsive dl:nth-of-type(odd) {
          background: #eff2f6;
      }
      #pb-franchise #modelos .table-responsive dl dt {
        display: block;
      }
      #pb-franchise #modelos .table-responsive dl dt:nth-of-type(1) {
        display: none;
      }
      #pb-franchise #modelos .table-responsive dl dd:nth-of-type(1) {
        grid-column: span 2;
        padding-bottom: 10px;
      }
      #pb-franchise #modelos .table-responsive dl dt,
      #pb-franchise #modelos .table-responsive dl dd {
          height: auto;
          background: none !important;
          border: none !important;
          justify-content: flex-start !important;
        text-align: left;
      }
      #pb-franchise #modelos .gallery-container {
          display: grid;
          grid-template-columns: 100%;
          gap: 20px;
          margin: 33px 0 0;
      }
}
#pb-franchise #tour {
    margin-top: 58px;
  }
  #pb-franchise #tour .tour-container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 16px;
  }
  #pb-franchise #tour .tour-container > a {
    width: 100%;
    height: 0;
    display: block;
    padding: 0 0 100%;
    position: relative;
  }
  #pb-franchise #tour .tour-container > a > img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
  }
  #pb-franchise #tour .tour-container > a .title {
    width: calc(100% - 60px);
    font-size: 1.7rem;
    display: block;
    position: absolute;
    top: 30px;
    left: 30px;
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: 700;
    z-index: 2;
  }
  #pb-franchise #tour .tour-container > a .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px 0 0;
    font-size: 1.4rem;
    color: var(--color-white);
    z-index: 2;
  }
  #pb-franchise #tour .tour-container > a .info img {
    margin-bottom: 8px;
  }
@media (max-width: 992px) {      
    #pb-franchise #tour .tour-container {
        grid-template-columns: 100%;
        gap: 20px;
    }
    #pb-franchise #tour .tour-container > a .title {
        text-align: center;
    }
}
#pb-franchise #requisitos {
    background: #e6eaf2;
    margin-top: 80px;
    padding-top: 78px;
    padding-bottom: 78px;    
  }
  #pb-franchise #requisitos h2 {
    margin-bottom: 60px;
  }
  #pb-franchise #requisitos span,
  #pb-franchise #requisitos p {
    width: 90%;
    font-size: 1rem;
  }
  #pb-franchise #requisitos .title {
    display: block;
    margin: 0 0 18px;
    font-size: 21px;
  }
  #pb-franchise #requisitos .col-12:nth-of-type(6),
  #pb-franchise #requisitos .col-12:nth-of-type(7) {
    padding-top: 84px;
  }
  #pb-franchise #requisitos .subtitle {
    display: block;
    font-weight: 700;
  }
  #pb-franchise #requisitos ul {
    padding-left: 0px;
    list-style: none;
  }
  #pb-franchise #requisitos ul li:before {
    content: "-";
    margin-right: 6px;
  }
  @media (max-width: 992px) {
    #pb-franchise #requisitos {
      margin-top: 28px;
      padding-top: 28px;
      padding-bottom: 28px;  
    }
    #pb-franchise #requisitos span, #pb-franchise #requisitos p {
        width: 100%;
    }
    #pb-franchise #requisitos .col-12:nth-of-type(6),
    #pb-franchise #requisitos .col-12:nth-of-type(7) {
      padding-top: 0px;
    }
    #pb-franchise #requisitos .col-12:nth-of-type(even) {
      margin-bottom: 12px;
    }
    #pb-franchise #requisitos .col-12:nth-of-type(odd) {
      margin-bottom: 30px;
    }
  }
  #pb-franchise #vantagens {
    background: #e6eaf2;
    padding-top: 78px;
    padding-bottom: 78px;
}
#pb-franchise #vantagens p {
  width: 90%;
}
#pb-franchise #vantagens a {
  width: 75%;
  margin-top: 20px;
}
#pb-franchise #vantagens ul {
  width: 90%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap: 30px;
  margin: 0;
  padding: 0;
}
#pb-franchise #vantagens ul li {
  width: 75%;
  font-weight: 700;
  color: var(--color-darker-alt);
}
#pb-franchise #vantagens ul li img {
  display: block;
  margin: 0 0 9px;
}
@media (max-width: 992px) {
    #pb-franchise #vantagens {
      padding-top: 28px;
      padding-bottom: 28px;
  
    }
    #pb-franchise #vantagens p,
    #pb-franchise #vantagens a {
        width: 100%;
    }
  #pb-franchise #vantagens ul {
    margin-top: 25px;
    grid-template-columns: repeat(2,1fr)
  }  
  #pb-franchise #vantagens ul li {
    width: 100%;
  }
}
#pb-franchise #premios {
    margin-top: 120px;
    padding-bottom: 80px;
}
#pb-franchise #premios a.btn {
    margin-top: 20px;
  }
  #pb-franchise #premios ul {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    column-gap: 20px;
    row-gap: 40px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #pb-franchise #premios ul li > img {
    width: 90px;
    height: 90px;
    margin: 0 0 6px;
    display: block;
    object-fit: contain;
    object-position: bottom left;
  }
  #pb-franchise #premios ul li span {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
  }
  #pb-franchise #premios ul li .year {
    display: block;
    margin: 0 0 6px;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-light);
  }
  #pb-franchise #premios .card {
      border: none;
      padding-top: 40px;
  }
  #pb-franchise #premios .more-collapse {
      display: flex;
      justify-content: center;
  }
  #pb-franchise #premios .more-collapse a.btn {
      width: 236px;
      padding: 9px 70px;
      font-size: 0.875rem;
      color: var(--color-light);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2px;
      border: 1px solid var(--color-light);
  }
  #pb-franchise #premios .collapse.show + .more-collapse  {
      display: none;
  }
  @media (max-width: 992px) {  
    #pb-franchise #premios {
        margin-top: 28px;
    } 
    #pb-franchise #premios a.btn {
        margin-bottom: 30px;
    }  
    #pb-franchise #premios ul {
        grid-template-columns: repeat(2,1fr);
        column-gap: 10px;
        row-gap: 20px;
    }
  }

  /* FORMULÁRIO */
  #pb-franchise-form {
      padding-top: 64px;
  }
  #pb-franchise-form .pb-pageheader-v2 {
      margin-bottom: 40px;
  }
  #pb-franchise-form h1 {
      padding-bottom: 36px;
  }
  #pb-franchise-form .subtitle {
      display: block;
      margin-bottom: 20px;
      font-size: 0.875rem;
      font-weight: 300;
      color: var(--color-darkest);
  }
  #pb-franchise-form #content > img {
      display: block;
      margin-bottom: 36px;
  }
  #pb-franchise-form #content p {
      font-size: 1.7rem;
      font-weight: 300;
      line-height: 1.2;
  }
  #pb-franchise-form #step > ul {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin: 0;
      padding: 0;
      list-style: none;
  }
  #pb-franchise-form #step > ul li {
      margin: 0 16px 0 0;
  }
  #pb-franchise-form #step > ul li a,
  #pb-franchise-form #step > ul li > div {
      height: 95px;
      padding: 4px 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  }
  #pb-franchise-form #step > ul li .number {
      display: block;
      font-size: 70px;
      font-weight: 700;
      line-height: 1;
  }
  #pb-franchise-form #step > ul li.active div {
      background: var(--color-main);
      text-shadow: none;
  }
  #pb-franchise-form #step > ul li.active div .number {
      color: var(--color-white);
      text-shadow: none;
  }
  #pb-franchise-form #step > ul li.active div .title {
      display: block;
      max-width: 160px;
      min-height: 50.38px;
      margin: 0 0 0 15px;
      font-size: 1.5rem;
      font-weight: 400;
      color: var(--color-white);
      line-height: 1.2;
      text-shadow: none;
  }
  #pb-franchise-form #step > ul li div,
  #pb-franchise-form #step > ul li a {
      color: var(--color-lightest);
      text-shadow: 1px 0 0 var(--color-light), -1px 0 0 var(--color-light), 0 1px 0 var(--color-light), 0 -1px 0 var(--color-light), 1px 1px var(--color-light), -1px -1px 0 var(--color-light), 1px -1px 0 var(--color-light), -1px 1px 0 var(--color-light);
      background: var(--color-lightest);
      text-decoration: none;
  }
  #pb-franchise-form #form {
    margin-top: 46px;
    margin-bottom: 300px;
  }
  #pb-franchise-form #form .form--title {
      display: block;
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
  }
  #pb-franchise-form #form form {
    display: grid;
    grid-template-columns: 100%;
    gap: var(--container-grid-spacer);
    margin-top: 16px;
  }
  #pb-franchise-form #form form .form-submit {
      width: 100%;
      max-width: 614px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 16px;
      margin-top: 30px;
  }
  #pb-franchise-form[data-step="02"] #form form {
    grid-template-columns: 3fr 0.5fr 0.5fr 1fr 2fr;
  }
  #pb-franchise-form[data-step="02"] #form form .form--nome {
  }
  #pb-franchise-form[data-step="02"] #form form .form--data {
    grid-columns: span 3;
  }
  #pb-franchise-form[data-step="02"] #form form .form--nome {
  }
  #pb-franchise-form[data-step="02"] #form form .form--data {
    grid-column: span 3;
  }
  #pb-franchise-form[data-step="02"] #form form .form--sexo .form--title {
    display: block;
    margin-bottom: 10px;
  }
  #pb-franchise-form[data-step="02"] #form form .form--cep,
  #pb-franchise-form[data-step="02"] #form form .form--telefone {
    grid-column: span 2;
  
  }
  #pb-franchise-form[data-step="02"] #form form .form--email,
  #pb-franchise-form[data-step="02"] #form form .form--celular {
    grid-column: span 3;
  } 
  #pb-franchise-form[data-step="02"] #form form .form-submit {

  }
  #pb-franchise-form[data-step="02"] #form form .form-submit {
    grid-column: span 5;
  }
  #pb-franchise-form[data-step="03"] #form form {
    grid-template-columns: repeat(7,1fr);
  }
  #pb-franchise-form[data-step="03"] #form form .form--situacao {
    grid-column: span 5;
  }
  #pb-franchise-form[data-step="03"] #form form .form--situacao .form--title {
    display: block;
    margin-bottom: 10px;
  }
  #pb-franchise-form[data-step="03"] #form form .form--escolaridade {
    grid-column: span 4;
  }
  #pb-franchise-form[data-step="03"] #form form .form--experiencia {
    grid-column: span 7;
  }
  #pb-franchise-form[data-step="03"] #form form .form--sociedade {
    grid-column: span 5;
  }
  #pb-franchise-form[data-step="03"] #form form .form--sociedade .form--title {
    display: block;
    margin-bottom: 10px;
  }
  #pb-franchise-form[data-step="03"] #form form .form-submit {
    grid-column: span 5;
  }
  #pb-franchise-form[data-step="04"] #form form {
    grid-template-columns: repeat(2,1fr);
  }
  #pb-franchise-form[data-step="04"] #form form .form--capital {
  }
  #pb-franchise-form[data-step="04"] #form form .form--financiamento {
  }#pb-franchise-form[data-step="04"] #form form .form--financiamento .form--title {
    display: block;
    margin-bottom: 0px;
  }
  #pb-franchise-form[data-step="04"] #form form .form--financiamento .form-check {
    padding-top: 10px;
  }
  #pb-franchise-form[data-step="04"] #form form .form--financiamento .form--financiamento-valor {
    display: inline-flex;
    margin-top: 0px;
    position: absolute;
  }
  #pb-franchise-form[data-step="04"] #form form .form--renda {
  }
  #pb-franchise-form[data-step="04"] #form form .form--atividades {
  }
  #pb-franchise-form[data-step="04"] #form form .form--tempo {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="04"] #form form .form--tempo .form--title {
    display: block;
    margin-bottom: 10px;
  }
  #pb-franchise-form[data-step="04"] #form form .form--interesse {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="04"] #form form .form--interesse .form--title {
    display: block;
    margin-bottom: 10px;
  }
  #pb-franchise-form[data-step="04"] #form form .form-submit {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="05"] #form form {
    grid-template-columns: repeat(2,1fr);
  }
  #pb-franchise-form[data-step="05"] #form form .form--faturamento {
  }
  #pb-franchise-form[data-step="05"] #form form .form--lucro {
  }
  #pb-franchise-form[data-step="05"] #form form .form--espera {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="05"] #form form .form--comentarios {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="05"] #form form .form--optin {
    grid-column: span 2;
  }
  #pb-franchise-form[data-step="05"] #form form .form--optin .form-check {
    margin: 0 0 15px;
  }
  #pb-franchise-form[data-step="05"] #form form .form--optin .form-check label {
    font-size: .875rem;
    font-weight: 700;
    color: var(--color-main);
    margin-top: 2px;
    margin-left: 6px;
  }
  #pb-franchise-form[data-step="05"] #form form .form--optin .form-check label a {
    text-decoration: underline;
    font-size: .875rem;
    font-weight: 700;
    color: var(--color-main);
  }
  #pb-franchise-form[data-step="05"] #form form .form-submit {
    grid-column: span 2;
  }
  @media (max-width: 992px) {
    #pb-franchise-form #content p {
      margin-bottom: 30px;
    }
    #pb-franchise-form .subtitle {
      display: none;
    }
    #pb-franchise-form #step > ul li {
      display: none;
    }
    #pb-franchise-form #step > ul li.active {
      width: 100%;
      display: block;
    }
    #pb-franchise-form #step > ul li.active div .title {
      max-width: 100%;
    }
    #pb-franchise-form #form form {
      grid-template-columns: 100% !important;
    }
    #pb-franchise-form #form form > [class^="form"] {
      grid-column: 1 !important;
    }
    #pb-franchise-form #form form .form-submit {
      max-width: 100%;
      margin-top: 0px;
    }
    #pb-franchise-form #form form .btn-lightest-v2 {
      width: 36px;
      padding: 0;
      color: transparent;
      overflow: hidden;
      position: relative;
    }
    #pb-franchise-form #form form .btn-lightest-v2:after {
      width: 8px;
      height: 8px;
      display: block;
      content: "";
      position: absolute;
      top: 16px;
      left: 14px;
      border-right:  2px solid var(--color-main);
      border-bottom:  2px solid var(--color-main);
      transform: rotate(135deg);
    }
  }
  #pb-franchise-form #feedback {
    padding-top: 46px;
  }
  #pb-franchise-form #feedback .header--container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  #pb-franchise-form #feedback {
    padding-top: 46px;
  }
  #pb-franchise-form #feedback .header--container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
  }
#pb-franchise-form #feedback .header--container > img {
  margin: 0 10px 0 0;
}
#pb-franchise-form #feedback .header--container > span {
  margin-top: 5px;
      font-size: 1.875rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
    line-height: 1.2;
}
#pb-franchise-form #feedback .content p {
  font-size: 1.7rem;
    font-weight: 300;
    line-height: 1.2;
}
#pb-franchise-form #feedback .btn {
  margin-top: 20px;
}
#pb-franchise-form[data-step="success"] #step > ul li {
  opacity: 0.35;
}
#pb-franchise-form[data-step="error"] #step > ul li div,
#pb-franchise-form[data-step="error"] #step > ul li a {
  background: #daebf3;
  color: #daebf3;
  text-decoration: none;
}
#pb-franchise-form[data-step="error"] #step > ul li.error div {
  background: #949ca1;
  color: #949ca1;
  text-shadow: 1px 0 0 var(--color-white), -1px 0 0 var(--color-white), 0 1px 0 var(--color-white), 0 -1px 0 var(--color-white), 1px 1px var(--color-white), -1px -1px 0 var(--color-white), 1px -1px 0 var(--color-white), -1px 1px 0 var(--color-white);
}
#pb-franchise-form #feedback .content ul {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 240px;
  padding: 0;
}
#pb-franchise-form #feedback .content ul li {
  margin: 0 60px 20px 0;
}
#pb-franchise-form #feedback .content ul li > a {
  font-size: 0.875rem;
  color: var(--color-darkest);
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  text-decoration: none !important;
}
#pb-franchise-form #feedback .content ul li > a:after {
  width: 100%;
  height: 3px;
  display: block;
  content: "";
  background: #949ca1;
}
@media(max-width: 992px) {  
  #pb-franchise-form #feedback .content ul {
    margin: 0 0 120px;
  }
}