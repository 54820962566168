[class^="pb-content-"],
[class^="pb-content-"] * {
    --image-cols: 5;
    --image-offset: 1;
    --infobox-spacer: -42px;
    box-sizing: border-box;
}
/* Gerais */
[class^="pb-content-"] h2,
[class^="pb-content-"] .title {
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
    line-height: 1.2;
}
[class^="pb-content-"] .subtitle {
    margin: 1rem 0 0;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.875rem;
    color: var(--color-darkest);
}
[class^="pb-content-"] .header {
    margin: 0 0 1.5rem;
}
[class^="pb-content-"] .content p {
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-darkest);
}
@media (max-width: 992px) {    
    [class^="pb-content-"] h2,
    [class^="pb-content-"] .title {
        font-size: 1.125rem;
    }
    [class^="pb-content-"] .subtitle {
        font-size: 1.125rem;
    }
}
/* Conteúdo Conceito Inicial */
.pb-content--concept {
    overflow-x: hidden;
}
.pb-content--concept .highlight {
    width: 100%;
    max-width: 100%;
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0 0 1rem;
}
.pb-content--concept .image {
    position: relative;
}
.pb-content--concept p {
    max-width: 80%;
}
.pb-content--concept .subtitle {
    display: block;
    margin: 38px 0; 
    color: var(--color-darker);
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
}
.pb-content--concept ul.links {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 1fr;
    column-gap: var(--container-grid-spacer);
    row-gap: 20px;
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.pb-content--concept ul.links li a {
    font-size: 0.875rem;
    color: var(--color-light);
}
.pb-content--concept .infobox {
    width: calc(        
        (
            (100vw - var(--max-container)) / 2
        ) +
        (
            (var(--max-container) - (var(--container-grid-spacer) * (var(--container-grid-cols) - 1)))
            /var(--container-grid-cols) * var(--image-cols) +
            (var(--container-grid-spacer) * (var(--image-cols) - 1))
        )
    );
    bottom: 10px;
    position: relative;
    margin-top: var(--infobox-spacer);
    margin-left: calc(
        (
            (var(--max-container) - (var(--container-grid-spacer) * (var(--container-grid-cols) - 1)))
            /var(--container-grid-cols) * var(--image-offset) +
            (var(--container-grid-spacer) * var(--image-offset))
        )
    );
    padding: 54px 0;
    padding-left: calc(
        (
            (var(--max-container) - (var(--container-grid-spacer) * (var(--container-grid-cols) - 1)))
            /var(--container-grid-cols)
        )
    );
    z-index: 2;
    background: var(--color-lightest);
}
.pb-content--concept .infobox .content {
    --temp-value: (var(--image-cols) - var(--image-offset) - 2);
    width: calc(
        (
            (var(--max-container) - (var(--container-grid-spacer) * (var(--container-grid-cols) - 1)))
            /var(--container-grid-cols) * var(--temp-value) +
            (var(--container-grid-spacer) * (var(--image-cols) - var(--image-offset)))
        )
    );
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
.pb-content--concept .infobox .content p {
    font-size: 1.75rem;
    line-height: 2rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
.pb-content--concept .infobox .content > a.btn-more {
    margin: 44px 0 0;
    border: 1px solid var(--color-main);
}
@media (max-width: 1120px) {
    .pb-content--concept .infobox {
        padding-left: calc(
            (
                (var(--max-container) - (var(--container-grid-spacer) * (var(--container-grid-cols) - 1)))
                /var(--container-grid-cols)
            )/2
        );
    }
}
@media (max-width: 992px) {
    .pb-content--concept ul.links {
        grid-template-columns: 1fr;
    }
    .pb-content--concept .image {
        width: 100%;
        margin: 40px -40px; 
    }
    .pb-content--concept .image img {
        width: calc(100% + 80px);
    }
    .pb-content--concept .infobox {
        width: calc(100% + 80px);
        margin: 0;
        padding: 40px 28px;
    }
    .pb-content--concept .infobox .content,
    .pb-content--concept .infobox .content p {
        width: 100%;
        max-width: 100%;
        font-size: 1.175rem;
    }
    .pb-content--concept .infobox .content > a.btn-more {
        width: 100%;
        max-width: 100%;
        margin: 24px 0 0;
    }
}

/* Conteúdo Simples com Mídia em Duas Colunas*/
.pb-content--media .subtitle {
    text-transform: uppercase;
}
.pb-content--media .image img {
    width: 100%;
}
@media (max-width: 992px) {
    .pb-content--media .image {
        margin: 24px -28px 0;
    }
}

/* Conteúdo Centralizado */
.pb-content--singlecentered,
.pb-content--singlecentered * {
    --image-spacer: 50px;
    --title-spacer: 30px; 
    --subtitle-spacer: 20px; 
    --more-spacer: 68px;
}
.pb-content--singlecentered .image {
    margin-bottom: var(--image-spacer);
}
.pb-content--singlecentered .image img {
    width: 100%;
}
.pb-content--singlecentered .header h2,
.pb-content--singlecentered .header .subtitle {
    display: block;
    margin-bottom: var(--title-spacer);
    text-align: center;
}
.pb-content--singlecentered .header .subtitle {
    margin-bottom: var(--subtitle-spacer);
    text-align: center;
}
.pb-content--singlecentered .content {
    text-align: center;
}
.pb-content--singlecentered .more {
    text-align: center;
    margin-top: var(--more-spacer);
}
.pb-content--singlecentered .more .btn-more {
    width: 100%;
    max-width: 100%;
}

/* Duas Colunas */
.pb-content--cols,
.pb-content--cols * {
    --image-spacer: 60px;
    --title-spacer: 30px; 
    --subtitle-spacer: 20px; 
    --more-spacer: 48px;
}
.pb-content--cols .image {
    width: 100%;
    margin-bottom: var(--image-spacer);
}
.pb-content--cols .image img,
.pb-content--cols .image-mosaic img {
    width: 100%;
}
.pb-content--cols .image-mosaic {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--container-grid-spacer);
    margin-bottom: var(--image-spacer);
}
.pb-content--cols .header h2,
.pb-content--cols .header .subtitle {
    display: block;
    margin-bottom: var(--title-spacer);
}
.pb-content--cols .header .subtitle {
    margin-bottom: var(--subtitle-spacer);
}
.pb-content--cols .more {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: var(--more-spacer);
}
@media (max-width: 992px) {
    .pb-content--cols .order-3 {
        margin-top: 180px;
    }
}

/* Destaque em Caixa com Imagem de Fundo */
.pb-content--highlight .image {
    width: 57vw;
    height: 564px;
    position: absolute;
    top: var(--mainmenu-height);
    right: 0px;
    z-index: 1;
}
.pb-content--highlight .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}
.pb-content--highlight .content-box {
    margin: 384px 0 0;
    padding: 30px 36px 30px 0;
    position: relative;
    background: var(--color-white);
    z-index: 2;
}
.pb-content--highlight .content-box .title {
    display: block;
    margin: 0 0 28px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.8rem;
    color: var(--color-darker-alt);
    text-transform: none;;
}
.pb-content--highlight .content-box p {
    font-weight: 300;
}
.pb-content--highlight .content-box .more {
    width: 80%;
    margin: 34px 0 60px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: var(--container-grid-spacer);
}
.pb-content--highlight .content-box .more .btn {
    width: 100%;
}
.pb-content--highlight .content-box .call {
    display: block;
    font-size: 1.125rem;
    font-weight: 300;
}
.pb-content--highlight .content-box:before {
    width: 12px;
    height: 180px;
    display: block;
    position: absolute;
    content: "";
    top: 140px;
    left: -60px;
    background: url("/template/images/icon_arrow.svg") top center no-repeat;
    background-size: contain;
}
@media(max-width: 1200px) {
    .pb-content--highlight .content-box .more {
        width: 100%;
    }
}
@media(max-width: 992px) {
    .pb-content--highlight .content-box {
        margin: 0;
        padding: 0;
    }
    .pb-content--highlight .content-box .more {
        display: none;
    }
    .pb-content--highlight .image {
        display: none;
    }
    .pb-content--highlight .content-box:before {
        display: none;
    }
}

/* Conteúdo de Introdução Institucional */
.pb-content--intro,
.pb-content--intro * {
    --highlight-spacer: 100px 30px 32px 0;
    --header-spacer: 86px;
    --box-spacer: 70px 20% 0 0;
}
.pb-content--intro {
    position: relative;
    padding: calc(var(--mainmenu-height) * 0.6) 0 0;
}
.pb-content--intro .subtitle {
    display: block;
    margin: 0 0 18px;
    font-size: 0.75rem;
    font-weight: 300;
}
.pb-content--intro header {
    display: block;
    position: relative;
    margin: 0 0 var(--header-spacer);
}
.pb-content--intro header h1:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -34px;
    left: 0;
    background: var(--color-main);
}
.pb-content--intro .links {
    padding: 0;
    margin: 0;
    list-style: none;
}
.pb-content--intro .links li {
    margin: 0 0 20px;
}
.pb-content--intro .links a {
    font-size: 0.875rem;
    color: var(--color-light);
}
.pb-content--intro p.highlight {
    font-size: 1.5rem;
    color: var(--color-darker-alt);
    font-weight: 300;
    margin: var(--highlight-spacer);
    line-height: 1.2;
}
.pb-content--intro .content-box {
    padding: var(--box-spacer);
    background: var(--color-white);
    position: relative;
    z-index: 2;
}
.pb-content--intro .content-box {
    font-size: 1.125rem;
    color: var(--color-darker-alt);
    font-weight: 300;
    min-height: 280px;
}
.pb-content--intro .content-box p {
    line-height: 1.4;
}
.pb-content--intro .image {
    width: 62vw;
    height: 688px;
    position: absolute;
    top: var(--mainmenu-height);
    right: 0px;
    z-index: 1;
}
.pb-content--intro .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
@media(max-width: 992px) {
    .pb-content--intro,
    .pb-content--intro * {
        --highlight-spacer: 100px 0 40px;
        --header-spacer: 0px;
        --box-spacer: 0;
    }
    .pb-content--intro header h1 {
        font-size: 1.125rem;
    }    
    .pb-content--intro header h1:after {
        display: none;
    }
    .pb-content--intro .content-box p {
        font-size: 1rem;
    }
}
/* Listagem de Conceitos em duas colunas */
.pb-content--concept-list,
.pb-content--concept-list * {
    --box-spacer: 83px 0 108px;
    --item-spacer: 48px;
}
.pb-content--concept-list {
    padding: var(--box-spacer);
    background: var(--color-lightest);
}
.pb-content--concept-list .pb-item {
    margin: 0 0 var(--item-spacer);
}
.pb-content--concept-list .pb-item .title {
    margin: 0 0 50px;
    display: block;
    position: relative;
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--color-darker-alt);
    text-transform: none;
}
.pb-content--concept-list .pb-item .title:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0;
    background: var(--color-main);
    
}
@media (max-width: 992px) {    
    .pb-content--concept-list,
    .pb-content--concept-list * {
        --box-spacer: 56px 0 40px;
    }
}
/* Listagem de Recursos */
.pb-content--about-resources,
.pb-content--about-resources * {
    --call-spacer: 0 0 112px;
}
.pb-content--about-resources .call {
    display: block;
    margin: var(--call-spacer);
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    color: var(--color-darker-alt);
}
.pb-content--about-resources .pb-item .image {
    width: 84px;
    height: 84px;
    margin: 0 auto 36px;
}
.pb-content--about-resources .pb-item .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.pb-content--about-resources .pb-item .title {
    display: block;
    margin: 0 auto 36px;
    text-align: center;
}
.pb-content--about-resources .pb-item .content * {
    font-size: 0.875rem;
}
/* Estatísticas */
.pb-content--about-stats,
.pb-content--about-stats * {

}
.pb-content--about-stats .image {
    width: 100%;
    height: 433px;
}
.pb-content--about-stats .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.pb-content--about-stats .titlebox {
    width: 100%;
    margin: -30px 0 0;
    padding: 38px 0;
    background: var(--color-white);
    text-align: center;
}
.pb-content--about-stats .titlebox h2 {
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: none;
    text-align: center;
}
.pb-content--about-stats .custom-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    margin: 0 auto;
}
.pb-content--about-stats .custom-grid .pb-item {
    width: 188px;
    height: auto;
}
.pb-content--about-stats .custom-grid .pb-item .stat-container {
    width: 188px;
    height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 0 50px;
}
.pb-content--about-stats .custom-grid .pb-item .stat-container > span {  
    display: block;  
    position: relative;
    font-size: 2rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
    z-index: 2;
    background: var(--color-white);
}
.pb-content--about-stats .custom-grid .pb-item .stat-container:after {
    width: 164px;
    height: 164px;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    border-radius: 50%;
    border: 2px solid var(--color-light);
}
.pb-content--about-stats .custom-grid .pb-item .stat-content {
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;
}
@media (max-width: 992px) {
    .pb-content--about-stats .titlebox {
        margin: 0px;
    }
    .pb-content--about-stats .custom-grid {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pb-content--about-stats .custom-grid .pb-item {
        margin: 0 0 50px;
    }

}
/* Conteúdo Conceitual o Officina */
.pb-content--officina-concept,
.pb-content--officina-concept * {
    --note-spacer: 18px;
    --bottom-spacer: 105px;
}
.pb-content--officina-concept .header {
    margin-bottom: var(--bottom-spacer);
}
.pb-content--officina-concept .header .note {
    display: block;
    margin: 0 0 var(--note-spacer);
}
.pb-content--officina-concept .header h2:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: -36px;
    left: 16px;
    background: var(--color-main);
}
.pb-content--officina-concept .subtitle {
    display: block;
    margin-bottom: 30px;
}
.pb-content--officina-concept .concept-image {
    width: 100%;
}
.pb-content--officina-concept p {
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-dark-alt);
}

/* Conteúdo Prêt-à-Porter no Officina */
.pb-content--officina-pretaporter,
.pb-content--officina-pretaporter * {

}
.pb-content--officina-pretaporter img {
    width: 100%;
}
.pb-content--officina-pretaporter .title {
    display: block;
    text-transform: none;
    margin: 0 0 14px;
}
.pb-content--officina-pretaporter .subtitle {
    text-transform: none;
    margin: 0 0 38px;
}
.pb-content--officina-pretaporter  p {
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-dark-alt);
}
.pb-content--officina-pretaporter  a.btn {
    margin-top: 48px;
}
@media(max-width: 992px) {
    .pb-content--officina-pretaporter img {
        margin-bottom: 22px;
    }
    .pb-content--officina-pretaporter  a.btn {
        margin-top: 30px;
    }
    .pb-content--officina-pretaporter .title,
    .pb-content--officina-pretaporter .subtitle {
        font-size: 1.5rem;
    }
}
/* Informações Produtos Portobello (Construtoras e Internacional) */
.pb-content--concept-officina,
.pb-content--concept-officina * {
    box-sizing: border-box;
}
.pb-content--concept-officina h3 {
    font-size: 1.72rem;
    font-weight: 700;
    text-align: center;
    margin: 120px 0 45px;
}
.pb-content--concept-officina img {
    width: 100%;
}
.pb-content--concept-officina p {
    font-size: 1.72rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.2;
    margin: 60px 0 45px;
}