.pb-favorites,
.pb-favorites * {
    --return-spacer: 82px;
    --form-spacer: 45px;
    --map-height: 382px;
    --map-spacer: 70px;
    box-sizing: border-box;
}
/* Layout de retorno NÃO LOGADO e LOGADO + NULO */
.pb-favorites .pb-favorites-container  .header--null {
    margin: 0px !important;
    padding: 60px 0 30px;
    text-align: center;
}
.pb-favorites .pb-favorites-container  .header--null h2 {
    max-width: 500px;
    margin: 0 auto 28px !important;
}
.pb-favorites .pb-favorites-container .header--null img {
    top: auto;
    left: auto;
    position: relative;
    text-align: center;
    margin: 0 auto 30px;;
}
.pb-favorites .pb-favorites-container  .header--null .btn-darkest {
    padding: 16px 46px;
    line-height: 1.2em;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: white;
}
.pb-favorites .pb-favorites-container .content--null p {
    display: block;
    max-width: 400px;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    margin: 0 auto;
}
.pb-favorites .pb-favorites-container .content--null a {
    color: var(--color-main);
    font-weight: 700;
    font-size: 12px;
}
.pb-favorites .pb-favorites-container  .header--null a {
    font-size: 1rem;
    font-weight: 700;
    color: var(--color-light);
    border-bottom: 2px solid var(--color-lightest);
    text-decoration: none !important;
}