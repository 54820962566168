#page-careers,
#page-careers * {
    --subtitle-spacer: 23px;
    --links-spacer: 28px;
    --section-spacer: 77px;
    --tweak-spacer: -100px;
}
#page-careers h3 {
    margin: 0 0 var(--subtitle-spacer);
}
#page-careers .links {
    display: block;
}
#page-careers .links li {
    margin-top: var(--links-spacer);
}
#page-careers .links li a {
    font-weight: 700;
    color: var(--color-main);
}
#page-careers section {    
    margin: 0 0 var(--section-spacer);
}
#page-careers .infobox p.default {
    margin-top: 40px;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: none;
    font-weight: 300;
}
#page-careers .content-tweaked {
    margin-top: var(--tweak-spacer);
}
#page-careers section.mb-9{
    margin-bottom: 12rem !important;
}
@media (max-width: 992px) {
    #page-careers,
    #page-careers * {
        --tweak-spacer: 60px;
    }
}