.pb-pattern,
.pb-pattern *,
.pb-pattern--container,
.pb-pattern--container * {
    --container-width: 100%; /* Largura do Container */
    --container-height: 100%; /* Altura do Container */
    --cross-left: 15%; /* Percentual para transpasse da esquerda */
    --cross-right: 15%; /* Percentual para transpasse da direita */
    --cross-center: 50%; /* Percentual de tranmspasse no centro */
    --gap-spacer: 2px; /*Espaçamento / Rejunte */
    --gap-color: #fff; /*Cor do espaçamento / Rejunte */
    --product-width: 120; /* Comprimento da Peça */
    --product-height: 20; /* Altura da Peça */
    box-sizing: border-box;
}
.pb-pattern--container {
    width: var(--container-width);
    height: var(--container-height);
}
.pb-pattern--limiter { 
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.pb-pattern {
    width: 100%;
    height: auto;
    display: grid;
    gap: var(--gap-spacer);
    grid-template-columns: var(--cross-left) 1fr var(--cross-right);
    grid-template-rows: auto;
    background: var(--gap-color);
}
.pb-pattern.title {    
    grid-template-columns: repeat(4,1fr);
}
.pb-pattern.relevo {    
    grid-template-columns: var(--cross-left) 1fr var(--cross-right);
}
.pb-pattern .pb-block {
    width: 100%;
    position: relative;
}
.pb-pattern.relevo .pb-block-1,
.pb-pattern.wood .pb-block-1 {
    height: 100%;
}
.pb-pattern.relevo .pb-block-2,
.pb-pattern.wood .pb-block-2 {
    height: 0px;
    padding-bottom: calc(var(--product-height)/var(--product-width) * 100%);
    grid-column: span 2;
}
.pb-pattern.relevo .pb-block-3,
.pb-pattern.wood .pb-block-3 {
    height: 0px;
    padding-bottom: calc(var(--product-height)/var(--product-width) * 100%);
    grid-column: span 2;
}
.pb-pattern.relevo .pb-block-4,
.pb-pattern.wood .pb-block-4 {
    height: 100%;
} 
.pb-pattern.title .pb-block-2,
.pb-pattern.title .pb-block-4,
.pb-pattern.title .pb-block-5 {
    height: 0px;
    padding-bottom: calc(var(--product-height)/var(--product-width) * 100%);
    grid-column: span 2;
}
.pb-pattern.title .pb-block-1,
.pb-pattern.title .pb-block-3 {
    height: 100%;
    padding-bottom: calc(var(--product-height)/var(--product-width) * 100%);
}
.pb-pattern.relevo .pb-block-2, 
.pb-pattern.relevo .pb-block-4, 
.pb-pattern.relevo .pb-block-5
{
    grid-column: initial;
}

.pb-pattern .pb-block img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top left;  
}
.pb-pattern.title .pb-block-1 img,
.pb-pattern.relevo .pb-block-1 img,
.pb-pattern.wood .pb-block-1 img {
    object-position: top right;
}
.pb-pattern.title .pb-block-3 img {
    object-position: top left;
}

.pb-pattern--container[data-code="201699E"] .pb-pattern.relevo,
.pb-pattern--container[data-code="201700E"] .pb-pattern.relevo,
.pb-pattern--container[data-code="201696E"] .pb-pattern.relevo,
.pb-pattern--container[data-code="201465E"] .pb-pattern.relevo,
.pb-pattern--container[data-code="201466E"] .pb-pattern.relevo,
.pb-pattern--container[data-code="201467E"] .pb-pattern.relevo
{
    --gap-spacer: 0px !important;   
}