.btn-light {
    background: var(--color-lightest);
    padding: 8px 20px;
    border: none !important;
  }
  .pb-helpcenter .header--container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pb-helpcenter .header--container .btn-light {
    min-width: 304px;
  }
  .pb-helpcenter .header--container header {
  }
  .pb-helpcenter .header--container header h1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .pb-helpcenter .header--container header p.content {
    margin-top: 30px;
  }
  .pb-helpcenter .header--container header h1 .main {
    margin-top: 16px;
    order: 2;
  }
  .pb-helpcenter .header--container header h1 .subtitle {
    font-size: 12px;
    font-weight: 300;
    text-transform: none;
    order: 1;
  }
  @media (max-width: 992px) {
    .pb-helpcenter .header--container {
      display: block;
    }
    .pb-helpcenter .header--container .btn-light {
      margin-top: 20px;
    }
  }
  #pb-helcenter--search-container .search-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 20px 23px 40px;
    border: 1px solid #073960;
  }
  #pb-helcenter--search-container .search-field > input {
    flex-grow: 1;
    border: none;
    font-size: 21px;
    color: var(--color-light);
    font-weight: 400;
  }
  #pb-helcenter--search-container ::placeholder {
    font-size: 21px;
    color: var(--color-light);
    font-weight: 400;
  }
  #pb-helcenter--search-container .search-field > button {
    border: none;
    background: none;
  }
  #pb-helpcenter--list-container {
    margin: 30px 0 0;
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic {
    margin: 0 0 30px;
    padding: 23px 40px;
    background: var(--color-white);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16)
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .title {
    width: 100%;
    display: block;
    position: relative;
    font-size: 21px;
    color: var(--color-light);
    font-weight: 400;
    text-decoration: none !important;
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .title:after {
    width: 8px;
    height: 8px;
    display: block;
    content: "";
    border-right: 2px solid var(--color-light);
    border-bottom: 2px solid var(--color-light);
    position: absolute;
    right: 0px;
    top: 3px;
    transform: rotate(45deg);
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .title[aria-expanded="true"] span {
    color: var(--color-darkest);
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .title[aria-expanded="true"]:after {
    transform: rotate(-135deg);
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .card.card-body {
    margin: 12px 0 0;
    padding: 0;
    border: none;
  }
  #pb-helpcenter--list-container .pb-helpcenter--topic .card.card-body > * {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  #pb-helpcenter--more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 50px;
  }
  #pb-helpcenter--more .btn {
    width: 100%;
    padding: 14px 20px;
    max-width: 386px;
  }