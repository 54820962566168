#toast-container {
    position: fixed;
    right: 20px;
    top: 100px;
    z-index: var(--z-lightbox);
}

@media (max-width: 769px) {
    #toast-container {
        left: 20px;
    }
}

.pb-notification,
.pb-notification * {
    --color-gray-light-v2: #e3e3e3;
    --color-gray: #949CA1;
    --notification-bg: var(--color-gray-light-v2);
    --notification-color: var(--color-gray);
    --notificationtimeout: 5s;
}

.pb-notification[data-timeout="5s"],
.pb-notification[data-timeout="5s"] *,
.pb-notification[data-timeout="default"],
.pb-notification[data-timeout="default"] * {
    --notificationtimeout: 5s;
}

.pb-notification[data-timeout="8s"],
.pb-notification[data-timeout="8s"] * {
    --notificationtimeout: 8s;
}

.pb-notification[data-timeout="10s"],
.pb-notification[data-timeout="10s"] * {
    --notificationtimeout: 10s;
}

.pb-notification[data-timeout="15s"],
.pb-notification[data-timeout="15s"] * {
    --notificationtimeout: 15s;
}

.pb-notification[data-status="warning"],
.pb-notification[data-status="warning"] *,
.pb-notification[data-status="alert"],
.pb-notification[data-status="alert"] * {
    --color-alert-light: #fffaeb;
    --color-alert: #f8bb13;
    --notification-bg: var(--color-alert-light);
    --notification-color: var(--color-alert);
}

.pb-notification[data-status="error"],
.pb-notification[data-status="error"] * {
    --color-error: #dc3545;
    --color-error-light: #ffd8de;
    --notification-bg: var(--color-error-light);
    --notification-color: var(--color-error);
}

.pb-notification[data-status="success"],
.pb-notification[data-status="success"] * {
    --color-success: #5ca694;
    --color-success-light: #def2ed;
    --notification-bg: var(--color-success-light);
    --notification-color: var(--color-success);
}

.pb-notification .notification-container {
    --border-radius: 15px;
    --shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 402px;
    display: grid;
    grid-template-columns: 26px 1fr auto;
    grid-template-areas: "icon content action";
    gap: 10px;
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--shadow);
    box-shadow: var(--shadow);
    padding: 14px 10px;
    background: var(--notification-bg);
    margin-bottom: 10px;
    position: relative;
}

.pb-notification[data-timeout]:not([data-timeout=""]) .notification-container {
    /* animation-delay: var(--notificationtimeout); */
    animation-name: notification;
    /* animation-duration: 0.25s; */
    opacity: 1;
    visibility: visible;
    /* animation-fill-mode: forwards; */
}

.pb-notification[data-timeout]:not([data-timeout=""]) .notification-container:after,
.pb-notification[data-timeout]:not([data-timeout=""]) .notification-container:before {
    display: block;
}

.pb-notification .notification-container:before {
    --color-white: #ffffff;
    width: calc(100% - 40px);
    height: 2px;
    display: none;
    content: "";
    border-radius: 1px;
    background: var(--color-white);
    position: absolute;
    bottom: 5px;
    left: 20px;
    z-index: 5;
}

.pb-notification .notification-container:after {
    width: 0%;
    height: 2px;
    display: none;
    content: "";
    border-radius: 1px;
    background: var(--notification-color);
    position: absolute;
    bottom: 5px;
    left: 20px;
    z-index: 6;
}

.pb-notification[data-timeout]:not([data-timeout=""]) .notification-container:after {
    animation-name: notificationbar;
    animation-duration: var(--notificationtimeout);
}

.pb-notification .notification-container .icon {
    grid-area: icon;
    background-color: var(--notification-bg) !important;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pb-notification .notification-container .action {
    background-color: var(--notification-bg) !important;
    grid-area: action;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pb-notification .notification-container .content {
    /*align-self: center;*/
    align-self: left;
    /*display: flex;
    justify-content: flex-start;
    flex-direction: column;*/
    align-items: left;
    /*align-items: center;*/
    font-size: rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--color-darkest);
}

.pb-notification .notification-container .content .title {
    font-weight: 700;
    font-size: 1.125rem;
}

.pb-notification .notification-container .content .total {
    display: none;
    /* position: absolute;*/
    right: 52px;
    background-color: white;
    padding: 2px 7px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
}

.pb-notification .notification-container .content .message {
    display: block;
}

@keyframes notificationbar {
    0% {
        width: 0%;
    }

    100% {
        width: calc(100% - 40px);
    }
}

.pb-btn-close {
    border:none;
    background-color: var(--notification-bg) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.pb-btn-close span,
.pb-btn-close span i {
    --color-gray-dark: #707070;
    background-color: var(--notification-bg) !important;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--color-gray-dark);
    text-decoration: none;
}

@media (max-width: 769px) {
    .pb-btn-close span i {
        display: none;
    }

    .pb-btn>.desktop-only {
        display: none;
    }

    .pb-btn>.mobile-only {
        display: inline;
    }
}

.pb-btn-close .icon {
    background-color: var(--notification-bg) !important;
    width: 20px;
    height: 20px;
    display: block;
    position: relative !important;
    top: auto !important;
    right: auto !important;
}

.pb-btn-close .icon:before,
.pb-btn-close .icon:after {
    --color-secondary: #828FA5;
    width: 26px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    top: 9px;
    left: -3px;
    background: var(--color-secondary);
}

.pb-btn-close .icon:before {
    transform: rotate(45deg);
}

.pb-btn-close .icon:after {
    transform: rotate(-45deg);
}

.pb-btn-back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    gap: 10px;
}

.pb-btn-back img {
    transform: rotate(180deg);
}

.pb-btn-back span,
.pb-btn-close span i {
    --color-gray-dark: #707070;
    font-size: 0.75rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--color-gray-dark);
}

.arch-btn.alert,
.arch-btn.alert * {
    --btn-color: var(--color-error);
    --btn-bg: var(--color-error-light);
    --btn-color-hover: var(--color-error);
    --btn-bg-hover: var(--color-error-light);
    --btn-color-border: var(--color-error-light);
    --btn-color-border-hover: var(--color-error-light);
    --btn-size-border: 2px;
}