#help-pdv-header {
    width: 100vw;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0px;
    border-bottom: 1px solid #e6eaf2;
    background: #fff;
    z-index: 5000;
}
.pb-pageheader {
    margin-bottom: 50px;
}
#page-helpfaq
{
    margin-bottom: 80px;
}

#page-helpfaq .faq {
    min-height: 60px;
    margin: 30px 0;
}
#page-helpfaq .faq-search{
    border: 1px solid #849DB0;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 20px 40px;
}
#page-helpfaq .search{
    width: 95%;
}
#page-helpfaq .faq-search input{
   width: 100%;
   border: none;
   color: #000;
   height: auto;
   font-size: 18px;
}
#page-helpfaq .faq-search input:focus{
    color: #000;
    font-weight: 500;
}
#page-helpfaq .faq-search input::-webkit-input-placeholder {
    font-size: 15px;
    color: var(--color-light);
}
#page-helpfaq .faq-group question {
    color: var(--color-light);
    line-height: 1.5rem;
}
#page-helpfaq .faq-question {
    box-shadow: #e1e5ed 0px 2px 8px 0px;
    padding-bottom: 10px;
}
#page-helpfaq .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}
#page-helpfaq .collapsible {
    background: #fff;
    color: var(--color-light);
    cursor: pointer;        
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border: none;
    outline: none;
    height: auto;
    padding: 20px 40px;
    font-size: 18px;
}
#page-helpfaq .active {
    color: black;
    font-weight: 400;
}
#page-helpfaq .collapsible:after {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-right: 2px solid var(--color-light);
    border-bottom: 2px solid var(--color-light);
    transform: rotate(45deg);
    margin-top: -2px 20px 0;
}
#page-helpfaq .collapsible.active:after {
    transform: rotate(-135deg);
}

#page-helpfaq .content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    font-size: 14px;
    padding: 0 40px;
}
#page-helpfaq .content p {
    margin: 0 0 20px;
}
#page-helpfaq .loadMore {
    padding: 16px;
    line-height: 1.2em;
    letter-spacing: 0.4px;
    text-transform: uppercase;
}
#page-helpfaq .result-info, 
#page-helpfaq .noResult,
#page-helpfaq .hasdoubt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#page-helpfaq .hasdoubt {
    font-size: 0.8rem;
    color: var(--color-light);
    line-height: 1.5rem;
    margin-top: 60px;        
}
#page-helpfaq .hasdoubt a {
    color: var(--color-light);
}
#page-helpfaq .animation {
    display: flex; 
    justify-content: center;
}
.pb-pageheader .header h1:after
{
    display: none !important;
}
@media (max-width: 992px) {        
    .pb-pageheader
    {
        margin-top: 80px;
        margin-bottom: 0px;
    }
    .pb-pageheader .header h1
    {
        font-size: 2rem !important;
    }
    #page-helpfaq .hasdoubt {
        margin-top: 20px;
    }
    #page-helpfaq .faq-search{
        border: 1px solid var(--color-darkest);
    }
    #page-helpfaq .collapsible {
        padding: 30px 20px;
        font-size: 1rem;
        font-weight: 700;
        color: #000;
    }
    #page-helpfaq .content {
        padding: 0px 20px 0px
    }
    #page-helpfaq .content p {
        font-size: 1rem;
    }
}