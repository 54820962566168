* {
    font-family: 'Roboto', sans-serif;
    line-height: 1.2;
}
a {
    font-size: inherit;
    text-decoration: underline;
    color: var(--clr-link-400);
}
.title {
    font-size: 2.35rem;
    font-weight: 700;
}
.subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;
}
.title.large { 
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 3.5rem;
    text-transform: initial;
}
h1 { 
    margin: 0 0 1.5rem;
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-transform: initial;
}
h2 { 
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 2.67rem;
    text-transform: initial;
}
h3 { 
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.83rem;
    text-transform: uppercase;
}
h4 { 
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.83rem;
    text-transform: uppercase;
}
h5 { 
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.83rem;
    text-transform: uppercase;
}
p.large { 
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.5rem;
    text-transform: initial;
}
p { 
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;
    text-transform: initial;
}
p.small { 
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.125rem;
    text-transform: initial;
}
b, bold, strong {
    font-weight: 700 !important;
}
@media (max-width: 1179px) {
    .title.large { 
        font-size: 2.625rem;
        font-weight: 300;
        line-height: 3.83rem;
        text-transform: initial;
    }
    h1 { 
        font-size: 1.875rem;
        font-weight: 300;
        line-height: 2.83rem;
        text-transform: initial;
    }
    h2 { 
        font-size: 1.75rem;
        font-weight: 300;
        line-height: 2.67rem;
        text-transform: initial;
    }
    h3 { 
        font-size: 1.3125rem;
        font-weight: 400;
        line-height: 2.083rem;
        text-transform: uppercase;
    }
    h4 { 
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.67rem;
        text-transform: uppercase;
    }
    h5 { 
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-transform: uppercase;
    }
    p.large { 
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 2rem;
        text-transform: initial;
    }
    p { 
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.375rem;
        text-transform: initial;
    }
    p.small { 
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.125rem;
        text-transform: initial;
    }
}
/* SEO */
.pb23-seo--content .title.large {
    font-size: 3.125rem;
    font-weight: 300;
    line-height: 3.5rem;
}
.pb23-seo--content h1 {
    font-size: 2.375rem;
    font-weight: 300;
    line-height: 3.5rem;
    text-transform: initial;
}
.pb23-seo--content h2 {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.32;
    text-transform: initial;
}
.pb23-seo--content h3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.8rem;
    text-transform: initial;
}
.pb23-seo--content h4 {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.833rem;
    text-transform: initial;
}
.pb23-seo--content,
.pb23-seo--content p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375rem;
}
.pb23-seo--content div.small,
.pb23-seo--content div.small p {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.125rem;
}
.pb23-seo--content .title.large {
    font-size: 2.625rem;
    font-weight: 300;
    line-height: 3.833rem;
}
.pb23-seo--content.footer h2,
.pb23-seo--content.footer h3,
.pb23-seo--content.footer h4,
.pb23-seo--content.footer h5,
.pb23-seo--content.footer p {
    color: var(--clr-gray-460);
    text-transform: initial;
}
.pb23-seo--content .show-mobile,
.pb23-seo--content.show-mobile {
    display: none;
}
@media (max-width: 1179px) {
    .pb23-seo--content h1 {
        font-size: 1.875rem;
        font-weight: 300;
        line-height: 2.833rem;
    }
    .pb23-seo--content h2 {
        font-size: 1.3125rem;
        font-weight: 500;
        line-height: 2.083rem;
    }
    .pb23-seo--content h3 {
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.833rem;
    }
    .pb23-seo--content h4 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.833rem;
    }
    .pb23-seo--content p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.375rem;
    }
    .pb23-seo--content div.small,
    .pb23-seo--content div.small p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.125rem;
    }
    .pb23-seo--content.show-mobile,
    .pb23-seo--content .show-mobile {
        display: block;
    }
    .pb23-seo--content.hide-mobile,
    .pb23-seo--content .hide-mobile {
        display: none;
    }
}

/* Área de Conteúdo Customizado */
.pb23-content--custom * {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375;
}
.pb23-content--custom h1 {
    text-align: left;
    font-size: 2.375rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    text-transform: none;
    margin: 0 var(--margin-offset) 20px;
}
.pb23-content--custom h2 {
    font-size: 1.875rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    margin: 40px 0 0.625rem;
    text-transform: none;
}
.pb23-content--custom h3 {
    font-size: 1.75rem;
    font-weight: 300;
    color: var(--clr-gray-700);
    margin: 20px 0 0.625rem;
    text-transform: none;
}
.pb23-content--custom h4 {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--clr-gray-700);
    margin: 20px 0 0.625rem;
    text-transform: none;
}
.pb23-content--custom p {
    margin: 0 0 0.625rem;
    color: var(--clr-gray-700);
}
.pb23-content--custom figure {
    width: 50%;
    float: right;
    margin: 0 0 1rem 1rem;
}
.pb23-content--custom figure > img {
    width: 100% !important;
    height: auto !important;
    margin: 0 0 5px;
}
.pb23-content--custom figure > figcaption {
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--clr-gray-500);
}
.pb23-content--custom img {
    width: 50% !important;
    height: auto !important;
    max-height: 600px;
    object-fit: contain;
    object-position: center;
}
.pb23-content--custom p > img {
    margin: 0 auto;
    display: block;
}
.pb23-content--custom p > img + br {
    display: none;
}
.pb23-content--custom tt {
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    font-style: normal;
    color: var(--clr-gray-500);
}
.pb23-content--custom p > tt {
    display: block;
}
.pb23-content--custom h1 + p,
.pb23-content--custom h1 + p + p,
.pb23-content--custom h1 + p + p, 
.pb23-content--custom h1 + p + p + p,
.pb23-content--custom h1 + p + p + p + p,
.pb23-content--custom h1 + p + p + p + p + p {
    margin: 0 var(--margin-offset) 0.625rem;
}
.pb23-content--custom h1 + hr,
.pb23-content--custom h1 + p + hr,
.pb23-content--custom h1 + p + p + hr,
.pb23-content--custom h1 + p + p + hr, 
.pb23-content--custom h1 + p + p + p + hr,
.pb23-content--custom h1 + p + p + p + p + hr,
.pb23-content--custom h1 + p + p + p + p + p + hr {
    margin: 40px var(--margin-offset) 60px;
}
#page-releasesCms .pb23-content--custom img {
    width: unset !important;
    max-height: unset !important;
    height: unset !important;
}
@media (max-width: 992px) {
    .pb23-content--custom img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        max-height: none;
    }
    .pb23-content--custom figure {
        width: 100%;
        float: none;
        margin: 0;
    }
}

.pb23-header--tool h1 {
    margin: 0 0 5px;
    font-size: 2.25rem;
    font-weight: 300;
}
.pb23-header--tool p {
    font-size: 1rem;
    font-weight: 300;
    color: var(--clr-gray-400);
}
@media (max-width: 769px) {
    .pb23-header--tool h1 {
        font-size: 1.5rem;
    }
    .pb23-header--tool p {
        font-size: 0.875rem;
    }
}