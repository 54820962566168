@keyframes matte {
    0% {
        display: none;
        opacity: 0;
    }
    5% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}
@keyframes carouselPagination {
    from {width: 0;}
    to {width: 100%;}
}
@keyframes bannerHoverFx {
    0% {
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,0.55) 100%);
    }
    30% {
        background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%,rgba(0,0,0,0.55) 100%);
    }
    70% {
        background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%,rgba(0,0,0,0.55) 100%);
    }
    100% {
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,0.55) 100%);
    }
}
@keyframes bannerHoverDarkFx {
    0% {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%);
    }
    30% {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.70) 100%);
    }
    70% {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
    }
    100% {
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.80) 100%);
    }
}
@keyframes linkAnim {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}