/* GERAIS */
:root {

    --container: 1498px;
    --container-margins: 20px;

    --transition--default: all 0.3s ease-out;

    --box-radius: 5px;

    --border: 1px solid var(--clr-gray-100);

    --spacer: 16px;
    --divider-spacer: 60px;

    --matte: rgba(0,0,0,0.54);

    --clr-white: #fff;
    --clr-gray-050: #f9f9f9;
    --clr-gray-075: #fafafa;
    --clr-gray-100: #f1f1f1;
    --clr-gray-150: #f0f0f0;
    --clr-gray-175: #eeeeee;
    --clr-gray-180: #e6eaf2;
    --clr-gray-200: #d1d1d1;
    --clr-gray-250: #d5d5d5;
    --clr-gray-280: #bec5ce;
    --clr-gray-290: #a4a9ad;
    --clr-gray-300: #b0b0b0;
    --clr-gray-320: #909090;
    --clr-gray-390: #8390a5;
    --clr-gray-400: #888;
    --clr-gray-450: #808080;
    --clr-gray-460: #797979;
    --clr-gray-500: #646464;
    --clr-gray-600: #3d3d3d;
    --clr-gray-700: #222;
    --clr-gray-800: #828FA5;
    --clr-black: #000;

    --clr-link-100: #EDF0FF;
    --clr-link-400: #0075ff;
    --clr-link-500: #3176EA;
    --clr-link-550: #3569ef;

    --clr-error-100: #ffeaea;
    --clr-error-200: #ffedec;
    --clr-error-400: #dc3545;

    --clr-success-050: #f3fffa;
    --clr-success-300: #65a594;
    --clr-success-400: #09a57f;

    --clr-warning-050: #FFF9EE;

    --clr-info-100: #EDF0FF;

    --clr-prm-300: #2a4677;
    --clr-prm-400: #002554;
    --clr-prm-600: #0b2650;

    --clr-qtn-300: #9facc3;
    --clr-qtn-400: #828fa5;

    --clr-homol-disabled: red;
    
    --clr-promo01-400: #a96324;
    --clr-promo01-300: #ffdcc4;

    --clr-promo02-400: #e3b76e;
    --clr-promo02-300: #FFDEAB;
    --clr-promo02-600: #FFC469;
    
    --z-100: -1;  /* Backgrounds */
    --z-200: 1; /* Padrão */
    --z-300: 10; /* Menus */
    --z-400: 100; /* Elementos Extras */
    --z-500: 1000; /* Dialog e Modais de Contexto */
    --z-600: 10000; /* Menu Principal */
    --z-700: 100000; /* Dialog e Modais de Bloqueio */

    --shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);

    --content--lines-max: 4;
    --name--lines-max: 3;
    --title--lines-max: 3;
    
    --input-width--min: 120px;
    --input-height: 42px;
    --input-radius: 10px;
    --input-background: var(--clr-gray-100);
    --input-border--color: var(--clr-gray-250);
    --input-placeholder--color: var(--clr-gray-500);
    --input-font--size: 0.875rem;
    --input-font--size-focus: 0.625rem;
    --input-font--color: var(--clr-gray-700);
    --input-font--color-focus: var(--clr-gray-700);
    --input-font--weight: 300;
    --input-font--weight-focus: 500;
    --input-prepos--size: 0.875rem;
    --input-prepos--color: var(--clr-gray-400);
    --input-prepos--weight: 300;
    --input-label--top: 10px;
    --input-padding--left: 1rem;
    --input-padding--right: 1rem;
    --input-padding--top: calc(var(--input-label--top) + 2px);
    --input-icon: 18px;
    --input-mark: 1rem;
    --input-mark--spacer: 10px;
    --input-mark--radius: 2px;
    --input-mark--border-size: 2px;
    --input-mark--border-spacer: 1px;
    --input-mark--border-color: var(--clr-gray-700);
    --input-mark--check: var(--clr-prm-600);
    --input-mark--background: var(--clr-white);
    --mark-font--size: 0.75rem;
    --mark-font--weight: 300;
    --mark-font--color: var(--clr-gray-700);
    --mark-font--style: normal;
    --content-font--size: 0.875rem;
    --content-font--weight: 400;
    --content-font--color: var(--clr-gray-700);
    --content-font--style: normal;
    --input-spacer: 1rem;
    --hint-font--size: 0.75rem;
    --hint-font--weight: 400;
    --hint-font--color: var(--clr-gray-300);
    --hint-font--style: italic;
    --error-font--size: 0.75rem;
    --error-font--weight: 500;
    --error-font--color: var(--clr-error-400);
    --error-font--style: normal;
    --input-range--thumb-size: 15px;
    --input-range--thumb-color: var(--clr-prm-600);
    --input-range--track-size: 2px;
    --input-range--track-color: var(--clr-gray-300);

    --tag--height: 26px;
    --tag--spacer: 12px;
    --tag--radius: 5px;
    --tag--bg: var(--clr-gray-180);
    --tag--font-size: 0.75rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    
    --carousel-nav--link-color: var(--clr-gray-700);
    --carousel-nav--link-spacer: 30px;

}
@media (max-width: 769px) {
    :root {
        --divider-spacer: 40px;
    }
}

/* LAYOUT */
.pb23-container.full {
    --container: 100%;
    --container-margins: 20px;
}
.pb23-container.full-spaced {
    --container: 100%;
    --container-margins: 20px;
}
.pb23-container.tool {
    --container: 100%;
    --container-margins: 90px;
}
.pb23-container.over {
    --container: 100%;
    --container-margins: 0px;
}
.pb23-container.seo {
    --container: calc(1498px * 0.66);
    --container-margins: 20px;
}
.pb23-container.medium {
    --container: 1246px;
    --container-margins: 20px;
}
.pb23-container.small {
    --container: 780px;
    --container-margins: 20px;
}
@media (max-width: 1498px) {
    .pb23-container.tool {
        --container-margins: 60px;
    }
    .pb23-container.seo {
        --container: 66%;
    }
    .pb23-container.carousel {
        --container: 100%;
        --container-margins: 12px;
    }
}
@media (max-width: 1180px) {
    .pb23-container.seo {
        --container: 100%;
    }
    .pb23-container.medium {
        --container: 100%;
    }
}
@media (max-width: 769px) {    
    .pb23-container.tool {
        --container-margins: 20px;
    }
    .pb23-container.small {
        --container: 100%;
    }
}

/* INPUTS */
label.large {
    --input-height: 48px;
}
label.medium {
    --input-height: 42px;
}
label.small {
    --input-height: 33px;
}
label:hover {
    --input-background: var(--clr-gray-150);
    --input-border--color: var(--clr-gray-150);
}
label:has(> input:not([type="radio"]):not([type="checkbox"]):focus){
    --input-background: var(--clr-white);
    --input-border--color: var(--clr-gray-700);
}
label:has(> input:not([type="radio"]):not([type="checkbox"]):not(:placeholder-shown)) > span:not([class]) {
    --input-border--color: var(--clr-gray-700);
    --input-placeholder--color: transparent;
}
label:has(> input:not([type="radio"]):not([type="checkbox"]):disabled){
    --input-background: var(--clr-gray-100);
    --input-border--color: var(--clr-gray-100);
    --input-placeholder--color: var(--clr-gray-280);
}
label:has(> input:not([type="radio"]):not([type="checkbox"]):not(:placeholder-shown)) > pos {
    --input-border--color: var(--clr-gray-700);
}
label[data-error] {
    --input-border--color: var(--clr-error-400) !important;
}
label > select {
    --input-padding--right: 40px;    
    --input-background: url("/template/images/icons/icon_forms--select-chevron.svg") calc(100% - 18px) center no-repeat, var(--clr-white);
}

/* BOTÕES */
.pb23-btn,
.pb23-btn * {
    --button-height: 46px;
    --button-spacer: 10px;
    --button-padding: 50px;
    --button-radius: 10px;
    --button-font: 0.75rem;
    --button-weight: 400;
    --button-color: var(--clr-gray-700);
    --button-letter: 2px;
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border--thickness: 1px;
    --button-border: transparent;
    --button-border--hover: var(--button-border);
}
.pb23-btn.light,
.pb23-btn.light * {
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-border: var(--clr-gray-700);
    --button-shadow: none;
    --button-shadow--hover: 0px 0px 15px 0px rgba(0,0,0,0.16);
    --button-border--hover: var(--clr-gray-700);
}
.pb23-btn.dark,
.pb23-btn.dark * {
    --button-color: var(--clr-white);
    --button-bg: var(--clr-black);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-gray-500);
    --button-border: transparent;
    --button-border--hover: transparent;
}
.pb23-btn.download-dark,
.pb23-btn.download-dark * {
    --button-color: var(--clr-white);
    --button-bg: var(--clr-black);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-gray-500);
    --button-height: 40px;
    --button-padding: 20px;
}
.pb23-btn.darkest,
.pb23-btn.darkest * {
    --button-color: var(--clr-white);
    --button-bg: var(--clr-gray-700);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-gray-500);
}
.pb23-btn.blue-dark,
.pb23-btn.blue-dark * {
    --button-color: var(--clr-white);
    --button-bg: var(--clr-link-500);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-link-400);
}
.pb23-btn.clear,
.pb23-btn.clear * {
    --button-bg: transparent;
    --button-color--hover: transparent;
    --button-bg--hover: transparent;
    --button-shadow: none;
}
.pb23-btn.clear-blue,
.pb23-btn.clear-blue * {
    --button-color: var(--clr-link-400);
    --button-bg: transparent;
    --button-color--hover: var(--clr-link-400);
    --button-bg--hover: transparent;
    --button-border: var(--clr-link-400);
    --button-shadow: none;
}
.pb23-btn.clear-black,
.pb23-btn.clear-black * {
    --button-color: var(--clr-gray-700);
    --button-bg: transparent;
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: transparent;
    --button-border: var(--clr-gray-700);
    --button-shadow: none;
}
.pb23-btn.shortcut,
.pb23-btn.shortcut * {
    --button-color: var(--clr-link-400);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-link-400);
    --button-bg--hover: var(--clr-white);
    --button-border: var(--clr-link-400);
    --button-shadow: none;
    --button-shadow--hover: 0px 0px 15px 0px rgba(0,0,0,0.16);
}
.pb23-btn.small,
.pb23-btn.small * {
    --button-height: 24px;
    --button-padding: 4px;
}
.pb23-btn.medium,
.pb23-btn.medium * {
    --button-height: 36px;
    --button-padding: 20px;
    --button-font: 0.875rem;
    --button-letter: normal;
    --button-transform: normal;
}
.pb23-btn.banner-main,
.pb23-btn.banner-main * {
    --button-bg--hover: rgba(255,255,255,0.8);
    --button-transform: uppercase;
}
.pb23-btn.banner-main,
.pb23-btn.banner-main * {
    transition: all 0.8s ease-out;
}
.pb23-btn.close {
    --button-bg--hover: transparent;
    --button-height: unset;
}
.pb23-btn.close.icon {
    --button-spacer: 0;
    --button-padding: 0;
}
.pb23-btn.simple,
.pb23-btn.simple * {
    --button-height: 30px;
    --button-padding: 10px;
    --button-radius: 5px;
    --button-font: 0.875rem !important;
    --button-letter: normal;
    --button-transform: normal;
}
.pb23-btn.bordered,
.pb23-btn.bordered * {
    --button-color: var(--clr-black);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-black);
    --button-border: var(--clr-black);
    --button-padding: 20px;
}
.pb23-btn.whiteborder,
.pb23-btn.whiteborder * {
    --button-color: var(--clr-white);
    --button-bg: transparent;
    --button-color--hover: var(--clr-gray-100);
    --button-bg--hover: transparent;
    --button-border: var(--clr-white);
    --button-padding: 20px;
}
.pb23-btn.download,
.pb23-btn.download * {
    --button-height: 40px;
    --button-spacer: 10px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-font: 1rem;
    --button-weight: 400;
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-gray-100);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-gray-100);
    --button-shadow: none;
    --button-border: transparent;
    --button-border--hover: transparent;
    --button-transform: normal;
    --button-letter: auto;
}
.pb23-btn.download.icon {
    --button-padding: 0px;
}
.pb23-btn.show-filter,
.pb23-btn.show-filter * {
    --button-height: 36px;
    --button-padding: 20px;
    --button-font: 0.875rem;
    --button-weight: 400;
    --button-color: var(--clr-gray-700);
    --button-transform: none; 
    --button-letter: normal;
    --button-bg: var(--clr-gray-100);
    --button-bg--hover: var(--clr-gray-100);
}
.pb23-btn.filter,
.pb23-btn.filter * {
    --button-height: 36px;
    --button-padding: 18px;
    --button-font: 0.875rem;
    --button-transform: none; 
    --button-letter: 0.5px;
}
.pb23-btn.filter[disabled],
.pb23-btn.filter[disabled] * {
    --button-color: var(--clr-gray-400);
    --button-color--hover: var(--clr-gray-400);
    --button-bg: var(--clr-gray-200);
    --button-bg--hover: var(--clr-gray-200);
}
.pb23-btn.filter .icon > .disabled {
    display: none;
}
.pb23-btn.filter[disabled] .icon > .enabled {
    display: block;
}
.pb23-btn.filter[disabled] .icon > .enabled {
    display: none;
}
.pb23-btn.filter[disabled] .icon > .disabled {
    display: block;
}
.pb23-link,
.pb23-link * {
    --link-color: var(--clr-white);
}
.pb23-link.darkest,
.pb23-link.darkest * {
    --link-color: var(--clr-gray-700);
}
.pb23-btn.filter-submenu,
.pb23-btn.filter-submenu * {
    --button-height: 46px;
    --button-spacer: 10px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-weight: 300;
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-250);
    --button-border--hover: var(--clr-gray-250);
    --button-font: 0.875rem;
    --button-letter: normal;
    --button-transform: normal;
}
.pb23-btn.filter-submenu.grayed,
.pb23-btn.filter-submenu.grayed * {
    --button-bg: var(--clr-gray-100);
    --button-bg--hover: var(--clr-gray-100);
}
.pb23-btn.input-disabled,
.pb23-btn.input-disabled *{
    --button-height: 100%;
    --button-spacer: 0;
    --button-padding: 20px;
    --button-radius: 0;
    --button-weight: 400;
    --button-color: transparent;
    --button-bg: transparent;
    --button-color--hover: transparent;
    --button-bg--hover: transparent;
    --button-shadow: none;
    --button-border: transparent;
}
.pb23-btn.action-link,
.pb23-btn.action-link * {
    --button-height: auto;
    --button-spacer: 10px;
    --button-padding: 0px;
    --button-radius: 10px;
    --button-font: 0.875rem;
    --button-weight: 700;
    --button-letter: normal;
    --button-color: var(--clr-gray-400);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-400);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-100);
    --button-transform: normal;
}
.pb23-btn.action-tool,
.pb23-btn.action-tool * {
    --button-height: auto;
    --button-spacer: 10px;
    --button-padding: 0px;
    --button-radius: 10px;
    --button-font: 0.875rem;
    --button-weight: 700;
    --button-letter: normal;
    --button-color: var(--clr-gray-700);
    --button-bg: transparent;
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: transparent;
    --button-shadow: none;
    --button-border: var(--clr-gray-500);
    --button-transform: normal;
    --button-border--hover: var(--clr-gray-500);
}
.pb23-btn.action-footnote,
.pb23-btn.action-footnote * {
    --button-height: auto;
    --button-spacer: 10px;
    --button-padding: 0px;
    --button-radius: 10px;
    --button-font: 0.75rem;
    --button-weight: 700;
    --button-letter: normal;
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-500);
    --button-transform: normal;
}
.pb23-btn.filter-auxmenu,
.pb23-btn.filter-auxmenu * {
    --button-height: 56px;
    --button-padding: 20px;
    --button-letter: normal;
    --button-font: 1rem;
    --button-radius: 0px;
    --button-transform: normal;
    --button-border: transparent;
    --button-border--hover: transparent;
    --button-bg: var(--clr-white);
}
.pb23-btn.aside-link,
.pb23-btn.aside-link * {
    --button-height: 36px;
    --button-spacer: 8px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-font: 0.875rem;
    --button-weight: 400;
    --button-letter: normal;
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-gray-100);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-gray-050);
    --button-shadow: none;
    --button-border: transparent;
    --button-transform: normal;
    --button-border--hover: transparent;
}
.pb23-btn.spec-selector,
.pb23-btn.spec-selector * {
    --button-height: 36px;
    --button-spacer: 10px;
    --button-padding: 8px;
    --button-radius: 10px;
    --button-weight: 400;
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-200);
    --button-border--hover: var(--clr-gray-700);
    --button-font: 0.875rem;
    --button-letter: normal;
    --button-transform: normal;
}
.pb23-btn.spec-selector.selected,
.pb23-btn.spec-selector.selected * {
    --button-color: var(--clr-white);
    --button-bg: var(--clr-black);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-black);
    --button-border: var(--clr-black);
    --button-border--hover: var(--clr-black);
}
.pb23-btn.spec-selector.color,
.pb23-btn.spec-selector.color * {
    --button-height: 42px;
    --button-spacer: 0px;
    --button-padding: 4px;
    --button-radius: 10px;
    --button-weight: 400;
}
.pb23-btn.spec-selector.color.selected {
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-200);
    --button-border--hover: var(--clr-gray-700);
    border: 2px solid var(--button-border--hover,--button-border);
}
.pb23-btn.spec-selector:has(> input[type="radio"]:checked),
.pb23-btn.spec-selector:has(> input[type="radio"]:checked) *,
.pb23-btn.spec-selector:has(> input[type="checkbox"]:checked),
.pb23-btn.spec-selector:has(> input[type="checkbox"]:checked) * {
    --button-weight: 400;
    --button-color: var(--clr-white) !important;
    --mark-font--color: var(--clr-white) !important;
    --mark-font--weight: 400;
    --button-bg: var(--clr-black);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-black);
    --button-border: var(--clr-black);
    --button-border--hover: var(--clr-black);
}
.pb23-btn.spec-selector.color:has(> input[type="radio"]:checked),
.pb23-btn.spec-selector.color:has(> input[type="checkbox"]:checked) {
    --button-color: var(--clr-gray-700);
    --button-bg: var(--clr-white);
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border: var(--clr-gray-200);
    --button-border--hover: var(--clr-gray-700);
    border: 2px solid var(--button-border--hover,--button-border);
}
.pb23-btn.spec-selector:has(> input[type="radio"][disabled]),
.pb23-btn.spec-selector:has(> input[type="checkbox"][disabled]) {
    border: 1px dashed var(--button-border);
}
.pb23-btn.carousel-actions,
.pb23-btn.carousel-actions * {
    --button-height: 40px;
    --button-spacer: 10px;
    --button-padding: 0px;
    --button-radius: 20px;
    --button-font: 0.75rem;
    --button-weight: 400;
    --button-color: var(--clr-white);
    --button-transform: normal; 
    --button-letter: auto;
    --button-bg: rgba(34,34,34,0.5);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: rgba(34,34,34,0.5);
    --button-shadow: none;
    --button-border: transparent;
    --button-border--hover: transparent;
}
.pb23-btn.masonry-actions,
.pb23-btn.masonry-actions * {
    --button-height: 40px;
    --button-spacer: 10px;
    --button-padding: 0px;
    --button-radius: 20px;
    --button-font: 0.75rem;
    --button-weight: 400;
    --button-color: var(--clr-white);
    --button-transform: normal; 
    --button-letter: auto;
    --button-bg: rgba(34,34,34,0.5);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: rgba(34,34,34,0.5);
    --button-shadow: none;
    --button-border: transparent;
    --button-border--hover: transparent;
}
.pb23-btn.promo-blackdays2024,
.pb23-btn.promo-blackdays2024 *,
.pb23-btn.promo-blackdays2025,
.pb23-btn.promo-blackdays2025 * {
    --button-height: 28px;
    --button-spacer: 8px;
    --button-padding: 8px;
    --button-radius: 5px;
    --button-font: 0.875rem;
    --button-weight: 500;
    --button-color: var(--clr-black);
    --button-transform: normal; 
    --button-letter: auto;
    --button-bg: var(--clr-gray-050);
    --button-color--hover: var(--clr-black);
    --button-bg--hover: var(--clr-promo02-600);
    --button-shadow: none;
    --button-border: var(--clr-promo02-600);
    --button-border--hover: var(--clr-promo02-600);
}
.pb23-btn.tool,
.pb23-btn.tool * {
    --button-height: 44px;
    --button-spacer: 8px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-font: 0.75rem;
    --button-weight: 400;
    --button-color: var(--clr-gray-700);
    --button-transform: uppercase; 
    --button-letter: 2px;
    --button-bg: transparent;
    --button-color--hover: var(--clr-gray-700);
    --button-bg--hover: var(--clr-white);
    --button-shadow: none;
    --button-border--thickness: 1.5px;
    --button-border: var(--clr-gray-700);
    --button-border--hover: var(--clr-gray-700);
}
.pb23-btn.tool.dark,
.pb23-btn.tool.dark * {
    --button-height: 44px;
    --button-spacer: 8px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-font: 0.75rem;
    --button-weight: 400;
    --button-color: var(--clr-white);
    --button-transform: uppercase; 
    --button-letter: 2px;
    --button-bg: var(--clr-black);
    --button-color--hover: var(--clr-white);
    --button-bg--hover: var(--clr-black);
    --button-shadow: none;
    --button-border--thickness: 1.5px;
    --button-border: var(--clr-black);
    --button-border--hover: var(--clr-black);
}
.pb23-btn.tool[disabled],
.pb23-btn.tool[disabled] * {
    --button-color: var(--clr-gray-400) !important;
    --button-bg: var(--clr-white) !important;
    --button-color--hover: var(--clr-gray-400) !important;
    --button-bg--hover: var(--clr-white) !important;
    --button-border: var(--clr-gray-200) !important;
    --button-border--hover: var(--clr-gray-200) !important;
}
.pb23-btn.tool.dark[disabled],
.pb23-btn.tool.dark[disabled] * {
    --button-color: var(--clr-gray-400) !important;
    --button-bg: var(--clr-gray-200) !important;
    --button-color--hover: var(--clr-gray-400) !important;
    --button-bg--hover: var(--clr-gray-200) !important;
    --button-border: var(--clr-gray-200) !important;
    --button-border--hover: var(--clr-gray-200) !important;
}
.pb23-btn.mosaicproduct,
.pb23-btn.mosaicproduct * {
    --button-width: 60px;
    --button-height: 60px;
    --button-spacer: 0px;
    --button-padding: 0px;
    --button-radius: 10px;
    --button-font: 0.875rem;
    --button-weight: 700;
    --button-letter: normal;
    --button-color: var(--clr-black);
    --button-bg: transparent;
    --button-color--hover: var(--clr-black);
    --button-bg--hover: transparent;
    --button-shadow: none;
    --button-border: var(--clr-gray-100);
    --button-border--hover: var(--clr-black);
    --button-transform: normal;
}
.pb23-btn.silver,
.pb23-btn.silver * {
    --button-height: 40px;
    --button-spacer: 8px;
    --button-padding: 20px;
    --button-radius: 10px;
    --button-font: 0.875rem;
    --button-weight: 300;
    --button-letter: normal;
    --button-color: var(--clr-black);
    --button-bg: var(--clr-gray-050);
    --button-color--hover: var(--clr-black);
    --button-bg--hover: var(--clr-gray-050);
    --button-shadow: none;
    --button-border: var(--clr-gray-100);
    --button-border--hover: var(--clr-black);
    --button-transform: normal;
}
.pb23-btn.mosaiccolor,
.pb23-btn.mosaiccolor * {
    --button-padding: 0;
    --button-font: 0.75rem;
    --button-weight: 300;
    --button-color: var(--clr-black);
    --button-color--hover: var(--clr-black);
    --button-transform: normal; 
    --button-letter: auto;
}
.pb23-btn.banner-main,
.pb23-btn.banner-main *,
.pb23-btn.formSubmit,
.pb23-btn.formSubmit * {
    --button-font: 0.75rem !important;
    --button-letter: 2px;
}
@media (max-width:769px) {
    .pb23-btn.tool,
    .pb23-btn.tool *,
    .pb23-btn.tool.dark,
    .pb23-btn.tool.dark * {
        --button-padding: 16px;
    }
}
/* CABEÇALHOS DE SEÇÃO */
.pb23-section--header,
.pb23-section--header * {
    --section-header-title--font: 1.75rem;
    --section-header-content--font: 1rem;
    --section-header--spacer: 20px;
}
@media (max-width: 1180px) {
    .pb23-section--header,
    .pb23-section--header * {
        --section-header-title--font: 1.5rem;
    }
}
@media (max-width: 1179px) {
    .pb23-section--header,
    .pb23-section--header * {
        --section-header-title--font: 1.5rem;
        --section-header-content--font: 1rem;
    }
}
@media (max-width: 765px) {
    .pb23-section--header,
    .pb23-section--header * {
        --section-header-title--font: 1.5rem;
        --section-header-content--font: 0.75rem;
    }
}

/* BANNERS (GERAL) */
.pb23-banner,
.pb23-banner * {  
    --banner-height: auto;  
    --banner-aspect: none;
    --banner-padding--y: 30px;
    --banner-padding--x: 40px;
    --banner-title--font: 1.75rem;
    --banner-title--line: 1.14;
    --banner-subtitle--font: 1rem;
    --banner-subtitle--line: 1.375;
    --banner-subtitle--spacer: 5px;
    --banner-subtitle--spacing: auto;
    --banner-subtitle--transform: normal;
    --banner-description: 0.875rem;
    --banner-description--spacer: 10px;
    --banner-cta--spacer: 10px;
    --banner-cta--font: 0.875rem;
    --banner-align--x: flex-start;
    --banner-align--y: flex-end;
    --banner-fx: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.55) 100%);
}
@media only screen and (min-width: 1080px) and (max-width: 1366px) {
    .pb23-banner,
    .pb23-banner * { 
        --banner-title--font: 1.3125rem;
        --banner-title--line: 1.2;
        --banner-subtitle--font: 0.875rem;
        --banner-subtitle--line: 1.6;
    }
}
@media only screen and (min-width: 900px) and (max-width: 1179px) {
    .pb23-banner,
    .pb23-banner * { 
        --banner-title--font: 1.3125rem;
        --banner-title--line: 1.2;
        --banner-subtitle--font: 0.875rem;
        --banner-subtitle--line: 1.6;
        --banner-padding--y: 20px;
        --banner-padding--x: 20px;
    }
}
@media only screen and (min-width: 770px) and (max-width: 899px) {
    .pb23-banner,
    .pb23-banner * { 
        --banner-title--font: 1.3125rem;
        --banner-title--line: 1.2;
        --banner-subtitle--font: 0.875rem;
        --banner-subtitle--line: 1.6;
        --banner-padding--y: 20px;
        --banner-padding--x: 20px;
    }
}
@media only screen and (max-width: 769px) {
    .pb23-banner,
    .pb23-banner * { 
        --banner-title--font: 1.3125rem;
        --banner-title--line: 1.2;
        --banner-subtitle--font: 0.875rem;
        --banner-subtitle--line: 1.6;
        --banner-padding--y: 20px;
        --banner-padding--x: 20px;
    }
}


/* Tags */
.pb23-tag.sku,
.pb23-tag.sku * {
    --tag--spacer: 20px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--bg: var(--clr-gray-050); 
    --tag--border-color: var(--clr-gray-100);
}
.pb23-tag.releases,
.pb23-tag.releases * {
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--bg: var(--clr-gray-050);
    --tag--border-color: var(--clr-gray-050);
}
.pb23-tag.download,
.pb23-tag.download * {
    --tag--height: 28px;
    --tag--font-size: 0.875rem;
}
.pb23-tag.static.specs,
.pb23-tag.static.specs * {
    --tag--height: 28px;
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--bg: var(--clr-gray-050);
    --tag--border-color: var(--clr-gray-200);
}
.pb23-tag.specs-application,
.pb23-tag.specs-application * {
    --tag--height: 28px;
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--bg: var(--clr-gray-050);
    --tag--border-color: var(--clr-gray-200);
}
.pb23-tag.specs-application > i {
    font-style: normal;
    white-space: nowrap;
}
.pb23-tag.new,
.pb23-tag.new * {
    --tag-height: 28px;
    --tag--spacer: 8px;
    --tag--radius: 5px;
    --tag--bg: var(--clr-gray-050);
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--border-color: var(--clr-gray-100);
}
.pb23-tag.discount {
    --tag--font-color: var(--clr-black);
    --tag--bg: #FCB956;
    --tag--font-size: 0.875rem;
    gap: 4px;
}

.pb23-tag.info {
    --tag-height: 28px;
    --tag--spacer: 8px;
    --tag--radius: 5px;
    --tag--bg: var(--clr-gray-050);
    --tag--font-size: 0.875rem;
    --tag--font-weight: 300;
    --tag--font-color: var(--clr-gray-700);
    --tag--border-color: var(--clr-gray-200);
}
.pb23-tag.blackdays,
.pb23-tag.blackdays * {
    --tag--height: 28px;
    --tag--spacer: 8px;
    --tag--font-size: 14px;
    --tag--font-weight: 700;
    --tag--font-color: var(--clr-black);
    --tag--font-color-hover: var(--clr-black);
    --tag--bg: var(--clr-promo02-600);
    --tag--bg-hover: var(--clr-white);
    --tag--border-color: var(--clr-promo02-600);
    --tag--border-color-hover: var(--clr-promo02-600);
}
/* Tag: Engenharia/Construtoras */
.pb23-tag.saleschannel-1,
.pb23-tag.saleschannel-1 *,
.pb23-tag.saleschannel-construtoras,
.pb23-tag.saleschannel-construtoras *
 {
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 700;
    --tag--font-color: var(--clr-gray-500);
    --tag--bg: var(--clr-gray-200);
    --tag--border-color: var(--clr-gray-200); 
}
/* Tag: Multimarcas */
.pb23-tag.saleschannel-2,
.pb23-tag.saleschannel-2 *,
.pb23-tag.saleschannel-lojas,
.pb23-tag.saleschannel-lojas *,
.pb23-tag.saleschannel-multimarcas,
.pb23-tag.saleschannel-multimarcas * {
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 700;
    --tag--font-color: var(--clr-white);
    --tag--bg: var(--clr-qtn-400);
    --tag--border-color: var(--clr-qtn-400);  
}
/* Tag: Portobello Shop */
.pb23-tag.saleschannel-4,
.pb23-tag.saleschannel-4 *,
.pb23-tag.saleschannel-portobello-shop,
.pb23-tag.saleschannel-portobello-shop * {
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 700;
    --tag--font-color: var(--clr-white);
    --tag--bg: var(--clr-gray-700); 
    --tag--border-color: var(--clr-gray-700);    
}
/* Tag: Exportação/Internacional */
.pb23-tag.saleschannel-5,
.pb23-tag.saleschannel-5 *,
.pb23-tag.saleschannel-internacional,
.pb23-tag.saleschannel-internacional * {
    --tag--spacer: 8px;
    --tag--font-size: 0.875rem;
    --tag--font-weight: 700;
    --tag--font-color: var(--clr-white);
    --tag--bg: var(--clr-gray-500);  
    --tag--border-color: var(--clr-gray-500);     
}
/* MODAL (GERAL) */
.pb23-modal,
.pb23-modal *,
.pb23-modal::backdrop {  
    --modal--max-width: 666px;
    --modal--width: 100%;
    --modal--radius: 10px;
    --modal--padding: 20px 30px 30px;
    --modal--background: var(--clr-white);
    --matte: rgba(0,0,0,0.54);
}
/* Área de Conteúdo Customizado */
.pb23-content--custom,
.pb23-content--custom * {
    --container: 1000px;
    --margin-offset: -249px;
}
@media (max-width: 1538px) {
    .pb23-content--custom,
    .pb23-content--custom * {
        --container: calc(66vw + 20px);
        --margin-offset: calc(0px - 17vw + 40px);
    }
}
@media (max-width: 992px) {
    .pb23-content--custom,
    .pb23-content--custom * {
        --container: 100%;
        --margin-offset: 0px;
    }
}
/* Item de Paginação (Calculadora) */
.pb23-item--pagination,
.pb23-item--pagination * {
    --mark-font--size: 14px;
    --mark-font--weight: 400;
    --mark-font--color: var(--clr-gray-500);
    --border-color: transparent;
}
.pb23-item--pagination:has(input:checked),
.pb23-item--pagination:has(input:checked) * {
    --mark-font--color: var(--clr-link-400);
    --mark-font--weight: 400 !important;
    --border-color: var(--clr-link-400);
}
/* Menu Auxiliar */
.pb23-auxmenu,
.pb23-auxmenu * {
    --auxmenu-width: 454px;
}
@media (max-width: 600px) {
    .pb23-auxmenu,
    .pb23-auxmenu * {
        --auxmenu-width: 100%;
    }
}
