#page-releases,
#page-releases * {
    box-sizing: border-box;
    --header-height: 794px;
    --logo-spacer: 200px;
    --releases-spacer: 124px;
}

#page-releases header {
    width: 100%;
    height: var(--header-height);
    margin: 0 0 var(--releases-spacer);
    display: block;
    background: url("/data/releases/banner.jpg") center center no-repeat;
    background-size: cover;
    position: relative;
}
#page-releases header h1 {
    margin-top: var(--logo-spacer);
}
#page-releases header a {
    width: 214px;
    display: inline-block;
    position: absolute;
    left: calc(50% - 107px);
    bottom: 66px;
    text-align: center;
    text-decoration: none;
}
#page-releases header a span.label {
    display: block;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
    color: var(--color-black);
    text-transform: uppercase;
}
#page-releases header a img {
    display: block;
    margin: 0 auto -40px;
    text-align: center;
}
#page-releases header a span.block {
    width: 65px;
    height: 42px;
    margin: 20px auto 0;
    display: block;
    background: var(--color-white);
}
@media (max-width: 992px) {#page-releases,
    #page-releases * {
        --header-height: 380px;
        --logo-spacer: 40px;
        --releases-spacer: 0px;
    }
    #page-releases header {
        background: none;
    }
    #page-releases header a {
        display: none;
    }
}