#page-fachada,
#page-fachada * {
    box-sizing: border-box;
    --header-spacer: 60px;
    --table-spacer: 350px;
}
#page-fachada .pb-fachadastable {
    margin: 0 0 var(--table-spacer);
}
#page-fachada .pb-fachadastable h2 {
    font-size: 1.75rem;
    font-weight: 700;
}
#page-fachada .pb-fachadastable .table-header {
    display: grid;
    grid-template-columns: 25% 25% 1fr 1fr 1fr;
    margin: 0 0 var(--header-spacer);
}
#page-fachada .pb-fachadastable .table-header * {
    font-size: 1rem;
    color: var(--color-main);
    font-weight: 300;
    text-align: center;
}
#page-fachada .pb-fachadastable .table-header .pb {
    font-size: 1.125rem;
    font-weight: 700;
}
#page-fachada .pb-fachadastable .table-header p {
    padding-right: 30%;
    text-align: left;
    margin: 0;
}
#page-fachada .pb-fachadastable .table-line {
    height: 50px;
    display: grid;
    grid-template-columns: 25% 75%;
}
#page-fachada .pb-fachadastable .table-line:nth-of-type(odd) {
    background: var(--color-lightest);
}
#page-fachada .pb-fachadastable .table-line span {
    align-self: center;
    padding: 0 0 0 28px;
}
#page-fachada .pb-fachadastable .table-line dl {
    display: grid;
    grid-template-columns: 33.33% 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
    align-self: center;
}
#page-fachada .pb-fachadastable .table-line dl dt {
    display: none;
    margin: 0;
    padding: 0;
}
#page-fachada .pb-fachadastable .table-line dl dd {
    text-align: center;
    margin: 0;
    padding: 0;
}
@media (max-width: 992px) {
    #page-fachada,
    #page-fachada * {
        box-sizing: border-box;
        --header-spacer: 40px;
        --table-spacer: 140px;
    }
    #page-fachada .pb-fachadastable h2 {
        font-size: 1.125rem;
    }
    #page-fachada .pb-fachadastable .table-header {
        display: block;
    }
    #page-fachada .pb-fachadastable .table-header span {
        display: none;
    }
    #page-fachada .pb-fachadastable .table-line {
        background: none !important;
        height: auto;
        display: block;
    }
    #page-fachada .pb-fachadastable .table-line > span {
        display: block;
        margin: 0 0 12px;
        padding: 0px;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--main-color);
    }
    #page-fachada .pb-fachadastable .table-line dl {
        display: grid;
        margin: 0 0 44px;
        grid-template-columns: 30% 1fr;
    }
    #page-fachada .pb-fachadastable .table-line dl dt {
        display: block;
        height: 22px;
        font-size: 0.75rem;
        font-weight: 300;
    }
    #page-fachada .pb-fachadastable .table-line dl dd {
        height: 22px;
        font-size: 0.75rem;
    }
    #page-fachada .pb-fachadastable .table-line dl dt:nth-of-type(1) {
        grid-column: 2;
        grid-row: 1;
    }
    #page-fachada .pb-fachadastable .table-line dl dd:nth-of-type(1) {
        grid-column: 1;
        grid-row: 1;
    }
    #page-fachada .pb-fachadastable .table-line dl dt:nth-of-type(2) {
        grid-column: 2;
        grid-row: 2;
        background: var(--color-lightest); 
    }
    #page-fachada .pb-fachadastable .table-line dl dd:nth-of-type(2) {
        grid-column: 1;
        grid-row: 2;
        background: var(--color-lightest);
    }
    #page-fachada .pb-fachadastable .table-line dl dt:nth-of-type(3) {
        grid-column: 2;
        grid-row: 3;
    }
    #page-fachada .pb-fachadastable .table-line dl dd:nth-of-type(3) {
        grid-column: 1;
        grid-row: 3;
    }
    #page-fachada .pb-fachadastable .table-line dl dt:nth-of-type(4) {
        grid-column: 2;
        grid-row: 4;
        background: var(--color-lightest); 
    }
    #page-fachada .pb-fachadastable .table-line dl dd:nth-of-type(4) {
        grid-column: 1;
        grid-row: 4;
        background: var(--color-lightest);
    }
    #page-fachada .pb-fachadastable .table-line img {
        height: 18px;
    }
}
.pb-fachadaconcept {
    min-height: 850px;
    margin-top: 78px;
    margin-bottom: 135px;
}
@media (max-width: 992px) {
    .pb-fachadaconcept {
        min-height: 0px;
        margin-bottom: 50px;
    }
}