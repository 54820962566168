button,
button *,
a,
a *,
.pb23-btn,
.pb23-btn * {
    font-size: inherit;
    transition: all 0.3s ease-out;
}
button {
    outline: none !important;
}
.pb23-btn {
    width: var(--button-width,auto);
    min-height: var(--button-height);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--button-spacer);
    padding: 0 var(--button-padding);
    text-decoration: none;
    font-size: var(--button-font,0.75rem);
    color: var(--button-color);
    font-weight: var(--button-weight);
    text-transform: var(--button-transform,none);
    text-align: center;
    text-decoration: none;
    letter-spacing: var(--button-letter,normal);
    line-height: 1.2;
    background: var(--button-bg);
    border: var(--button-border--thickness) solid var(--button-border);
    border-radius: var(--button-radius);
    box-shadow: var(--button-shadow);
}
.pb23-btn > .icon {
    position: relative;
}
.pb23-btn > .icon svg,
.pb23-btn > .icon svg path {
    fill: var(--button-color);
}
.pb23-btn > .icon .hover {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}
.pb23-btn.full {
    width: 100%;
    padding: 0;
}
.pb23-btn * {
    text-decoration: none;
    white-space: var(--button-wrap,nowrap);
}
.pb23-btn:hover > .icon:has(.hover) > img {
    opacity: 0;
}
.pb23-btn:hover > .icon:has(.hover) > .hover {
    opacity: 1;
}
.pb23-btn:hover {
    cursor: pointer;
    color: var(--button-color--hover);
    background: var(--button-bg--hover);
    border: 1px solid var(--button-border--hover);
    box-shadow: var(--button-shadow--hover);
    text-decoration: none;
}
.pb23-btn:hover > .icon svg,
.pb23-btn:hover > .icon svg path {
    fill: var(--button-color--hover);
}
.pb23-btn[disabled]:hover {
    cursor: not-allowed;
}
.pb23-btn.action-tool,
.pb23-btn.action-link,
.pb23-btn.action-footnote {
    border: none !important;
}
.pb23-btn.action-tool > span,
.pb23-btn.action-link > span,
.pb23-btn.action-footnote > span {
    position: relative;
}
.pb23-btn.action-tool > span:before,
.pb23-btn.action-link > span:before,
.pb23-btn.action-footnote > span:before {
    width: 100%;
    height: 2px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    background: var(--button-border);
}
.pb23-btn.action-tool:hover > span:before,
.pb23-btn.action-link:hover > span:before,
.pb23-btn.action-footnote:hover > span:before {
    animation-name: carouselPagination;
    animation-duration: 0.5s;
}
.pb23-btn.filter-auxmenu {
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid var(--clr-gray-100);
}
.pb23-btn.spec-selector.color {
    width: 50px;
    height: 50px;
    padding: var(--button-padding);
}
.pb23-btn.spec-selector.color > img {
    width: 42px;
    aspect-ratio: 1/1;
    display: block;
    border-radius: var(--button-radius);
    border-radius: 7px;
}
.pb23-btn.spec-selector.disabled {
    border: 1px dashed var(--button-border);
}
.pb23-btn.carousel-actions,
.pb23-btn.masonry-actions {
    width: 40px;
    max-width: 40px;
    overflow: hidden;
}
.pb23-btn.carousel-actions > span,
.pb23-btn.masonry-actions > span {
    display: none;
}
.pb23-btn.carousel-actions:hover,
.pb23-btn.masonry-actions:hover {
    width: auto;
    max-width: 100%;
    padding: 0 20px;
}
.pb23-btn.carousel-actions:hover > span,
.pb23-btn.masonry-actions:hover > span {
    display: block;
}
.pb23-btn.download > .image img,
.pb23-btn.download > .icon img{
    max-width: 14px;
    filter: grayscale(100%);
}
/* Sistema de Textos Responsivos */
button > span.default,
button > span.tablet,
button > span.mobile,
a > span.default,
a > span.tablet,
a > span.mobile,
.pb23-btn > span.default,
.pb23-btn > span.tablet,
.pb23-btn > span.mobile {
    display: none;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: 1.2;
}
button span.default,
a span.default,
.temp span.default {
    display: block;
}
button:not(:has(span.default)) > span:first-child,
a:not(:has(span.default)) > span:first-child,
.temp:not(:has(span.default)) > span:first-child {
    display: block;
}
@media (max-width: 1180px) {
    button:has(> span.tablet) > span.default,
    a:has(> span.tablet) > span.default,
    .temp:has(> span.tablet) > span.default {
        display: none;
    }
    button > span.tablet,
    a > span.tablet,
    .temp > span.tablet {
        display: block;
    }
}
@media (max-width: 769px) {
    button:has(> span.mobile) > span.default,
    button:has(> span.mobile) > span.tablet,
    a:has(> span.mobile) > span.default,
    a:has(> span.mobile) > span.tablet,
    .pb23-btn:has(> span.mobile) > span.default,
    .pb23-btn:has(> span.mobile) > span.tablet {
        display: none;
    }
    button > span.mobile,
    a > span.mobile,
    .pb23-btn > span.mobile {
        display: block;
    }
}
.pb23-btn.mosaicproduct > img {
    width: var(--button-width);
    height: var(--button-height);
    object-fit: contain;
    object-position: center;
    border-radius: var(--button-radius);
}
.pb23-btn.mosaiccolor {
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-areas: "image name" "image finishing";
    row-gap: 0px;
    column-gap: 8px;
}
.pb23-btn.mosaiccolor > .thumb {
    grid-area: image;
    align-self: start;
    width: 30px;
    border: 1px solid var(--clr-gray-200);
    border-radius: 50%;
    padding: 3px;
    overflow: hidden;
}
.pb23-btn.mosaiccolor > .thumb > img {
    width: 22px;
    height: 22px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    display: block;
}
.pb23-btn.mosaiccolor .name {
    grid-area: name;
}
.pb23-btn.mosaiccolor .finishing {
    grid-area: finishing;
    align-self: start;
    display: flex;
    justify-content: flex-start;
}
.pb23-btn.mosaiccolor .finishing i {
    margin: 0 4px 0 0;
    padding: 0 4px 0 0;
    border-right: 1px solid var(--clr-gray-200);
    font-size: 0.625rem;
    font-style: normal;
    color: var(--clr-gray-400);
}
.pb23-btn.mosaiccolor .finishing i:last-of-type {
    border: none;
}
.pb23-btn.tool[disabled] > img {
    display: none;
}
.pb23-btn.tool[disabled]:has(span + img):after {
    width: 12px;
    height: 15px;
    content: "";
    display: block;
    background: url("/template/images/icons/ic_lock.svg") center center no-repeat;
}
.pb23-btn.tool[disabled]:has(img + span):before {
    width: 12px;
    height: 15px;
    content: "";
    display: block;
    background: url("/template/images/icons/ic_lock.svg") center center no-repeat;
}
.pb23-btn.close {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    &:after {
        width: 28px;
        height: 1px;
        display: block;
        content: "";
        position: absolute;
        bottom: 9px;
        left: -3px;
        transform: rotate(-45deg);
        background: var(--clr-gray-400);
    }
    &:before {
        width: 28px;
        height: 1px;
        display: block;
        content: "";
        position: absolute;
        bottom: 9px;
        left: -3px;
        transform: rotate(45deg);
        background: var(--clr-gray-400);
    }
    &:hover {
        border: 0;
    }
}