#page-sympathetic,
#page-sympathetic * {
    --banner-height: 392px;
    --banner-spacer: 97px;
    --article-spacer: 80px;
}
#page-sympathetic.list,
#page-sympathetic.list *  {
    --banner-height: 392px;
    --banner-spacer: 30px;
    --article-spacer: 60px;
}
.sympathetic-modal .tag {
    width: auto;
    display: inline-block;
    margin: 20px 20px 0 0 ;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: .5625rem;
    color: var(--color-main);
    text-transform: uppercase;
    background: var(--color-lightest);
}
.sympathetic-modal .location {
    width: auto;
    display: block;
    margin: 0 0 12px;    
    font-size: .75rem;
    color: var(--color-main);
    font-weight: 300;
}
.sympathetic-modal .title {
    display: block;
    margin: 0 0 20px;
}
.sympathetic-modal .subtitle {
    display: block;
    margin: 0 0 24px;
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--color-darkest);
    line-height: 1.2;;
}
.sympathetic-modal .content {
    display: block;
    margin: 0 0 40px;
    color: var(--color-darker);
}
.sympathetic-modal .content * {
    color: var(--color-darker);
}
.sympathetic-modal .content dl {
    width: auto;
    display: grid;
    grid-template-columns: auto auto;
}
.sympathetic-modal,
.sympathetic-modal *  {
    --top-spacer: 70px;
    --banner-spacer: 26px;
    --close-top: 25px;
    --close-right: 25px;
}
.sympathetic-modal  .pb-content--highlight {
    padding-top: var(--top-spacer);
}
.sympathetic-modal .btn-close {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: var(--close-top);
    right: var(--close-right);
    z-index: 5;
}
#page-sympathetic .pb-content--highlight .content-container  .image {
    /*width: 64vw;*/
    height: var(--banner-height);
    position: relative;
    top: auto;
    margin-bottom: var(--banner-spacer);
}
#page-sympathetic .pb-content--highlight .content-container .image img {
    object-position: center;
    height:auto;
    width:auto;
}
.sympathetic-modal .pb-content--highlight .image {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
}
.sympathetic-modal .pb-content--highlight .image img {
    height: auto;
    object-position: center;
    object-fit: contain;
    max-height: 603px;
}
#page-sympathetic .pb-content--highlight .pb-item .image {
    width: 100%;
    height: 0px;
}
#page-sympathetic .content-container {
    /*padding-top: calc(var(--banner-height) + var(--banner-spacer));*/
    position: relative;
}
#page-sympathetic.list .content-container {
 /*   padding-bottom: var(--banner-spacer);*/
}
#page-sympathetic .content-container:before {
    width: 185px;
    height: 200px;
    content: "";
    display: block;
    position: absolute;
    top: calc(var(--banner-height) + var(--banner-spacer));
    left: -200px;
    background: url("/data/sympathetic/cover_graph.svg") top center no-repeat;
    background-size: contain;
}
#page-sympathetic.list .content-container:before {
    width: 186px;
    height: 275px;
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: -200px;
    bottom: -120px;
    background: url("/data/sympathetic/bello_graph.svg") top center no-repeat;
    background-size: contain;
}
#page-sympathetic .content-container h2 {
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: none;
}
#page-sympathetic .content-container h2 b {
    font-weight: 700;
}
#page-sympathetic .content-container article {
    margin-top: var(--article-spacer);
}
#page-sympathetic .content-container article h3 {
    margin-bottom: 1.75rem;
    font-size: 0.875rem;
    font-weight: 700;
}
#page-sympathetic .content-container article p,
#page-sympathetic .content-container p {
    font-size: 0.875rem;
    color: var(--color-dark-alt)
}
#page-sympathetic .content-container article ul {
    padding: 0;
    list-style: none;
}
#page-sympathetic .content-container article li {
    font-size: 0.875rem;
    color: var(--color-dark-alt)
}
@media (max-width: 992px){
    #page-sympathetic,
    #page-sympathetic *,
    #page-sympathetic.list,
    #page-sympathetic.list * {
        --banner-height: 0px;
        --banner-spacer: 0px;
        --article-spacer: 80px;
    }
    .pb-content--highlight .image{
        display: block;
    }
}

#page-sympathetic .filters {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto auto;
    margin-bottom: var(--container-grid-spacer);
}
#page-sympathetic .filters .predefined > span 
{
    padding-left: 60px;
    font-size: 0.90rem;
    color: var(--color-light);
    font-weight: 600;
}
#page-sympathetic .filters .predefined > ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 40px;
}
#page-sympathetic .filters .predefined > ul > li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 20px;
    padding: 0;
    position: relative;
}
#page-sympathetic .filters .predefined > ul > li > a {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.90rem;
    color: var(--color-light);
    text-decoration: none;
    padding: 0 0 0 0;
}
#page-sympathetic .filters .predefined > ul > li > a:after {
    width: 8px;
    height: 8px;
    display: block;
    content: "";
    margin: -4px 0 0 10px;
    transform: rotate(45deg);
    border-right: 2px solid var(--color-light);
    border-bottom: 2px solid var(--color-light);
}
#page-sympathetic .filters .predefined > ul .dropdown {
    width: auto;
    display: none;
    margin: 0;
    padding: 20px 20px 12px;
    position: absolute;
    right: -4px;
    top: 16px;
    list-style: none;
    background: var(--color-white);
    border: 1px solid var(--color-lightest);
    z-index: 5;
}
#page-sympathetic .filters .predefined > ul .dropdown[data-type="loja"] {
    min-width: 230px;
}
#page-sympathetic .filters .predefined > ul .dropdown[data-type="sort"] {
    min-width: 130px;
}
#page-sympathetic .filters .predefined > ul > li:hover .dropdown {
    display: block;
}
#page-sympathetic .filters .predefined > ul .dropdown li {
    padding: 0px;
    margin: 0 0 8px;
}
#page-sympathetic .filters .predefined > ul .dropdown li a {
    display: block;
    font-size: 0.75rem;
    color: var(--color-light);
    text-decoration: none;
}
@media (max-width: 440px)
{
    #page-sympathetic .filters
    {   
        display: block !important;
    }
    .pb-list--projects header
    {
        width: 100%;
        margin-bottom: 30px !important;
    }
    #page-sympathetic .filters .predefined {
        display: inline-block !important;
        width: 49% !important;
        margin-bottom: 20px !important;
    }    
    #page-sympathetic .filters .predefined>span,
    #page-sympathetic .filters .predefined>ul
    {
        padding-left: 0px !important;
    }
    #page-sympathetic .filters .predefined > ul .dropdown
    {
        left: 0px;
    }
    #page-sympathetic .pb-content--highlight .content-container .image 
    {
        display: block;
        width: 85vw;
        height: auto !important;
        margin-bottom: 20px;
    }
    #page-sympathetic .pb-content--highlight .content-container .image img {
        width: 100% !important;
        height: auto;
        margin-bottom: 20px;
    }
    .pb-list--projects .list-container .pb-item .name
    {
        height:auto !important;
    }
}