#pb-innovation .content-container p,
.pb-innovationlist-v2 header p
{
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.375;
}
.pb-innovationlist-v2 header p
{
    padding-top: 5px;
}

#pb-innovation .content-container p b {
    font-weight: 700;
}
#pb-innovation .image-container img {
    width: 100%;
}
.pb-innovation--internal {    
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2f2f2+0,f2f2f2+20,ffffff+20,ffffff+100 */
    background: #f2f2f2; /* Old browsers */
    background: -moz-linear-gradient(left,  #f2f2f2 0%, #f2f2f2 22%, #ffffff 22%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #f2f2f2 0%,#f2f2f2 22%,#ffffff 22%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #f2f2f2 0%,#f2f2f2 22%,#ffffff 22%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.pb-innovation--internal.clear-bg {
    background: transparent;
}
.pb-innovation--internal {
    padding-top: 100px
}
.pb-innovation--internal .image {
    width: 100%;
    height: 0;
    margin: 0 0 24px;
    padding: 0 0 56%;
    position: relative;
}
.pb-innovation--internal .image img {
    width: 100%;
    height: 100%;
    position: absolute;
}
.pb-innovation--internal .quote p {
    margin: 0 0 1.25rem;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
}
.pb-innovation--internal .quote .author {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
}
.pb-innovation--internal .quote .occupation {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.2;
}
@media (max-width: 992px) {
    .pb-innovation--internal {
        background: none;
        padding-top: 70px;
    }
}



#bastidores-modal
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1430;
    background: white;
    height: 100%;
    overflow: auto;
}
#bastidores-modal .btn-close
{
    position: absolute;
    right: 60px;
    top: 40px;
    z-index: 1431;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    color: var(--color-dark);
    vertical-align: middle;
    text-transform: uppercase;
}
#bastidores-modal .btn-close .pb-close
{
    margin-left: 10px;
}
#bastidores-modal button.close-modal
{
    width: 100%;
    max-width: 302px;
    padding: 8px 20px;
    font-size: 14px;
}
#bastidores-modal .video
{
    position: relative;
    margin-top: 55px;
    margin-bottom: 30px;
    width: 100%;
    height: 60vh;
}
#bastidores-modal .video .embed
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#bastidores-modal .title
{
    margin: 0 0 1rem;
    font-size: 1.75rem;
    font-weight: 900;
    color: var(--color-darkest);
    text-transform: uppercase;
}

#bastidores-modal .content
{
    margin-top: 30px;
}

@media (max-width: 440px) {
    #bastidores-modal .video
    {        
        height: 27vh;
    }
    #bastidores-modal .btn-close
    {
        position: absolute;
        top: 20px;
    }
}

#pb-innovation .content-container p a
{
    color: var(--color-darkest);
}

.pb-innovationlist--accordion
{
    margin-bottom: 60px;
}
.back
{
    margin-bottom: 40px;
    margin-top: -40px;
}
.back img
{
    transform: rotate(180deg);
}
.back a {
    text-decoration: none !important;
}
.back a span {
    margin: 0 0 0 10px;
    font-size: 0.857rem;
    font-weight: 400;
    letter-spacing: 2px;
    color: #212529;
    text-transform: uppercase;
}

@media (max-width: 440px) {
    .back
    {        
        margin-bottom: 20px;
    }
}

#bastidores-modal span.subtitle {
    display: block;
    margin: 4px 0 10px;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-light);
}
#bastidores-modal span.subtitle b {
    font-weight: 700;
}

/* Coletivo */
#pb-innovation-coletivo .pb-pageheader-v2 .slogan a {
    color: var(--color-black);;
    font-weight: 500;
}
#pb-innovation-coletivo .content-container {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
}
#pb-innovation-coletivo .content-container a {
    color: var(--color-black);
    font-weight: 500;
}
#pb-innovation-coletivo .video-container {
    position: relative;
    margin: 0 0 15px;
}
#pb-innovation-coletivo .video-container > iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
}
#pb-innovation-coletivo h2 {
    display: block;
    margin: 0 0 14px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-white);
}
#pb-innovation-coletivo .btn {
    width: 100%;
    margin: 700 auto;
    max-width: 294px;
    padding: 9px 20px;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-radius: 0;
    background: var(--color-black);
}
#pb-innovation-coletivo .complement {
    margin-top: 40px;
    text-align: center;
}
#pb-innovation-coletivo .complement .title {
    display: block;
    margin: 0 0 12px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
}
#pb-innovation-coletivo .complement p {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    text-align: center;
}
#pb-innovation-coletivo p.legend {
    margin: 0 0 12px;
    font-size: 21px;
    font-style: italic;
    color: var(--color-white);
    font-weight: 300;
    text-align: center;
}
#pb-innovation-coletivo p.author {
    margin: 0 0 12px;
    font-size: 1rem;
    color: var(--color-white);
    font-weight: 300;
    text-align: center;
}
/* Seções Específicas */
#pb-innovation-coletivo #docs {
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px, rgba(34,34,34,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#pb-innovation-coletivo #docs h2 {
    margin: 0 0 26px;
    font-size: 25px;
    font-weight: 700;
    color: var(--color-darkest);
}
#pb-innovation-coletivo #maisarq {
    padding: 100px 0 0;
    background: #222;
    text-align: center;
}
#pb-innovation-coletivo #maisarq p {
    margin: 0 0 12px;
    font-size: 24px;
    line-height: 1.2;
    color: var(--color-white);
    font-weight: 300;
}
#pb-innovation-coletivo #maisarq .btn {
        background: var(--color-white);
}
#pb-innovation-coletivo #doha {
    padding: 100px 0 0;
    background: #222;
    text-align: center;
}
#pb-innovation-coletivo #videos {
    padding: 100px 0 0;
    background: #222;
    text-align: center;
}
#pb-innovation-coletivo #videos .videos-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}
#pb-innovation-coletivo #videos .videos-list > .video-container {
    width: 100%;
    max-width: calc((100% - (5 * 16px))/6);
    min-width: 182px;
    position: relative;
    aspect-ratio: 1 / 1;
    border-radius: 15px;
    overflow: hidden;
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border: none;
    outline: none !important;
    background: none;
    color: var(--color-white);
    text-decoration: none !important;
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
    filter: blur(2px);
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--color-black);
    opacity: 0.5;
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a span {
    order: 1;
    font-size: 22px;
    text-align: center;
    line-height: 1.2;
    color: var(--color-white);
    z-index: 5;
    text-transform: uppercase;
    cursor: pointer;
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a:after {
    order: 2;
    width: 40px;
    height: 1px;
    position: relative;
    display: block;
    content: "";
    background: var(--color-white);
    z-index: 5;
}
#pb-innovation-coletivo #videos .videos-list > .video-container > a span.label {
    order: 3;
    font-size: 12px;
}
#pb-innovation-coletivo #videos .videos-list > .video-container {
    min-width: 182px;
}
#pb-innovation-coletivo #cocriacoes {
    background: #222;
    padding: 100px 0 0;
}
#pb-innovation-coletivo #cocriacoes p {
    margin: 0 0 12px;
    font-size: 24px;
    line-height: 1.2;
    color: var(--color-white);
    font-weight: 300;
    text-align: center;
}
#pb-innovation-coletivo #cocriacoes ul {
    display: grid;
    padding: 0;
    margin: 24px 0 0;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    row-gap: 28px;
    list-style: circle;
    color: var(--color-white);
}
#pb-innovation-coletivo #cocriacoes ul li {
    list-style: circle;
    color: var(--color-white);
}
#pb-innovation-coletivo #cocriacoes ul li:nth-of-type(1) {
    order: 1;
}
#pb-innovation-coletivo #cocriacoes ul li:nth-of-type(2) {
    order: 3;
}
#pb-innovation-coletivo #cocriacoes ul li:nth-of-type(3) {
    order: 5;
}
#pb-innovation-coletivo #cocriacoes ul li:nth-of-type(4) {
    order: 2;
}
#pb-innovation-coletivo #cocriacoes ul li:nth-of-type(5) {
    order: 4;
}
#pb-innovation-coletivo #cocriacoes ul li a {
    font-weight: 500;
    color: var(--color-white);
    text-decoration: underline;
}
#pb-innovation-coletivo #maisarqbanner {
    background: #222;
    background: -moz-linear-gradient(top,  rgba(34,34,34,1) 0%, rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 100px 0 0;
}
#pb-innovation-coletivo #maisarqbanner .blog-post {
    width: 100%;
    height: 665px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 60px;
    gap: 10px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.16);
}
#pb-innovation-coletivo #maisarqbanner .blog-post > span,
#pb-innovation-coletivo #maisarqbanner .blog-post > a {
    position: relative;
    z-index: 5;
    color: var(--color-white);
}
#pb-innovation-coletivo #maisarqbanner .blog-post > span.title {
    order: 2;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
}
#pb-innovation-coletivo #maisarqbanner .blog-post > span.subtitle {
    order: 1;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
}
#pb-innovation-coletivo #maisarqbanner .blog-post > a {
    order: 3;
    margin: 40px 0 0;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: underline;
}
#pb-innovation-coletivo #maisarqbanner .blog-post:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: -moz-linear-gradient(top,  rgba(18,18,18,0) 0%, rgba(18,18,18,0.55) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(18,18,18,0) 0%,rgba(18,18,18,0.55) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(18,18,18,0) 0%,rgba(18,18,18,0.55) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00121212', endColorstr='#8c121212',GradientType=0 ); /* IE6-9 */
}
#pb-innovation-coletivo #maisarqbanner .blog-post > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
}
#pb-innovation-coletivo #blog {
    padding: 100px 0 0;
}
@media (max-width: 992px) {
    #pb-innovation-coletivo .complement {
        margin-top: 30px;
        text-align: left;
    }
    #pb-innovation-coletivo .complement .title,
    #pb-innovation-coletivo .complement p {
        text-align: left;
    }
    #pb-innovation-coletivo .btn {
        max-width: 100%;
    }
    #pb-innovation-coletivo .complement p:last-of-type {
        margin-top: 20px;
        text-align: center;
    }
    #pb-innovation-coletivo > .container-xl {
        padding-bottom: 30px !important;
    }
    #pb-innovation-coletivo #docs {
        background: rgb(255,255,255); /* Old browsers */
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px, rgba(34,34,34,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    #pb-innovation-coletivo #maisarq,
    #pb-innovation-coletivo #doha,
    #pb-innovation-coletivo #videos,
    #pb-innovation-coletivo #cocriacoes,
    #pb-innovation-coletivo #maisarqbanner,
    #pb-innovation-coletivo #blog {
        padding: 50px 0 0;
    }
    #pb-innovation-coletivo #videos .videos-list > .video-container {
        max-width: 100%;
        aspect-ratio: 334 / 235;
    }
    #pb-innovation-coletivo #cocriacoes ul {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 14px;
    }
    #pb-innovation-coletivo #cocriacoes ul li {
        list-style: none;
    }
    #pb-innovation-coletivo #maisarqbanner .blog-post {
        height: auto;
        min-height: 363px;
        padding: 20px;
    }
    #pb-innovation-coletivo #maisarqbanner {
        background: #222;
        background: -moz-linear-gradient(top,  rgba(34,34,34,1) 0%, rgba(34,34,34,1) 230px, rgba(255,255,255,1) 230px, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 230px, rgba(255,255,255,1) 230px,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 230px, rgba(255,255,255,1) 230px,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
}
/* Milão */
#pb-innovation #innovationMilao {
    margin: 80px 0;
    padding: 70px 0;
    background: #63666a;
}
#pb-innovation #innovationMilao .image-container .image {
    position: relative;
    padding: 0;
}
#pb-innovation #innovationMilao .image-container .logo {
    width: auto;
    position: absolute;
    bottom: -48px;
    right: 20px;
}
#pb-innovation #innovationMilao .content-container h2 {
    position: relative;
    margin: 0 0 16px;
    font-size: 1.875rem;
    font-weight: 700;
    color: var(--color-white);
    text-transform: uppercase;
}
#pb-innovation #innovationMilao .content-container .slogan {
    display: block;
    padding: 0 0 22px;
    margin: 0 0 30px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-white);
}
#pb-innovation #innovationMilao .content-container .slogan:after {
    width: 25px;
    height: 4px;
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #969ba0;
}
#pb-innovation #innovationMilao .content-container p {
    display: block;
    margin: 0 0 30px;
    position: relative;
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-white);
}
#pb-innovation #innovationMilao .content-container .ctas-container {
    display: flex;
    gap: 16px;
}
#pb-innovation #innovationMilao .content-container .btn-clear {
    height: 54px;
    padding: 0 50px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 0;
    font-size: 12px;
    color: #2e2e2e;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 12px;
    text-transform: uppercase;
    z-index: 2;
    white-space: nowrap;
    background: #fff;
}
@media (max-width: 769px) {
    #pb-innovation #innovationMilao {
        margin: 30px 0;
        padding: 30px 0;
    }
    #pb-innovation #innovationMilao .image-container .image {
        padding: 0 0 17px;
    }
    #pb-innovation #innovationMilao .image-container .logo {
        width: 55%;
        bottom: 0px;
        right: 20px;
    }
    #pb-innovation #innovationMilao .content-container {
        margin: 30px 0 0;
    }
    #pb-innovation #innovationMilao .content-container .slogan {
        padding: 0 0 14px;
    }
    #pb-innovation #innovationMilao .content-container .ctas-container {
        flex-direction: column;
    }
    #pb-innovation #innovationMilao .content-container .ctas-container > btn {
        width: 100%;
    }
}

/* CERSAIE */
/* Coletivo */
#pb-innovation-cersaie .pb-pageheader-v2 .slogan a {
    color: var(--color-black);;
    font-weight: 500;
}
#pb-innovation-cersaie .content-container b {
    font-weight: 500;
}
#pb-innovation-cersaie .content-container {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
}
#pb-innovation-cersaie .content-container a {
    color: var(--color-black);
    font-weight: 500;
}
#pb-innovation-cersaie .video-container {
    position: relative;
    margin: 0 0 15px;
}
#pb-innovation-cersaie .video-container > iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
}
#pb-innovation-cersaie h2 {
    display: block;
    margin: 0 0 14px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-white);
}
#pb-innovation-cersaie .btn {
    width: 100%;
    margin: 700 auto;
    max-width: 294px;
    padding: 9px 20px;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-radius: 10px;
    background: var(--color-black);
}
#pb-innovation-cersaie .btn.btn-light {
    padding: 9px 10px;
    font-weight: 300;
    background: var(--color-lightest);
    padding: 8px 20px;
    border: none !important;
}
#pb-innovation-cersaie .complement {
    margin-top: 160px;
    text-align: center;
}
#pb-innovation-cersaie .complement .title {
    display: block;
    margin: 0 0 12px;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
}
#pb-innovation-cersaie .complement p {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    text-align: center;
}
#pb-innovation-cersaie p.legend {
    margin: 0 0 12px;
    font-size: 21px;
    font-style: italic;
    color: var(--color-white);
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
}
#pb-innovation-cersaie p.author {
    margin: 0 0 12px;
    font-size: 1rem;
    color: var(--color-white);
    font-weight: 300;
    text-align: center;
}
/* Seções Específicas */
#pb-innovation-cersaie #cersaie {
    background: rgb(255,255,255); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px, rgba(34,34,34,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 300px, rgba(34,34,34,1) 300px,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
#pb-innovation-cersaie #cersaie h2 {
    margin: 0 0 26px;
    font-size: 25px;
    font-weight: 700;
    color: var(--color-darkest);
}
#pb-innovation-cersaie #videos {
    padding: 100px 0 0;
    background: #222;
    text-align: center;
}
#pb-innovation-cersaie #videos h2 {
    font-weight: 400;
}
#pb-innovation-cersaie #videos .videos-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0;
    padding: 0;
    list-style: none;
}
#pb-innovation-cersaie #videos .videos-list > .video-container {
    width: 100%;
    max-width: calc((100% - (5 * 16px))/6);
    min-width: 182px;
    position: relative;
    aspect-ratio: 1 / 1;
    border-radius: 15px;
    overflow: hidden;
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    border: none;
    outline: none !important;
    background: none;
    color: var(--color-white);
    text-decoration: none !important;
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
    filter: blur(2px);
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: var(--color-black);
    opacity: 0.5;
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a span {
    order: 1;
    font-size: 22px;
    text-align: center;
    line-height: 1.2;
    color: var(--color-white);
    z-index: 5;
    text-transform: uppercase;
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a:after {
    order: 2;
    width: 40px;
    height: 1px;
    position: relative;
    display: block;
    content: "";
    background: var(--color-white);
    z-index: 5;
}
#pb-innovation-cersaie #videos .videos-list > .video-container > a span.label {
    order: 3;
    font-size: 12px;
}
#pb-innovation-cersaie #videos .videos-list > .video-container {
    min-width: 182px;
}
#pb-innovation-cersaie #textanim {
    padding: 100px 0 0;
    background: #222;
}
#pb-innovation-cersaie #textanim span {
    margin: 0 20px 0 0;
    font-size: 80px;
    font-weight: 400;
    color: var(--color-white);
    line-height: 1.2;
}
#pb-innovation-cersaie #textanim span.bold {
    font-weight: 700;
}
#pb-innovation-cersaie #download {
    background: #222;
    padding: 100px 0 0;
    text-align: center;
}
#pb-innovation-cersaie #download .image-container {
    margin: 0 0 20px;
}
#pb-innovation-cersaie #download img {
    width: 100%;
}
#pb-innovation-cersaie #download p {
    margin: 0 0 12px;
    font-size: 21px;
    line-height: 1.2;
    color: var(--color-white);
    font-weight: 300;
    text-align: center;
}
#pb-innovation-cersaie #download .btn {
    width: auto;
    margin: 20px auto 0;
    font-weight: 400;
}
#pb-innovation-cersaie #maisarqbanner {
    background: #222;
    padding: 100px 0 0;
}
#pb-innovation-cersaie #maisarqbanner .blog-post {
    width: 100%;
    height: 665px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 60px;
    gap: 10px;
    box-shadow: 0 0 16px 0 rgba(0,0,0,0.16);
}
#pb-innovation-cersaie #maisarqbanner .blog-post > span,
#pb-innovation-cersaie #maisarqbanner .blog-post > a {
    position: relative;
    z-index: 5;
    color: var(--color-white);
}
#pb-innovation-cersaie #maisarqbanner .blog-post > span.title {
    order: 2;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
}
#pb-innovation-cersaie #maisarqbanner .blog-post > span.subtitle {
    order: 1;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
}
#pb-innovation-cersaie #maisarqbanner .blog-post > a {
    order: 3;
    margin: 40px 0 0;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: underline;
}
#pb-innovation-cersaie #maisarqbanner .blog-post:before {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 2;
    background: -moz-linear-gradient(top,  rgba(18,18,18,0) 0%, rgba(18,18,18,0.55) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(18,18,18,0) 0%,rgba(18,18,18,0.55) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(18,18,18,0) 0%,rgba(18,18,18,0.55) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00121212', endColorstr='#8c121212',GradientType=0 ); /* IE6-9 */
}
#pb-innovation-cersaie #maisarqbanner .blog-post > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    object-position: center;
}
#pb-innovation-cersaie #mosaic {
    background: #222;
    background: -moz-linear-gradient(top,  rgba(34,34,34,1) 0%, rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 400px, rgba(255,255,255,1) 400px,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 100px 0;
}
#pb-innovation-cersaie #mosaic p {
    margin: 16px 0 30px;
    font-size: 21px;
    text-align: center;
    line-height: 1.2;
    color: var(--color-white);
}
#pb-innovation-cersaie #mosaic ul {
    height: 630px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "banner01 banner02 banner04" "banner01 banner03 banner04" ;
    gap: 16px;
    margin: 0;
    padding: 0;
    list-style: none;
}
#pb-innovation-cersaie #mosaic ul li {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 20px 40px;
    position: relative;
    isolation: isolate;
    overflow: hidden;
    border-radius: 15px;
}
#pb-innovation-cersaie #mosaic ul li .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
#pb-innovation-cersaie #mosaic ul li .content .title {
    order: 2;
    font-size: 28px;
    font-weight: 700;
    color: var(--color-white);
    line-height: 1.2;
}
#pb-innovation-cersaie #mosaic ul li .content .subtitle {
    order: 1;
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-white);
    line-height: 1.2;
    text-transform: uppercase;
}
#pb-innovation-cersaie #mosaic ul li .content a {
    order: 3;
    margin: 40px 0 0;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-white);
    line-height: 1.2;
    text-decoration: underline;
}
#pb-innovation-cersaie #mosaic ul li .image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
#pb-innovation-cersaie #mosaic ul li .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center
}
#pb-innovation-cersaie #mosaic ul li:nth-of-type(1) {
    grid-area: banner01;
}
#pb-innovation-cersaie #mosaic ul li:nth-of-type(2) {
    grid-area: banner02;
}
#pb-innovation-cersaie #mosaic ul li:nth-of-type(3) {
    grid-area: banner03;
}
#pb-innovation-cersaie #mosaic ul li:nth-of-type(4) {
    grid-area: banner04;
}
@media (max-width: 992px) {
    #pb-innovation-cersaie .complement {
        margin-top: 30px;
        text-align: left;
    }
    #pb-innovation-cersaie .complement .title,
    #pb-innovation-cersaie .complement p {
        text-align: left;
    }
    #pb-innovation-cersaie .btn {
        max-width: 100%;
    }
    #pb-innovation-cersaie .complement p:last-of-type {
        margin-top: 20px;
        text-align: center;
    }
    #pb-innovation-cersaie > .container-xl {
        padding-bottom: 30px !important;
    }
    #pb-innovation-cersaie #cersaie {
        background: rgb(255,255,255); /* Old browsers */
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px, rgba(34,34,34,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 150px, rgba(34,34,34,1) 150px,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    #pb-innovation-cersaie #cersaie,
    #pb-innovation-cersaie #videos,
    #pb-innovation-cersaie #textanim,
    #pb-innovation-cersaie #download,
    #pb-innovation-cersaie #maisarqbanner,
    #pb-innovation-cersaie #mosaic {
        padding: 50px 0 0;
    }
    #pb-innovation-cersaie #videos .videos-list > .video-container {
        max-width: 100%;
        aspect-ratio: 334 / 235;
    }
    #pb-innovation-cersaie #textanim span {
        margin: 0 10px 0 0;
        font-size: 40px;
    }
    #pb-innovation-cersaie #maisarqbanner .blog-post {
        height: auto;
        min-height: 363px;
        padding: 20px;
    }
    #pb-innovation-cersaie #mosaic {
        margin: 0 0 100px;
        background: #222;
        background: -moz-linear-gradient(top,  rgba(34,34,34,1) 0%, rgba(34,34,34,1) 350px, rgba(255,255,255,1) 350px, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 350px, rgba(255,255,255,1) 350px,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(34,34,34,1) 0%,rgba(34,34,34,1) 350px, rgba(255,255,255,1) 350px,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
    #pb-innovation-cersaie #mosaic ul {
        height: auto;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: "banner01" "banner02" "banner03" "banner04" ;
        gap: 16px;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    #pb-innovation-cersaie #mosaic ul li {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 30px;
    }
}
#pb-innovation-cersaie .textFx.animated {
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
    100% {
        transform: scale(1);
    }
}

#pb-innovation-cersaie .textFx span {
    display: inline;
    opacity: 0;
    filter: blur(4px);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(1) {
animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(2) {
animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(3) {
animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(4) {
animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(5) {
animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(6) {
animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(7) {
animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(8) {
animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(9) {
animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(10) {
animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(11) {
animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(12) {
animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(13) {
animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(14) {
animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(15) {
animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(16) {
animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(17) {
animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(18) {
animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(19) {
animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(20) {
animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(21) {
animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(22) {
animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(23) {
animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(24) {
animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(25) {
animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(26) {
animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(27) {
animation: fade-in 0.8s 2.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#pb-innovation-cersaie .textFx.animated span:nth-child(28) {
animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}
