#page-virtual,
#page-virtual * {
    box-sizing: border-box;
}
#page-virtual {
    margin-bottom: 200px;
}
#page-virtual .content p {
    width: 100%;
    max-width: 100%;
}
#page-virtual form {
    width: 60%;
    margin: 40px 0 0;
}
#page-virtual form select {
    width: 60%;
    margin: 20px 0 50px;
}
#page-virtual form .link-small {
    margin: 20px 0 0;
}
#page-virtual .infobox p.default {
    margin-top: 40px;
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-transform: none;
    font-weight: 300;
}