#page-calculator,
#page-calculator * {
    --spacer-top: 68px;
    --spacer-bottom: 162px;
    --margin: 128px;
    box-sizing: border-box;
}
#page-calculator {
    margin-bottom: var(--margin);
    padding: var(--spacer-top) 0 var(--spacer-bottom);
    background: var(--color-white-alt);
}
#page-calculator .pb-pageheader .header {
    padding: 0px;
}
#page-calculator .content {
    margin: 0 0 60px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.75rem;
}
#page-calculator .subtitle {
    display: block;
    margin: 38px 0; 
    color: var(--color-darker);
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
}
#page-calculator ul.links {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: var(--container-grid-spacer);
    row-gap: 20px;
    list-style: none;
    padding: 0px;
    margin: 0px;
}
#page-calculator ul.links li a {
    font-size: 0.875rem;
    color: var(--color-light);
}