#page-specifier,
#page-specifier * {
    
    box-sizing: border-box;
}
@media (max-width: 992px) {
    #page-specifier,
    #page-specifier * {
        --header-spacer: 27px 15px 24px;
        --title-spacer: 10px;
    }
    #page-specifier--bg,
    #page-specifier--concept {
        display: none;
    }
}
/* Header */
#page-specifier {
    width: 100%;
    position: relative;
    z-index: 2;
}
#page-specifier .pb-pageheader {
    margin-bottom: 80px;
}
#page-specifier .pb-specifier-header {
    margin-bottom: 100px;
}
.pb-myarchtrends.no-projects .pb-item .image
{
    width: 180px;
    height: 150px;
    background-color:#F1F1F1;
}
.pb-myarchtrends.no-projects .pb-item .content
{
    width: 140px;
    height: 20px;
    background-color:#F1F1F1;
    margin-top: 5px;
}
.pb-myarchtrends.no-profile p
{
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 1.3rem;
}
.pb-myarchtrends.no-projects p
{
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: 28px;
}
.pb-specifier-header .no-account p
{
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 1.3rem;
}
.pb-specifier-header .no-account  .maisarq--options div
{
    margin-top: 20px;
}
.pb-specifier-header .no-account .btn-clear
{
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}
#page-specifier > div.pb-specifier-header > div > div.account
{
    padding-top: 60px;
}
/* INSPIRE-SE */
#page-specifier #inspirese .pb-pageheader-v2 {
    margin-bottom: 0px;
  }
/* CRIE */
#page-specifier #crie {
    padding-top: 100px;
}
#page-specifier #crie .pb-specifier-container--crie {
    /*display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 72px;*/
    margin: 0;
    padding: 0;
    list-style: none;
}
#page-specifier #crie .pb-specifier-container--crie li {
    /*width: 100%;*/
    width: 310px;
    min-height: 320px;
    margin: 15px;
    padding: 25px 25px 16px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 85px auto auto 1fr;
    grid-template-areas: "image" "title" "content" "link";
    gap: 10px;
    background: var(--color-white);
    --webkit-box-shadow: var(--shadow-default);
    box-shadow: var(--shadow-default);
}
#page-specifier #crie .pb-specifier-container--crie li .image {
    grid-area: image;
    max-width: 212px;
    width: 100%;
    height: 85px;
    object-fit: contain;
    object-position: left center;
}
#page-specifier #crie .pb-specifier-container--crie li .title {
  grid-area: title;
  margin: 22px 0 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}
#page-specifier #crie .pb-specifier-container--crie li p {
  grid-area: content;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.2;
}
#page-specifier #crie .pb-specifier-container--crie li a {
  grid-area: link;
  justify-self: start;
  align-self: end;
}
@media (max-width: 1200px) {
    #page-specifier #crie .pb-specifier-container--crie {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 769px) {    
    #page-specifier #crie {
        padding-top: 60px;
    }
    #page-specifier #crie .pb-specifier-container--crie {
        grid-template-columns: 100%;
        gap: 15px;
    }
}

/* EXECUTE */
#page-specifier #execute {
    padding-top: 100px;
}
#page-specifier #execute .content p {
  margin: 0;
  font-size: 1.12rem;
  font-weight: 300;
  line-height: 1.4;
}
#page-specifier #execute .content a.newlink {
  display: inline-block;
  margin: 6px 0 30px;
  color: var(--color-darker-alt)
}
#page-specifier #execute .content a.btn-darkest-v2 {
  margin: 8px 0 20px;
  display: inline-block;
}
#page-specifier #execute .content .footnote {
  font-style: italic;
}
#page-specifier #execute .seal-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 0 0 60px;
  padding: 0;
  list-style: none;
}
#page-specifier #execute .seal-container li {
  margin: 0;
  padding: 0;
}
#page-specifier #execute .seal-container li img {
  display: block;
  margin: 0 0 12px;
}
#page-specifier #execute .seal-container li .title {
  display: block;
    margin: 0 0 12px;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#page-specifier #execute .seal-container li p {
  max-width: 250px;
  font-size: 1.5rem;
  line-height: 1.2;
}
@media (max-width: 992px) {
    #page-specifier #execute {
        padding-top: 60px;
    }
    #page-specifier #execute .content {
        margin-bottom: 28px;
    }
    #page-specifier #execute .seal-container {
        display: block;
        margin-bottom: 0px;
    }
    #page-specifier #execute .seal-container li {
        margin: 0 0 28px;
    }
}

/* INCENTIVOS */
#page-specifier #incentivos {
    padding-top: 100px;
}
#page-specifier #incentivos .box {
    width: 100%;
    height: 100%;
    min-height: 316px;
    padding: 40px 48px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 64px 1fr auto;
    grid-template-areas: "logo" "content" "cta";
    gap: 18px;
    background: #f1f1f1;
}
#page-specifier #incentivos .box .title {
    grid-area: logo;
    align-self: center;
    font-size: 1.875rem;
    font-weight: 700;
    line-height: 1.2;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#page-specifier #incentivos .box p {
    margin: 0;
    font-size: 1.12rem;
    font-weight: 300;
    line-height: 1.4; 
}
#page-specifier #incentivos .box a {
    width: 100%;
    max-width: 294px;
    display: inline-block;
    justify-self: start;
    align-self: end;
    text-align: center;
}
#page-specifier #incentivos .selos {
    margin-top: 30px;
}
#page-specifier #incentivos .selos img {
    width: calc(100% - 50px);
}
#page-specifier #incentivos .selos-content {
    margin-top: 30px;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
}
#page-specifier #incentivos .selos-content p {
    font-size: 1.12rem;
    line-height: 1.2;
    margin: 0 0 20px;
}
@media (max-width: 992px) {
    #page-specifier #incentivos {
        padding-top: 60px;
    }
    #page-specifier #incentivos .box {
        height: auto;
        min-height: auto;
        padding: 20px;
        margin-bottom: 15px;
    }
    #page-specifier #incentivos .box a {
        max-width: 100%;
    }
    #page-specifier #incentivos .selos img {
        width: 100%;
    }
}

/* EXPERIENCIA */
#page-specifier #experiencia {
    padding-top: 128px;
}
#page-specifier #experiencia .mosaic-container {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: repeat(3,1fr);
  grid-template-areas: "venda concept01 tv delivery" "venda especificador concept02 meuprojeto" "concept03 kids gourmet meuprojeto";
  column-gap:  58px;
  row-gap: 50px;
  margin-bottom: 100px;
}
#page-specifier #experiencia .mosaic-container #mosaic--venda {
    grid-area: venda;
}
#page-specifier #experiencia .mosaic-container #mosaic--concept-01 {
    grid-area: concept01;
}
#page-specifier #experiencia .mosaic-container #mosaic--concept-02 {
    grid-area: concept02;
}
#page-specifier #experiencia .mosaic-container #mosaic--concept-03 {
    grid-area: concept03;
}
#page-specifier #experiencia .mosaic-container #mosaic--tv {
    grid-area: tv;
}
#page-specifier #experiencia .mosaic-container #mosaic--delivery {
    grid-area: delivery;
}
#page-specifier #experiencia .mosaic-container #mosaic--especificador {
    grid-area: especificador;
}
#page-specifier #experiencia .mosaic-container #mosaic--meuprojeto {
    grid-area: meuprojeto;
}
#page-specifier #experiencia .mosaic-container #mosaic--kids {
    grid-area: kids;
}
#page-specifier #experiencia .mosaic-container #mosaic--gourmet {
    grid-area: gourmet;
}
#page-specifier #experiencia .mosaic-container .box--full {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 62px auto 1fr 20px;
  grid-template-areas: "icon" "title" "content" "cta";
  padding: 24px 24px 20px;
  background: var(--color-white);
  --webkit-box-shadow: var(--shadow-default);
  box-shadow: var(--shadow-default);
}
#page-specifier #experiencia .mosaic-container .box--full img {
    grid-area: icon;
    height: 62px;
}
#page-specifier #experiencia .mosaic-container .box--full .title {
  grid-area: title;
  margin: 40px 0 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}
#page-specifier #experiencia .mosaic-container .box--full p {
  grid-area: content;
  margin: 10px 0 6px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.2;
}
#page-specifier #experiencia .mosaic-container .box--full a {
  grid-area: cta;
  justify-self: start;
  align-self: end;
}
#page-specifier #experiencia .mosaic-container .box--simple {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;  
    padding: 24px 24px 20px;
    background: var(--color-white);
    --webkit-box-shadow: var(--shadow-default);
    box-shadow: var(--shadow-default);
}
#page-specifier #experiencia .mosaic-container .box--simple .title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
}
#page-specifier #experiencia .mosaic-container .box--image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
@media (max-width: 992px) {  
    #page-specifier #experiencia .mosaic-container {
        margin-bottom: 0px;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas: "venda" "concept01" "concept02" "especificador" "kids" "tv" "concept03" "gourmet" "delivery" "meuprojeto";
        row-gap: 20px;
    }
    #page-specifier #experiencia .mosaic-container > div {
      width: 100%;
      min-height: 124px;
    }
}
/* BANNERS */
#Specifier_index .banner-maisarq2023 {
    margin-bottom: 20px;
}
